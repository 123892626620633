export const convertForClassNames = (key) => {
  if (key?.startsWith("a:")) {
    return key.replace("a:", "a-");
  }
  if (key?.startsWith("@_")) {
    return key.replace("@_", "at-");
  }
  if (key?.startsWith("h:")) {
    return key.replace("h:", "h-");
  }

  return key;
};

export const formatKey = (key) => {
  // articolo
  if (key.includes("Articolo")) {
    key = key.replace("Articolo", "Art.");
    return (
      <span
        style={{
          alignSelf: "center",
          fontSize: "1.4rem",
          fontWeight: 600,
        }}
      >
        {key}
      </span>
    );
  }
  //comma
  if (key.includes("Comma")) {
    key = key.replace("Comma", " ");
    // append . to the key
    key = key + ".";
    return (
      <span
        style={{
          fontSize: "1.4rem",
          fontWeight: 600,
        }}
      >
        {key}
      </span>
    );
  }
  // lettera
  if (key.includes("Lettera")) {
    key = key.replace("Lettera", "");
    key = key + ")";

    return (
      <span
        style={{
          fontSize: "1.2rem",
          fontWeight: 600,
        }}
      >
        <i>{key}</i>
      </span>
    );
  }
  // numero
  if (key.includes("Numero")) {
    key = key.replace("Numero", " ");
    // append . to the key
    key = key + ".";
    return (
      <span
        style={{
          fontSize: "1rem",
          fontWeight: 600,
        }}
      >
        {key}
      </span>
    );
  }

  return key;
  // return key; // articolo, comma, lettera

  // comma lettera
};

export const cleanParagraphPath = (inputString) => {
  const words = inputString.split(" ");
  const cleanedWords = words.filter(
    (word) => !word.startsWith("a:") && !word.startsWith("h:")
  );
  return cleanedWords.join(" ");
};

export const cleanKey = (key) => {
  // remove the @_, a: and h: prefixes
  if (key?.startsWith("@_")) {
    return key.replace("@_", "");
  }
  if (key?.startsWith("a:")) {
    return key.replace("a:", "");
  }
  if (key?.startsWith("h:")) {
    return key.replace("h:", "");
  }
  if (key?.startsWith("dl:")) {
    return key.replace("dl:", "");
  }

  return key;
};

export const getPremisedSectionTitle = (paragraphPath) => {
  // split ultime due parole
  const words = paragraphPath.split(" ");
  const lastWord = words.pop();
  const secondLastWord = words.pop().toUpperCase();
  const premisedSectionTitle = ` 0${lastWord}.`;
  return premisedSectionTitle;
};

export const getAddedSectionTitle = (paragraphPath) => {
  // split ultime due parole
  const words = paragraphPath.split(" ");
  const lastWord = words.pop();
  const secondLastWord = words.pop().toUpperCase();
  const addedSectionTitle = `${secondLastWord} ${lastWord}.bis`;
  return addedSectionTitle;
};

export const extractIntroductionValues = (data) => {
  let result = [];

  if (Array.isArray(data["a:Comma"])) {
    for (let item of data["a:Comma"]) {
      let corpo = item["a:Corpo"];
      if (corpo && corpo["h:p"]) {
        if (typeof corpo["h:p"] === "string") {
          result.push(corpo["h:p"]);
        } else if (Array.isArray(corpo["h:p"])) {
          for (let hp of corpo["h:p"]) {
            result.push(hp);
          }
        }
      }
    }
  }

  const joined = result;
  return joined;
};

export const getPositionReference = (
  paragraphPath,
  type,
  conseguentementeActive
) => {
  let positionReference = paragraphPath.split(" ").slice(-2).join(" ");
  console.log("positionReference 1", positionReference);
  // get Article
  let article = paragraphPath.split("Articolo")[1];
  if (article) {
    article = article.split(" ")[1];
  }

  // get Comma letter

  let comma = paragraphPath.split("Comma")[1];
  if (comma) {
    comma = comma.split(" ")[1];
  }

  // get Lettera

  let lettera = paragraphPath.split("Lettera")[1];

  if (lettera) {
    lettera = lettera.split(" ")[1];
  }

  // get Number

  let number = paragraphPath.split("Numero")[1];

  if (number) {
    number = number.split(" ")[1];
  }

  if (type === "add") {
    switch (positionReference.split(" ")[0]) {
      case "Comma":
        positionReference = conseguentementeActive
          ? "all'articolo " + article + ", dopo il comma " + comma + " "
          : "Dopo il comma " + comma + " ";
        break;
      case "Lettera":
        positionReference = conseguentementeActive
          ? "all'articolo " +
            article +
            ", al comma " +
            comma +
            ", dopo la lettera " +
            lettera +
            ")"
          : "Al comma " + comma + ", dopo la lettera " + lettera + ")";

        break;
      case "Numero":
        positionReference = positionReference = conseguentementeActive
          ? "all'articolo " +
            article +
            ", al comma " +
            comma +
            ", lettera " +
            lettera +
            "), dopo il numero " +
            number +
            ")"
          : "Al comma " +
            comma +
            ", lettera " +
            lettera +
            "), dopo il numero " +
            number +
            ")";
        break;
      default:
        positionReference = "Dopo l'articolo " + article + " ";
        break;
    }
    positionReference = `<i>${positionReference}</i>`;
    return positionReference;
  } else if (type === "premise") {
    switch (positionReference.split(" ")[0]) {
      case "Comma":
        positionReference = conseguentementeActive
          ? "all'articolo " + article + ", al comma " + comma + " "
          : "Al comma " + comma + " ";
        break;
      case "Lettera":
        positionReference = conseguentementeActive
          ? /*   ? "all'articolo " +
            article +
            ", al comma " +
            comma +
            ", alla lettera " +
            lettera +
            ")"
          : "Al comma " + comma + ", alla lettera " + lettera + " "; */
            "all'articolo " + article + ", al comma " + " "
          : "Al comma " + comma + " ";
        break;
      case "Numero":
        positionReference = positionReference = conseguentementeActive
          ? "all'articolo " +
            article +
            ", al comma " +
            comma +
            ", lettera " +
            lettera +
            ") numero " +
            number +
            ")"
          : "Al comma " +
            comma +
            ", lettera " +
            lettera +
            ") numero " +
            number +
            ")";
        break;
      default:
        positionReference = "All'articolo " + article + " ";
        break;
    }
    positionReference = `<i>${positionReference}</i>`;
    return positionReference;
  } else if (type === "substitute") {
    switch (positionReference.split(" ")[0]) {
      case "Comma":
        positionReference = conseguentementeActive
          ? "all'articolo " + article + ", sostituire il comma " + comma + " "
          : "Sostituire il comma " + comma + " ";
        break;
      case "Lettera":
        positionReference = conseguentementeActive
          ? "all'articolo " +
            article +
            ", al comma " +
            comma +
            ", sostituire la lettera " +
            lettera +
            ")"
          : "Al comma " + comma + ", sostituire la lettera " + lettera + ")";
        break;
      case "Numero":
        positionReference = positionReference = conseguentementeActive
          ? "all'articolo " +
            article +
            ", al comma " +
            comma +
            ", lettera " +
            lettera +
            "), sostituire il numero " +
            number +
            ""
          : "Al comma " +
            comma +
            ", lettera " +
            lettera +
            "), sostituire il numero " +
            number +
            "";
        break;
      default:
        positionReference = "Sostituirlo ";
        break;
    }
    positionReference = `<i>${positionReference}</i>`;
    return positionReference;
  } else if (type === "delete") {
    switch (positionReference.split(" ")[0]) {
      case "Comma":
        positionReference = conseguentementeActive
          ? "all'articolo " + article + ", sopprimere il comma " + comma + " "
          : "Sopprimere il comma " + comma + " ";
        break;
      case "Lettera":
        positionReference = conseguentementeActive
          ? "all'articolo " +
            article +
            ", al comma " +
            comma +
            ", sopprimere la lettera " +
            lettera +
            ")"
          : "Al comma " + comma + ", sopprimere la lettera " + lettera + ")";
        break;
      case "Numero":
        positionReference = positionReference = conseguentementeActive
          ? "all'articolo " +
            article +
            ", al comma " +
            comma +
            ", lettera " +
            lettera +
            "), sopprimere il numero " +
            number +
            ""
          : "Al comma " +
            comma +
            ", lettera " +
            lettera +
            "), sopprimere il numero " +
            number +
            "";
        break;
      default:
        positionReference = "Sopprimere l'articolo " + article + " ";
        break;
    }
    positionReference = `<i>${positionReference}</i>`;
    return positionReference;
  } else {
    switch (positionReference.split(" ")[0]) {
      case "Comma":
        positionReference = conseguentementeActive
          ? "all'articolo " + article + ", al " + positionReference
          : "Al " + positionReference;
        break;
      case "Lettera":
        positionReference = conseguentementeActive
          ? "all'articolo " +
            article +
            ", al comma " +
            comma +
            ", lettera " +
            lettera +
            ")"
          : "Al comma " + comma + ", lettera " + lettera + ")";
        break;
      case "Numero":
        positionReference = positionReference = conseguentementeActive
          ? "all'articolo " +
            article +
            ", al comma " +
            comma +
            ", lettera " +
            lettera +
            "), numero " +
            number +
            ""
          : "Al comma " +
            comma +
            ", lettera " +
            lettera +
            "), numero " +
            number +
            "";
        break;

      default:
        if (
          !!positionReference.split(" ")[1] &&
          positionReference.split(" ")[1] === "Rubrica"
        ) {
          positionReference = conseguentementeActive
            ? "all'articolo " + article + ", in rubrica"
            : "In Rubrica ";
        } else {
          return positionReference;
        }
        break;
    }

    positionReference = `<i>${positionReference}</i>`;

    return positionReference;
  }
};
