import React, { useState, useEffect } from "react";
import { FaDownload, FaTrashCan, FaUpload } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../shared/Buttons";
import {
  saveEmendament,
  removeEmendament,
  deleteEmendament,
  /*   removeAddedSection,
  removeChangedSection,
  removePremiseSection,
  removeRemovedSection,
  resetEmendaments, */
} from "../features/emendamentsSlice";
import { Popup } from "../components/popup/Popup";
import { useNavigate } from "react-router-dom";

import ConseguentementeTag from "../components/tag/ConseguentementeTag";
import styled from "styled-components";
import Avatar from "../components/avatar/Avatar";

const StyledEmendamentCard = styled.div`
  position: relative;
  padding: 8px 0px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #eee;
    text-decoration: underline;
  }
  border-bottom: ${(props) => (props.isLast ? "none" : "1px solid #ccc")};
`;

const EmendamentsRecap = ({ setClickedSection }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const emendaments = useSelector((state) => state.emendaments.emendaments);
  const formattedEmendaments = useSelector(
    (state) => state.emendaments.formattedEmendaments
  );
  /*   const emendamentSaved = useSelector(
    (state) => state.emendaments.emendamentSaved
  ); */
  const parlamentSection = useSelector(
    (state) => state.account.parlamentSection
  );
  /*   const ddlID = useSelector((state) => state.ddl.ddlID);
  const ddl = useSelector((state) => state.ddl); */
  /*   const isSenato = parlamentSection === "senato";
  const isCamera = parlamentSection === "camera";
  const ddlSenatoData = useSelector((state) => state.ddl.ddlSenatoData); */
  const conseguentementeActive = useSelector(
    (state) => state.emendaments.conseguentementeActive
  );
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  /*   const [isSavePopupOpen, setIsSavePopupOpen] = useState(false); */
  const [deletingEmendament, setDeletingEmendament] = useState(null);
  const [deletingText, setDeletingText] = useState(null);
  const profile = useSelector((state) => state.account.profile);

  const compareConseguentemente = (conseguentementeText, actualText) => {
    if (!conseguentementeText && !conseguentementeActive?.emendament?.length) {
      return false;
    }
    const formattedConseguentementeText =
      conseguentementeText?.emendament[0]?.replace(/<[^>]*>?/gm, "");

    if (actualText.includes(formattedConseguentementeText)) {
      return true;
    } else {
      return false;
    }
  };

  const handleDeleteEmendament = (emendament, text) => {
    /*   dispatch(removeEmendament({ text, section: emendament.textSection })); */
    dispatch(deleteEmendament(emendament.id));
    // ELIMINAZIONE NEI VARI ARRAY
    // dispatch(
    //   removeRemovedSection({
    //     section: emendament.section.split("Sopprimi l'").pop(),
    //     text: emendament.text,
    //   })
    // );
    // dispatch(
    //   removePremiseSection({
    //     section: emendament.section.split("all' ").pop(),
    //     text: emendament.text,
    //   })
    // );
    // dispatch(
    //   removeAddedSection({
    //     section: emendament.section.split("dopo ").pop(),
    //     text: emendament.text,
    //   })
    // );
    // dispatch(
    //   removeChangedSection({
    //     section: emendament.section.split("Sostituisci ").pop(),
    //     text: emendament.text,
    //   })
    // );
    setIsCancelPopupOpen(false);
    setDeletingEmendament(null);
    setDeletingText(null);
  };

  const handleClickSaveEmendaments = () => {
    navigate("/emendaments-list");
  };

  if (!formattedEmendaments || formattedEmendaments.length === 0) {
    return null;
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "var(--backgroundColor)",
        }}
      >
        {/* {!!isSavePopupOpen && (
          <Popup cardWidth="35%">
            <div className="flex-column justify-center align-center">
              <h3
                style={{
                  fontWeight: 600,
                }}
              >
                EMENDAMENTI SALVATI CORRETTAMENTE
              </h3>
              <div className="flex-row align-center justify-center w-100 mt-2">
                <Button
                  backgroundColor={"var(--primary)"}
                  handleclick={() => {
                    setIsSavePopupOpen(false);
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          </Popup>
        )} */}
        {!!isCancelPopupOpen && (
          <Popup cardWidth="35%">
            <div className="flex-column justify-center align-center">
              <h3
                style={{
                  fontWeight: 600,
                }}
              >
                SICURO DI VOLER ELIMINARE L'EMENDAMENTO?
              </h3>
              <p>Non sarà possibile recuperarlo.</p>
              <div className="flex-row align-center justify-between w-100 mt-2">
                <Button handleclick={() => setIsCancelPopupOpen(false)}>
                  Annulla
                </Button>
                <Button
                  backgroundColor={"var(--danger)"}
                  handleclick={() => {
                    handleDeleteEmendament(deletingEmendament, deletingText);
                  }}
                >
                  Elimina
                </Button>
              </div>
            </div>
          </Popup>
        )}
        <div
          style={{
            backgroundColor: "var(--primary)",
            width: "100%",
            boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "48px",
          }}
        >
          <h2
            style={{
              color: "white",
              fontWeight: 600,
              padding: "0px 20px",
              fontSize: "1.1rem",
              letterSpacing: "2px",
            }}
          >
            I miei emendamenti
          </h2>
          <div className="flex-row justify-between align-center">
            <Button
              style={{
                border: "2px solid white",
                paddingVertical: "0px",
                marginRight: "8px",
                width: "200px",
                maxWidth: "200px",
                backgroundColor: "var(--primary)",
              }}
              handleclick={() => {
                handleClickSaveEmendaments();
              }}
            >
              Scarica emendamenti
              <FaDownload
                style={{
                  marginLeft: "10px",
                }}
                color="#fff"
              />
            </Button>
            {!!profile && <Avatar profile={profile} />}
          </div>
        </div>
        <div
          className="mb-2"
          style={{
            width: "30vw",
            padding: "10px",
            borderLeft: "2px solid #ccc",
            height:
              parlamentSection === "camera"
                ? "calc(100vh - 75px - 48px )"
                : "calc(100vh - 48px )",
            minHeight:
              parlamentSection === "camera"
                ? "calc(100vh - 75px - 48px )"
                : "calc(100vh - 48px )",
            overflowY: "auto",
            padding: "20px",
            position: "relative",
          }}
        >
          <div>
            {formattedEmendaments.map((emendament, index) => (
              <div
                key={index}
                style={{
                  position: "relative",
                  borderBottom: "6px double #bbb",
                  padding: "10px 0px",
                  marginBottom: "4px",
                }}
              >
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: "1.1rem",
                    marginBottom: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  {emendament.section}
                </h3>
                {emendament.emendaments?.map((emendamentObj, i) => {
                  // Sostituzione del testo per formattazione
                  const formattedText = emendamentObj.emendament[0].replace(
                    /conseguentemente,/gi,
                    "<br/>Conseguentemente,<br/>"
                  );

                  return (
                    <StyledEmendamentCard
                      isLast={i === emendament.emendaments.length - 1}
                      onClick={() => {
                        setClickedSection(emendamentObj.textSection);
                      }}
                      key={`${i}-${emendamentObj.textSection}`}
                    >
                      <div>
                        <div style={{ width: "90%" }}>
                          <div
                            dangerouslySetInnerHTML={{ __html: formattedText }}
                          />
                          {compareConseguentemente(
                            conseguentementeActive,
                            emendamentObj.emendament[0]
                          ) &&
                            i === emendament.emendaments.length - 1 && (
                              <ConseguentementeTag />
                            )}
                        </div>

                        <Button
                          handleclick={() => {
                            setIsCancelPopupOpen(true);
                            setDeletingEmendament(emendamentObj);
                            setDeletingText(emendamentObj.emendament[0]);
                          }}
                          backgroundColor="var(--danger)"
                          width="30px"
                          style={{
                            position: "absolute",
                            right: "0px",
                            top: "8px",
                          }}
                        >
                          <FaTrashCan color="#fff" />
                        </Button>
                      </div>
                    </StyledEmendamentCard>
                  );
                })}
              </div>
            ))}
            <div></div>
          </div>
        </div>
      </div>
    );
  }
};

export default EmendamentsRecap;
