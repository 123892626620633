import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAuthToken } from "./features/accountSlice";

const CallbackPage = () => {
  // get code param from url
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  useEffect(() => {
    if (code) {
      dispatch(getAuthToken());
    }
  }, [code]);
  return <div>{code}</div>;
};

export default CallbackPage;
