import { cleanKey } from "../../../helpers/formatStringFuncs";

export const handleBulletKeyString = (keyString) => {
  switch (keyString) {
    /* case "a:Articolo":
      return "ARTICOLI";
    case "a:Comma":
      return "COMMI";
    case "a:Lettera":
      return "LETTERE";
    case "a:Numero":
      return "NUMERI"; */
    default:
      return cleanKey(keyString);
  }
};

export const formatCommaLabel = (commaLabel) => {
  let formatted = `Comma ${commaLabel.split(/  /)[1]}`;
  if (!commaLabel.split(/  /)[1]) {
    formatted = `Comma ${commaLabel.split("&#8195;&#8194;")[1]}`;
  }
  formatted = formatted.split(".")[0];
  return formatted;
};

export const formatLetterLabel = (letterLabel) => {
  let formatted = `Lettera ${letterLabel.split(/  <i>/)[1]}`;
  if (!letterLabel.split(/  <i>/)[1]) {
    formatted = `Lettera ${letterLabel.split("&#8195;&#8195;&#8194;<i>")[1]}`;
  }
  formatted = formatted.split(")")[0];
  return formatted;
};
export const formatNumberLabel = (numberLabel) => {
  let formatted = `Numero ${numberLabel.split(/  /)[1]}`;
  if (!numberLabel.split(/  /)[1]) {
    formatted = `Numero ${
      numberLabel.split("&#8195;&#8195;&#8195;&#8194;")[1]
    }`;
  }
  formatted = formatted.split(")")[0];
  return formatted;
};
