import React from "react";
import { cleanParagraphPath } from "../../helpers/formatStringFuncs";
import ContextMenu from "./ContextMenu";
import Tag from "../tag/Tag";
import MoreIcon from "../../shared/MoreIcon";

const ContextMenuArticle = ({
  setParagraphPath,
  setIsEditorPopupOpen,
  id,
  newPath,
  setEditorData,
  setIsEditing,
  setEditorAction,
  setSectionType,
}) => {
  return (
    <ContextMenu
      items={[
        {
          label: "Aggiungi articolo",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath(
              "Aggiungi articolo dopo " + cleanParagraphPath(newPath)
            );
            setIsEditorPopupOpen(true);
            setIsEditing(true);
            setEditorAction("add");
            setSectionType("articolo");
          },
        },
        {
          label: "Sopprimi articolo",
          handleItemClick: () => {
            setIsEditorPopupOpen(true);
            setEditorData("");
            setParagraphPath("Sopprimi l'" + cleanParagraphPath(newPath));
            setEditorAction("delete");
            setSectionType("articolo");
          },
        },
        {
          label: "Premetti articolo",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath(
              "Premetti articolo all' " + cleanParagraphPath(newPath)
            );
            setIsEditorPopupOpen(true);
            setIsEditing(true);
            setEditorAction("premise");
            setSectionType("articolo");
          },
        },
        {
          label: "Sostituisci articolo",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath("Sostituisci " + cleanParagraphPath(newPath));
            setIsEditorPopupOpen(true);
            setIsEditing(true);
            setEditorAction("replace");
            setSectionType("articolo");
          },
        },
      ]}
      backgroundColor={"var(--articolo)"}
      id={`articolo_${id}`}
    >
      <Tag backgroundColor={"var(--articolo)"}>Articolo</Tag>
      <MoreIcon color="var(--articolo)" />
    </ContextMenu>
  );
};

export default ContextMenuArticle;
