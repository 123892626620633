import { getPositionReference } from "./formatStringFuncs";

////////////// AGGIUNGERE //////////////
export const getAddedSection = (
  sectionType,
  paragraphPath,
  editorData,
  parlamentSection,
  conseguentementeActive
) => {
  const cleanEditedData = editorData;

  const isSenato = parlamentSection === "senato";
  const isCamera = parlamentSection === "camera";

  // puliamo il path prendendo solo parte utile dal titolo
  const cleanParagraphPath = paragraphPath.split("dopo ").pop();

  const positionReference = getPositionReference(
    paragraphPath,
    "add",
    conseguentementeActive
  );

  // prendiamo l'ultimo numero e aggiungiamo il -bis
  const paragraphPathBis = cleanParagraphPath.split(" ").pop() + "-bis.";

  const addedPhrases = [];

  if (!!isSenato) {
    // distinguere fra articolo, comma, lettera, numero
    if (sectionType === "articolo") {
      // return `Dopo l'${positionReference}, aggiungere il seguente: «Articolo ${paragraphPathBis} ${cleanEditedData}»`;
      addedPhrases.push(`${positionReference}, <i>aggiungere il seguente:</i>  
    <br />
     «Articolo ${paragraphPathBis} ${cleanEditedData}»`);
    }
    if (sectionType === "comma") {
      // return `Dopo l'${positionReference}, aggiungere il seguente: «${paragraphPathBis} ${cleanEditedData}»`;
      addedPhrases.push(`${positionReference}, <i>aggiungere il seguente:</i>  
    <br />
     «${paragraphPathBis} ${cleanEditedData}»`);
    }
    if (sectionType === "lettera") {
      // return `Dopo l'${positionReference} , aggiungere il seguente: «${paragraphPathBis} ${cleanEditedData}»`;
      addedPhrases.push(`${positionReference} , <i>aggiungere la seguente:</i>  
    <br />
     «${paragraphPathBis} ${cleanEditedData}»`);
    }
    if (sectionType === "numero") {
      // return `Dopo l'${positionReference}, aggiungere il seguente: «${paragraphPathBis} ${cleanEditedData}»`;
      addedPhrases.push(`${positionReference}, <i>aggiungere il seguente:</i>  
    <br />
     «${paragraphPathBis} ${cleanEditedData}»`);
    }
  } else if (!!isCamera) {
    // distinguere fra articolo, comma, lettera, numero
    if (sectionType === "articolo") {
      // return `Dopo l'${positionReference}, aggiungere il seguente: «Articolo ${paragraphPathBis} ${cleanEditedData}»`;
      addedPhrases.push(`${positionReference}, <i>aggiungere il seguente:</i>  
    <br />
     «Art. ${paragraphPathBis} ${cleanEditedData}»`);
    }
    if (sectionType === "comma") {
      // return `Dopo l'${positionReference}, aggiungere il seguente: «${paragraphPathBis} ${cleanEditedData}»`;
      addedPhrases.push(`${positionReference}, <i>aggiungere il seguente:</i>  
    <br />
     «${paragraphPathBis} ${cleanEditedData}»`);
    }
    if (sectionType === "lettera") {
      // return `Dopo l'${positionReference} , aggiungere il seguente: «${paragraphPathBis} ${cleanEditedData}»`;
      addedPhrases.push(`${positionReference} , <i>aggiungere la seguente:</i>  
    <br />
     «${paragraphPathBis} ${cleanEditedData}»`);
    }
    if (sectionType === "numero") {
      // return `Dopo l'${positionReference}, aggiungere il seguente: «${paragraphPathBis} ${cleanEditedData}»`;
      addedPhrases.push(`${positionReference}, <i>aggiungere il seguente:</i>  
    <br />
     «${paragraphPathBis} ${cleanEditedData}»`);
    }
  }

  return [{ emendament: addedPhrases, section: paragraphPath }];
};

////////////// SOPPRIMERE //////////////
export const getDeletedSection = (
  sectionType,
  paragraphPath,
  parlamentSection,
  conseguentementeActive
) => {
  const isSenato = parlamentSection === "senato";
  const isCamera = parlamentSection === "camera";
  // puliamo il path prendendo solo parte utile dal titolo
  const cleanParagraphPath = paragraphPath.split("Sopprimi l'").pop();
  const positionReference = getPositionReference(
    paragraphPath,
    "delete",
    conseguentementeActive
  );
  // distinguere fra articolo, comma, lettera, numero

  const deletedPhrases = [];

  // TODO: cambio sintassi?

  if (!!isSenato) {
    if (sectionType === "articolo") {
      deletedPhrases.push(`${positionReference}`);
    }
    if (sectionType === "comma") {
      deletedPhrases.push(`${positionReference}`);
    }
    if (sectionType === "lettera") {
      deletedPhrases.push(`${positionReference}`);
    }
    if (sectionType === "numero") {
      deletedPhrases.push(`${positionReference}`);
    }
  } else if (!!isCamera) {
    if (sectionType === "articolo") {
      deletedPhrases.push(`<i>Sopprimerlo</i>`);
    }
    if (sectionType === "comma") {
      deletedPhrases.push(`${positionReference}`);
    }
    if (sectionType === "lettera") {
      deletedPhrases.push(`${positionReference}`);
    }
    if (sectionType === "numero") {
      deletedPhrases.push(`${positionReference}`);
    }
  }

  return [{ emendament: deletedPhrases, section: paragraphPath }];
};

////////////// PREMETTERE //////////////
export const getPremiseSection = (
  sectionType,
  paragraphPath,
  editorData,
  parlamentSection,
  conseguentementeActive
) => {
  const cleanEditedData = editorData;

  const isSenato = parlamentSection === "senato";
  const isCamera = parlamentSection === "camera";

  // puliamo il path prendendo solo parte utile dal titolo
  const cleanParagraphPath = paragraphPath.split("all' ").pop();
  const premiseSection = "0" + cleanParagraphPath.split(" ").pop();

  const positionReference = getPositionReference(
    paragraphPath,
    "premise",
    conseguentementeActive
  );

  const premisedPhrases = [];

  if (!!isSenato) {
    // distinguere fra articolo, comma, lettera, numero
    if (sectionType === "articolo") {
      // return `Al ${positionReference}, premettere il seguente articolo: «Articolo ${premiseSection}. ${cleanEditedData}»`;
      premisedPhrases.push(`${positionReference} <i>premettere il seguente:</i>    
    <br />
     «Articolo ${premiseSection}. ${cleanEditedData}»`);
    }
    if (sectionType === "comma") {
      // return `Al ${positionReference}, premettere il seguente: «${premiseSection}. ${cleanEditedData}»`;
      premisedPhrases.push(`${positionReference} <i>premettere il seguente:</i>    
    <br />
     «${premiseSection}. ${cleanEditedData}»`);
    }
    if (sectionType === "lettera") {
      // premisedPhrases.push( `Al ${positionReference}, premettere il seguente: «${premiseSection}. ${cleanEditedData}»`;
      premisedPhrases.push(`${positionReference} <i>premettere la seguente lettera:</i>   
    <br />
    «${premiseSection}. ${cleanEditedData}»`);
    }
    if (sectionType === "numero") {
      // premisedPhrases.push( `Al ${positionReference}, premettere il seguente: «${premiseSection}. ${cleanEditedData}»`;
      premisedPhrases.push(`${positionReference} <i>premettere il seguente:</i>   
    <br />
     «${premiseSection}. ${cleanEditedData}»`);
    }
  } else if (!!isCamera) {
    // distinguere fra articolo, comma, lettera, numero
    if (sectionType === "articolo") {
      // return `Al ${positionReference}, premettere il seguente articolo: «Articolo ${premiseSection}. ${cleanEditedData}»`;
      premisedPhrases.push(`${positionReference} <i>premettere il seguente:</i>   
    <br />
     «Art. ${premiseSection}. ${cleanEditedData}»`);
    }

    if (sectionType === "comma") {
      // return `Al ${positionReference}, premettere il seguente: «${premiseSection}. ${cleanEditedData}»`;
      premisedPhrases.push(`${positionReference} <i>premettere il seguente:</i>  
    <br />
     «${premiseSection}. ${cleanEditedData}»`);
    }
    if (sectionType === "lettera") {
      // premisedPhrases.push( `Al ${positionReference}, premettere il seguente: «${premiseSection}. ${cleanEditedData}»`;
      premisedPhrases.push(`${positionReference} <i>premettere la seguente lettera:<i>    
    <br />
    «${premiseSection}. ${cleanEditedData}»`);
    }
    if (sectionType === "numero") {
      // premisedPhrases.push( `Al ${positionReference}, premettere il seguente: «${premiseSection}. ${cleanEditedData}»`;
      premisedPhrases.push(`${positionReference} <i>premettere il seguente:<i>   
    <br />
     «${premiseSection}. ${cleanEditedData}»`);
    }
  }

  return [{ emendament: premisedPhrases, section: paragraphPath }];
};

////////////// SOSTITUIRE /////////////
export const getReplacedSection = (
  sectionType,
  paragraphPath,
  editorData,
  parlamentSection,
  conseguentementeActive
) => {
  const cleanEditedData = editorData.replace(/<[^>]*>?/gm, "");

  const isSenato = parlamentSection === "senato";
  const isCamera = parlamentSection === "camera";

  // puliamo il path prendendo solo parte utile dal titolo
  const cleanParagraphPath = paragraphPath.split("Sostituisci ").pop();

  const positionReference = getPositionReference(
    paragraphPath,
    "substitute",
    conseguentementeActive
  );
  const substitutedSection = cleanParagraphPath.split(" ").pop();

  const replacedPhrases = [];

  if (!!isSenato) {
    // distinguere fra articolo, comma, lettera, numero
    if (sectionType === "articolo") {
      // return `Sostituire l'${cleanParagraphPath} con il seguente: «${cleanParagraphPath}. ${cleanEditedData}»`;
      replacedPhrases.push(`${positionReference} <i>con il seguente:</i>   
    <br />
     «${cleanParagraphPath?.replace("Articolo", "Art.")} ${cleanEditedData}»`);
    }

    if (sectionType === "comma") {
      // return `Sostituire ${cleanParagraphPath} con il seguente: «${substitutedSection}. ${cleanEditedData}»`;
      replacedPhrases.push(`${positionReference} <i>con il seguente:</i>    
    <br />
    «${substitutedSection}. ${cleanEditedData}»`);
    }

    if (sectionType === "lettera") {
      // return `Sostituire ${cleanParagraphPath} con la seguente il seguente: «${substitutedSection}) ${cleanEditedData}»`;
      replacedPhrases.push(`${positionReference} <i>con la seguente:</i>    
    <br />
     «${substitutedSection}) ${cleanEditedData}»`);
    }

    if (sectionType === "numero") {
      // return `Sostituire ${cleanParagraphPath} con il seguente: «${substitutedSection}. ${cleanEditedData}»`;
      replacedPhrases.push(`${positionReference} <i>con il seguente:</i>   
    <br />
     «${substitutedSection}. ${cleanEditedData}»`);
    }
    if (sectionType === "rubrica") {
      replacedPhrases.push(`<i>Sostituire rubrica con la seguente:</i>    
    <br />
     «${cleanEditedData}»`);
    }
  } else if (!!isCamera) {
    // distinguere fra articolo, comma, lettera, numero
    if (sectionType === "articolo") {
      // return `Sostituire l'${cleanParagraphPath} con il seguente: «${cleanParagraphPath}. ${cleanEditedData}»`;
      replacedPhrases.push(`${positionReference} <i>con il seguente:</i>    
    <br />
     «${cleanParagraphPath?.replace("Articolo", "Art.")}. ${cleanEditedData}»`);
    }

    if (sectionType === "comma") {
      // return `Sostituire ${cleanParagraphPath} con il seguente: «${substitutedSection}. ${cleanEditedData}»`;
      replacedPhrases.push(`${positionReference} <i>con il seguente:</i>     
    <br />
    «${substitutedSection}. ${cleanEditedData}»`);
    }

    if (sectionType === "lettera") {
      // return `Sostituire ${cleanParagraphPath} con la seguente il seguente: «${substitutedSection}) ${cleanEditedData}»`;
      replacedPhrases.push(`${positionReference} <i>con la seguente:</i>   
    <br />
     «${substitutedSection}) ${cleanEditedData}»`);
    }

    if (sectionType === "numero") {
      // return `Sostituire ${cleanParagraphPath} con il seguente: «${substitutedSection}. ${cleanEditedData}»`;
      replacedPhrases.push(`${positionReference} <i>con il seguente:</i>  
    <br />
     «${substitutedSection}. ${cleanEditedData}»`);
    }

    if (sectionType === "rubrica") {
      replacedPhrases.push(`<i>Sostituire rubrica con la seguente:</i>   
    <br />
     «${cleanEditedData}»`);
    }
  }

  return [{ emendament: replacedPhrases, section: paragraphPath }];
};
