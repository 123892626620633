import styled from "styled-components";
import RecIcon from "../../shared/RecIcon";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setConseguentementeActive } from "../../features/emendamentsSlice";

const StyledTag = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #f1f1f1;
  position: relative;
`;

const StyledP = styled.p`
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  margin-left: 32px;
  padding: 0;
`;

const ConseguentementeTag = () => {
  const dispatch = useDispatch();
  return (
    <StyledTag>
      <RecIcon size="24px" top={"10px"} />
      <StyledP>Conseguentemente...</StyledP>

      <MdClose
        style={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(setConseguentementeActive(false));
        }}
        size="24px"
      />
    </StyledTag>
  );
};
export default ConseguentementeTag;
