import React, { useState } from "react";
import { styled } from "styled-components";
import { IconWithTooltip } from "../../shared/IconWithTooltip";
import { useSelector } from "react-redux";
import { renderXMLTree } from "./component/RenderXMLTree";
import { renderXHTMLTree } from "./component/RenderXHTMLTree";

const RotateText = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transform: rotate(-90deg);
  font-size: 1.4rem;
  padding: 0px 12px 0px 12px;
  color: var(--primary);
  font-weight: 600;
`;

function BulletTree({
  data,
  selectedSection,
  setSelectedSection,
  sectionWidth,
  isBulletTreeOpen,
  setIsBulletTreeOpen,
  fileType,
  setClickedSection,
}) {
  const [expandedSections, setExpandedSections] = useState({});
  const parlamentSection = useSelector(
    (state) => state.account.parlamentSection
  );
  // const ddlCameraFormattedText = useSelector(
  //   (state) => state.ddl.ddlCameraFormattedText
  // );

  const toggleSection = (path) => {
    setExpandedSections((prevState) => {
      const newPathState = { ...prevState };

      // Espandi o collassa la sezione cliccata
      newPathState[path] = !prevState[path];

      // Ottieni tutte le chiavi al livello corrente
      const currentLevelKeys = Object.keys(prevState).filter(
        (key) =>
          key?.startsWith(path.substring(0, path.lastIndexOf(" "))) &&
          key !== path
      );

      // Collassa tutte le altre sezioni allo stesso livello
      for (const key of currentLevelKeys) {
        newPathState[key] = false;
      }

      return newPathState;
    });
  };

  // XHTML TREE

  const stripData = (html) => {
    //replace <em> and </em> with <i> and </i>
    html = html.replace(/«[^»]*»/g, (match) => {
      return match.replace(/<[^>]*>/g, " ");
    });

    html = html.replace(/<\/p>\s*<p>\s{2,}«/g, " «");
    html = html.replace(/<em/g, "<i");
    html = html.replace(/<\/em/g, "</i");

    // strip all the tags between « and » but not the letters

    return html;
  };

  return (
    <div
      style={{
        backgroundColor: "var(--backgroundColor)",
        width: sectionWidth,
        overflowY: "auto",
        transition: "width 0.2s ease-in-out",
      }}
    >
      <div
        style={{
          backgroundColor: "var(--primary)",
          width: "100%",
          display: "flex",
          justifyContent: !!isBulletTreeOpen ? "space-between" : "center",
          alignItems: "center",
          padding: !!isBulletTreeOpen ? "0px 12px 0px 12px" : "6px 0px",
          boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
          minHeight: "48px",
        }}
      >
        {!!isBulletTreeOpen && (
          <h2
            style={{
              color: "white",
              fontWeight: 600,
              fontSize: "1.5rem",
              letterSpacing: "2px",
            }}
          >
            Articolato
          </h2>
        )}
        <div className="flex-row justify-between align-center">
          {/* {!!isBulletTreeOpen && (
            <IconWithTooltip
              iconName="MdPlaylistRemove"
              tooltipText="Chiudi Articolato"
              handleclick={() => setExpandedSections({})}
              marginLeft="4px"
            />
          )} */}
          {!!isBulletTreeOpen ? (
            <IconWithTooltip
              iconName="RiExpandLeftFill"
              tooltipText="Collassa Sezione Articolato"
              handleclick={() => setIsBulletTreeOpen(false)}
              marginLeft="4px"
            />
          ) : (
            <IconWithTooltip
              iconName="RiExpandRightFill"
              tooltipText="Espandi Sezione Articolato"
              handleclick={() => setIsBulletTreeOpen(true)}
              marginLeft="4px"
              position="fixed"
            />
          )}
        </div>
      </div>
      <div
        className="bullet-tree"
        style={{
          listStyle: "none",
          borderRight: "2px solid #ccc",
          padding: !!isBulletTreeOpen ? "24px 20px 32px 32px" : "0px",
          overflow: "auto",
          height:
            parlamentSection === "camera"
              ? "calc(100vh - 75px - 48px )"
              : "calc(100vh - 48px )",
          minHeight:
            parlamentSection === "camera"
              ? "calc(100vh - 75px - 48px )"
              : "calc(100vh - 48px )",
        }}
      >
        {!!isBulletTreeOpen ? (
          <>
            {fileType === "xml"
              ? renderXMLTree(
                  data["a:Articolato"],
                  0,
                  "",
                  "",
                  setSelectedSection,
                  expandedSections,
                  toggleSection,
                  setClickedSection,
                  selectedSection
                )
              : renderXHTMLTree(
                  stripData(data),
                  0,
                  "",
                  "",
                  setSelectedSection,
                  expandedSections,
                  toggleSection,
                  selectedSection,
                  setClickedSection
                )}
          </>
        ) : (
          <RotateText>Articolato</RotateText>
        )}
      </div>
    </div>
  );
}

export default BulletTree;
