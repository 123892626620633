import React from "react";
import styled from "styled-components";
import { FaXmark } from "react-icons/fa6";
import Draggable from "react-draggable";

const Container = styled.div`
  display: flex;
  background-color: #00000050;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh !important;
  width: 100vw !important;
  align-items: center;
  justify-content: center;
  z-index: 1100;
`;

const StyledCard = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: ${(props) => props.cardWidth || "65%"};
  align-items: ${(props) => props.cardAlignItems};
  justify-content: center;
  background-color: ${(props) => props.backgroundColor || "#fff"};
  border-radius: 16px;
  padding: ${(props) => props.paddingCard || "32px"};
  min-height: ${(props) => props.minHeight || "0px"};
  max-height: ${(props) => props.height || "100%"};
  @media (max-width: 768px) {
    padding: ${(props) => props.paddingCardMobile || "24px"};
    width: 80%;
  }
  cursor: move;
`;

const StyledCloseIcon = styled(FaXmark)`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  cursor: pointer;
  color: #000;
  &:hover {
    color: #00000050;
  }
`;

export const Popup = (props) => {
  return (
    <Container {...props}>
      <Draggable cancel=".no-drag">
        <StyledCard {...props}>
          {props.handleClose && (
            <StyledCloseIcon onClick={() => props.handleClose()} />
          )}
          <div
            style={{
              cursor: "initial",
            }}
          >
            {props.children}
          </div>
        </StyledCard>
      </Draggable>
    </Container>
  );
};
