import { useEffect } from "react";
import FaviconCamera from "../assets/favicon-camera.ico";
import FaviconSenato from "../assets/favicon-senato.ico";

const useDynamicCSSVariables = () => {
  useEffect(() => {
    const loadCSSVariables = async () => {
      const parlamentSection = process.env.REACT_APP_PARLAMENT_SECTION;
      const linkElement =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      linkElement.type = "image/x-icon";
      linkElement.rel = "shortcut icon";
      if (parlamentSection === "camera") {
        await import("../styles/_variablesCamera.scss");
        linkElement.href = FaviconCamera;
      } else if (parlamentSection === "senato") {
        await import("../styles/_variablesSenato.scss");
        linkElement.href = FaviconSenato;
      }
    };

    loadCSSVariables();
  }, []); // Dipendenze vuote significano che l'effetto verrà eseguito solo al montaggio del componente
};

export default useDynamicCSSVariables;
