export const getRelativeOffsetTop = (child, parent) => {
  let offset = 0;
  let el = child;

  while (el && el !== parent) {
    offset += el.offsetTop;
    el = el.offsetParent;
  }

  return offset + 60;
};
