import React, { useState, useEffect, useRef } from "react";
import { Popup } from "../../components/popup/Popup";
import "../../styles/general.scss";
import Editor from "../../components/editor/Editor";
import { Button } from "../../shared/Buttons";
import {
  cleanParagraphPath,
  convertForClassNames,
  getAddedSectionTitle,
  getPremisedSectionTitle,
} from "../../helpers/formatStringFuncs";
import { useDispatch, useSelector } from "react-redux";
import {
  getSinglePdlEmendamentsByUserId,
  resetEmendaments,
  setFormattedEmendaments,
} from "../../features/emendamentsSlice";
import DiffViewer from "../../components/diff-viewer/DiffViewer";
import { resetDdl } from "../../features/ddlSlice";
import { IconWithTooltip } from "../../shared/IconWithTooltip";
import { renderXmlNode } from "./components/RenderXmlNode";
import { getRelativeOffsetTop } from "../../helpers/LayoutFuncs";
import { useNavigate } from "react-router-dom";

const XmlViewer = ({
  xmlData,
  setXmlData,
  selectedSection,
  setSelectedSection,
  viewerWidth,
  clickedSection,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const viewer = useRef(null);
  const ddlID =
    useSelector((state) => state.ddl.ddlID) || localStorage.getItem("ddlID");
  const profile = useSelector((state) => state.account.profile);
  const reduxEmendaments = useSelector(
    (state) => state.emendaments.emendaments
  );

  const [isEditorPopupOpen, setIsEditorPopupOpen] = useState(false);
  const [isDiffViewerPopupOpen, setIsDiffViewerPopupOpen] = useState(false);
  const [isExitPopupOpen, setIsExitPopupOpen] = useState(false);
  const [diffViewerOldText, setDiffViewerOldText] = useState("");
  const [diffViewerNewText, setDiffViewerNewText] = useState("");
  const [editorData, setEditorData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isTagsVisibile, setIsTagsVisibile] = useState(true);
  const [emendaments, setEmendaments] = useState([]);
  const [sectionChanged, setSectionChanged] = useState([]);
  const [paragraphPath, setParagraphPath] = useState("");
  const [editorAction, setEditorAction] = useState("");
  const [sectionType, setSectionType] = useState("");

  // const sectionChangedList = useSelector(
  //   (state) => state.emendaments.sectionsChanged
  // );
  // const listedEmendaments = useSelector(
  //   (state) => state.emendaments.emendaments
  // );
  // const sectionsRemovedList = useSelector(
  //   (state) => state.emendaments.sectionsRemoved
  // );

  // const sectionPremisedList = useSelector(
  //   (state) => state.emendaments.sectionsPremised
  // );

  // const sectionAddedList = useSelector(
  //   (state) => state.emendaments.sectionsAdded
  // );

  useEffect(() => {
    if (reduxEmendaments && reduxEmendaments.length > 0) {
      const formattedEmendaments = reduxEmendaments.map((emendament) => {
        let formattedSection = emendament.section.split("Articolo");
        formattedSection = formattedSection[1].split(" ");

        // recap completo
        /*  formattedSection = "Articolo " + formattedSection; */

        // recap solo articolo

        formattedSection = "Articolo " + formattedSection[1];

        // // tolgo ultime due parole
        // formattedSection = formattedSection
        //   .split(" ")
        //   .slice(0, -2)
        //   .join(" ")
        //   .trim();

        let trimmedEmendaments = null;
        if (Array.isArray(emendament.emendament)) {
          trimmedEmendaments = emendament.emendament.map((text) =>
            text.replace(/<(?!\/?i(?:\s+[^>]*)?>)[^>]*>/gm, "")
          );
        } else if (typeof emendament.emendament === "string") {
          trimmedEmendaments = emendament.emendament.replace(
            /<(?!\/?i(?:\s+[^>]*)?>)[^>]*>/gm,
            ""
          );
        } else {
          console.log("emendament.emendament non è né un array né una stringa");
        }

        return {
          /*         title: formattedSection,
           */
          ...emendament,
          textSection: emendament.section,
          section: formattedSection,
          emendament: Array.isArray(emendament.emendament)
            ? trimmedEmendaments
            : [trimmedEmendaments],
        };
      });

      // eliminare copie di emendamenti
      const uniqueEmendaments = [];
      formattedEmendaments.forEach((emendament) => {
        const index = uniqueEmendaments.findIndex(
          (uniqueEmendament) =>
            uniqueEmendament.emendament[0] === emendament.emendament[0] &&
            uniqueEmendament.section === emendament.section
        );
        if (index === -1) {
          uniqueEmendaments.push(emendament);
        }
      });

      // se ci sono emendamenti con la sezione uguale, unirli
      const mergedEmendaments = [];
      uniqueEmendaments.forEach((emendament) => {
        const index = mergedEmendaments.findIndex(
          (mergedEmendament) => mergedEmendament.section === emendament.section
        );

        if (index === -1) {
          // Se non esiste ancora un emendamento con questa sezione, aggiungi un nuovo oggetto
          mergedEmendaments.push({
            section: emendament.section,
            emendaments: [
              {
                id: emendament.id,
                textSection: emendament.textSection,
                emendament: emendament.emendament,
              },
            ],
          });
        } else {
          // Altrimenti, aggiungi l'emendamento corrente all'array esistente
          mergedEmendaments[index].emendaments.push({
            id: emendament.id,
            textSection: emendament.textSection,
            emendament: emendament.emendament,
          });
        }
      });
      // sort by section
      mergedEmendaments.sort((a, b) => {
        const aSection = a.section.split(" ").pop();
        const bSection = b.section.split(" ").pop();
        return aSection - bSection;
      });

      dispatch(setFormattedEmendaments(mergedEmendaments));
      console.log("mergedEmendaments", mergedEmendaments);
      return () => {
        dispatch(setFormattedEmendaments([]));
      };
    }
  }, [reduxEmendaments]);

  useEffect(() => {
    dispatch(
      getSinglePdlEmendamentsByUserId({
        pdlId: ddlID,
        userId: profile?.sub || 99,
      })
    );
  }, [profile, ddlID]);

  // Aggiungi l'event listener allo scroll
  useEffect(() => {
    const viewerElement = viewer.current;
    viewerElement.addEventListener("scroll", handleScroll);

    // Pulizia dell'event listener
    return () => {
      viewerElement.removeEventListener("scroll", handleScroll);
    };
  }, [selectedSection]);

  useEffect(() => {
    if (!!clickedSection && viewer.current) {
      // get element with id = selectedSection
      const sectionDiv = viewer.current.querySelector(
        `[id='${clickedSection.replace(/\./g, "\\.")}']`
      );
      if (!!sectionDiv) {
        const topOffset = getRelativeOffsetTop(sectionDiv, viewer.current);
        viewer.current.scrollTop = topOffset - 150;
      }
    }
  }, [clickedSection]);

  /*   useEffect(() => {
    if (!!selectedSection && viewer.current) {
      const sectionDiv = viewer.current.querySelector(
        `div[section="${selectedSection}"]`
      );

      if (!!sectionDiv) {
        const topOffset = getRelativeOffsetTop(sectionDiv, viewer.current);
        viewer.current.scrollTop = topOffset - 100;
      }
    }
  }, [selectedSection]); */

  // Funzione per determinare la sezione visibile
  const handleScroll = () => {
    // Ottieni tutte le sezioni
    const sections = viewer.current.querySelectorAll("[id]");
    let nearestSection = null;
    let minDistance = Infinity;

    // filter sections that contains art.
    const filteredSections = [...sections].filter((section) => {
      return section.id.includes("Articolo");
    });

    filteredSections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      const distance = Math.abs(rect.top - 60);

      if (distance < minDistance) {
        minDistance = distance;
        nearestSection = section.id;
      }
    });

    if (nearestSection !== null && nearestSection !== selectedSection) {
      setSelectedSection(nearestSection); // Aggiorna la sezione selezionata
    }
  };

  return (
    <div
      style={{
        /*    border: "1px solid", */
        backgroundColor: "var(--backgroundColor)",
        height: "100vh",
        width: viewerWidth,
      }}
    >
      {!!isExitPopupOpen && (
        <Popup cardWidth="35%">
          <div className="flex-column justify-center align-center">
            <h2>SICURO DI VOLER USCIRE?</h2>
            <p>Tutte le modifiche andranno perse.</p>
            <div className="flex-row align-center justify-between w-100 mt-2">
              <Button handleclick={() => setIsExitPopupOpen(false)}>
                Annulla
              </Button>
              <Button
                backgroundColor={"var(--danger)"}
                handleclick={() => {
                  setIsEditorPopupOpen(false);
                  setIsEditing(false);
                  setIsEdited(false);
                  setParagraphPath("");
                  setEmendaments([]);
                  setSectionChanged([]);
                  setXmlData(null);
                  dispatch(resetEmendaments());
                  dispatch(resetDdl());
                }}
              >
                Esci
              </Button>
            </div>
          </div>
        </Popup>
      )}
      <div
        style={{
          backgroundColor: "var(--primary)",
          width: "100%",
          boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "20px",
          minHeight: "48px",
        }}
      >
        <h2
          style={{
            color: "white",
            fontWeight: 600,
            padding: "0px 20px",
            fontSize: "1.5rem",
            letterSpacing: "2px",
          }}
        >
          {"Atto " + ddlID}
        </h2>
        <div className="flex-row align-center">
          {!isTagsVisibile ? (
            <IconWithTooltip
              iconName="TbSchema"
              tooltipText="Mostra tag"
              handleclick={() => {
                setIsTagsVisibile(true);
              }}
            />
          ) : (
            <IconWithTooltip
              iconName="TbSchemaOff"
              tooltipText="Nascondi tag"
              handleclick={() => {
                setIsTagsVisibile(false);
              }}
            />
          )}

          {/* <IconWithTooltip
            iconName="TbDoorExit"
            tooltipText="Esci dall'editor"
            handleclick={() => setIsExitPopupOpen(true)}
            marginLeft="12px"
          /> */}
          <Button
            style={{
              width: "200px",
              maxWidth: "200px",
              color: "white",
              fontWeight: 600,
              padding: "6px 8px",
              letterSpacing: "2px",
              cursor: "pointer",
              border: "2px solid #fff",
              marginLeft: "12px",
              backgroundColor: "var(--primary)",
            }}
            handleclick={() => {
              setIsEditorPopupOpen(false);
              setIsEditing(false);
              setIsEdited(false);
              setParagraphPath("");
              setEmendaments([]);
              setSectionChanged([]);
              setXmlData(null);
              dispatch(resetEmendaments());
              dispatch(resetDdl());
              navigate("/");
            }}
          >
            Elenco provvedimenti
          </Button>
          {/*      <IconWithTooltip
            iconName="TbDoorExit"
            tooltipText="Esci dall'editor"
            handleclick={() => setIsExitPopupOpen(true)}
            marginLeft="12px"
          /> */}
        </div>
      </div>
      <div
        ref={viewer}
        style={{
          overflowY: "auto",
          padding: "20px",
          height: "100vh",
          width: viewerWidth,
        }}
      >
        {!!isEditorPopupOpen && (
          <Popup
            handleClose={() => {
              setIsEditorPopupOpen(false);
              setIsEditing(false);
              setIsEdited(false);
              setParagraphPath("");
            }}
            paddingCard={!!isEdited && "24px 32px 24px"}
          >
            <div className="no-drag">
              <Editor
                paragraphPath={cleanParagraphPath(paragraphPath)}
                editorData={editorData}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                setIsEdited={setIsEdited}
                setEmendaments={setEmendaments}
                setSectionChanged={setSectionChanged}
                setIsEditorPopupOpen={setIsEditorPopupOpen}
                editorAction={editorAction}
                sectionType={sectionType}
                isEdited={isEdited}
                emendaments={emendaments}
                xmlData={xmlData}
                setXmlData={setXmlData}
              />
            </div>
          </Popup>
        )}
        {!!isDiffViewerPopupOpen && (
          <Popup
            handleClose={() => {
              setIsDiffViewerPopupOpen(false);
            }}
            paddingCard={"24px 32px 24px"}
          >
            <h3 className="mb-1">
              {`Modifiche ${cleanParagraphPath(paragraphPath)} `}
            </h3>
            <div className="no-drag">
              <DiffViewer
                oldText={diffViewerOldText}
                newText={diffViewerNewText}
              />
            </div>
            <div className="flex-row justify-end full-width mt-1">
              <Button
                handleclick={() => {
                  setIsDiffViewerPopupOpen(false);
                }}
              >
                Chiudi
              </Button>
            </div>
          </Popup>
        )}
        <div>
          {xmlData &&
            renderXmlNode(
              xmlData,
              0,
              "",
              "",
              false,
              isTagsVisibile,
              setParagraphPath,
              setIsEditorPopupOpen,
              setEditorData,
              setIsEditing,
              setSectionType,
              setEditorAction
            )}
        </div>
      </div>
    </div>
  );
};

export default XmlViewer;
