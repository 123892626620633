import React, { useState } from "react";
import RecIcon from "../../shared/RecIcon";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button } from "../../shared/Buttons";
import DiffMatchPatch from "diff-match-patch";
import {
  // detectAddedWords,
  // detectRemovedWords,
  // detectReplacedWords,
  getChangedPhrases,
} from "../../helpers/GetChangesFuncs";
import {
  getAddedSection,
  getDeletedSection,
  getPremiseSection,
  getReplacedSection,
} from "../../helpers/EditSectionsFuncs";
import {
  addAddedSection,
  addChangedSection,
  addConseguentementeText,
  addEmendaments,
  addPremiseSection,
  addRemovedSection,
  getSinglePdlEmendamentsByUserId,
  removeAddedSection,
  removeChangedSection,
  removePremiseSection,
  removeRemovedSection,
  saveEmendament,
  setConseguentementeActive,
  updateEmendament,
} from "../../features/emendamentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "../popup/Popup";

const Editor = ({
  editorData,
  isEditing,
  setIsEditing,
  setIsEdited,
  setEmendaments,
  setSectionChanged,
  paragraphPath,
  setIsEditorPopupOpen,
  editorAction,
  sectionType,
  isEdited,
  emendaments,
  xmlData,
  setXmlData,
}) => {
  const dispatch = useDispatch();
  const [originalData, setOriginalData] = useState(editorData);
  const [editedData, setEditedData] = useState(editorData);
  const [isRedoPopupOpen, setIsRedoPopupOpen] = useState(false);
  const parlamentSection = useSelector(
    (state) => state.account.parlamentSection
  );
  const conseguentementeActive = useSelector(
    (state) => state.emendaments.conseguentementeActive
  );
  const reduxEmendaments = useSelector(
    (state) => state.emendaments.emendaments
  );
  const ddlID = useSelector((state) => state.ddl.ddlID);
  const profile = useSelector((state) => state.account.profile);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    console.log("data", data);
    setEditedData(data);
  };

  // in caso di sopressione non è necessario editor e cliccare su salva

  const handleDeleteRequest = () => {
    if (editorAction === "delete") {
      handleSave();
    }
  };

  function updateXmlData(data, originalData, editedData) {
    // Controlla se l'input è un oggetto per iterare sulle sue proprietà
    if (typeof data === "object" && data !== null) {
      return Object.entries(data).reduce(
        (acc, [key, value]) => {
          // Se il valore è un oggetto, applica la funzione ricorsivamente
          if (typeof value === "object") {
            acc[key] = updateXmlData(value, originalData, editedData);
          } else {
            // Altrimenti, effettua la sostituzione
            acc[key] = value === originalData ? editedData : value;
          }
          return acc;
        },
        Array.isArray(data) ? [] : {}
      );
    } else {
      // Se non è un oggetto, ritorna il dato inalterato
      return data;
    }
  }

  const handleSave = (conseguentementeActive) => {
    let conseguentementeFromDifferentArticle = null;
    // confrontare l'articolo originale con quello dell'attuale conseguentemente
    if (!!conseguentementeActive) {
      // prendere prime due parole
      const originalArticle = conseguentementeActive.section
        .split(" ")
        .slice(0, 2)
        .join(" ");
      const actualArticle = paragraphPath.split(" ").slice(0, 2).join(" ");

      if (originalArticle !== actualArticle) {
        conseguentementeFromDifferentArticle = true;
      }
    }

    if (editorAction === "add") {
      const additionEmendament = getAddedSection(
        sectionType,
        paragraphPath,
        editedData,
        parlamentSection,
        conseguentementeFromDifferentArticle
      );
      setEmendaments([...additionEmendament]);
      setIsEdited(true);

      // aggiornare sezioni aggiunte
      // dispatch(
      //   addAddedSection({
      //     section: paragraphPath.split("dopo ").pop(),
      //     text: editedData,
      //   })
      // );
    } else if (editorAction === "delete") {
      const deletedEmendament = getDeletedSection(
        sectionType,
        paragraphPath,
        parlamentSection,
        conseguentementeFromDifferentArticle
      );
      setEmendaments([...deletedEmendament]);
      setIsEdited(true);
      // aggiornare sezioni rimosse
      // dispatch(
      //   addRemovedSection({
      //     section: paragraphPath.split("Sopprimi l'").pop(),
      //     text: "",
      //   })
      // );
    } else if (editorAction === "premise") {
      const premiseEmendament = getPremiseSection(
        sectionType,
        paragraphPath,
        editedData,
        parlamentSection,
        conseguentementeFromDifferentArticle
      );
      setEmendaments([...premiseEmendament]);
      setIsEdited(true);
      // aggiornare sezioni premesse
      // dispatch(
      //   addPremiseSection({
      //     section: paragraphPath.split("all' ").pop(),
      //     text: editedData,
      //   })
      // );
    } else if (editorAction === "replace") {
      const replaceEmendament = getReplacedSection(
        sectionType,
        paragraphPath,
        editedData,
        parlamentSection,
        conseguentementeFromDifferentArticle
      );
      // const cleanEditedData = editedData.replace(/<[^>]*>?/gm, "");
      setEmendaments([...replaceEmendament]);
      setIsEdited(true);
      // aggiornare sezioni sostituite
      // dispatch(
      //   addChangedSection({
      //     section: paragraphPath.split("Sostituisci ").pop(),
      //     text: cleanEditedData,
      //   })
      // );
    } else {
      if (editedData !== originalData) {
        // pulire tags html e &nbsp; all'inizio e alla fine
        const cleanOriginalData = originalData
          .replace(/<[^>]*>?/gm, "")
          .replace(/&nbsp;/g, "")
          .trim();
        const cleanEditedData = editedData
          .replace(/<[^>]*>?/gm, "")
          .replace(/&nbsp;/g, "");

        /*         console.log("cleanOriginalData", cleanOriginalData);
        console.log("cleanEditedData", cleanEditedData); */

        const dmp = new DiffMatchPatch();

        const diff = dmp.diff_main(cleanOriginalData, cleanEditedData);
        dmp.diff_cleanupSemantic(diff);

        const changedPhrases = getChangedPhrases(
          paragraphPath,
          diff,
          parlamentSection,
          conseguentementeFromDifferentArticle
        );

        setEmendaments([...changedPhrases]);
        setSectionChanged(paragraphPath);
        // aggiornare sezioni modificate
        // dispatch(
        //   addChangedSection({
        //     section: paragraphPath,
        //     text: cleanEditedData,
        //   })
        // );
        // sostituiamo all'interno dell xmlData il testo originale con quello modificato
        const updatedXmlData = updateXmlData(
          xmlData,
          originalData,
          cleanEditedData
        );

        setXmlData(updatedXmlData);
        setIsEdited(true);
      } else {
        console.log("Nessuna modifica apportata al testo.");
      }
    }
  };

  const handleConfirmEmendaments = async (emendaments, conseguentemente) => {
    /*  dispatch(saveEmendament(emendaments[0])); */

    await emendaments.forEach((emendament) => {
      emendament.emendament.forEach((emendamentText) => {
        dispatch(
          saveEmendament({
            section: emendament.section,
            emendament: emendamentText,
            pdlId: ddlID?.toString(),
            userId: !!profile ? profile.sub : "99",
          })
        );
      });
    });

    // get emendament just saved
    const savedEmendament = emendaments[emendaments.length - 1];

    // check if conseguentemente is active

    if (!!conseguentemente) {
      dispatch(setConseguentementeActive(savedEmendament));
    } else {
      dispatch(setConseguentementeActive(false));
    }
  };

  const handleConfirmConseguentemente = async (
    originalEmendament,
    emendamentsToAdd
  ) => {
    // get database id of original emendament
    let originalReduxEmendament = null;
    if (!!originalEmendament.id) {
      console.log("originalEmendament.id", originalEmendament.id);
      originalReduxEmendament = reduxEmendaments.find(
        (emendament) => emendament.id === originalEmendament.id
      );
    } else {
      originalReduxEmendament = reduxEmendaments.find(
        (emendament) =>
          emendament.section === originalEmendament.section &&
          emendament.emendament ===
            (originalEmendament.emendament[0] || originalEmendament.emendament)
      );
    }

    // check se original emendament ha già Conseguentemente,
    const alreadyConseguentemente =
      originalEmendament.emendament[0].includes("Conseguentemente,");
    // se già presente, rimuovere il conseguentemente da emendamentToAdd
    if (alreadyConseguentemente) {
      emendamentsToAdd[0].emendament[0] =
        emendamentsToAdd[0].emendament[0].replace("Conseguentemente,", "");
    }
    const emendamentsToAddText = emendamentsToAdd[0].emendament[0].replace(
      /<(?!\/?i(?:\s+[^>]*)?>)[^>]*>/gm,
      ""
    );

    dispatch(
      updateEmendament({
        editingEmendamentId: originalReduxEmendament.id,
        emendamentData: {
          section: originalEmendament.section,
          emendament:
            originalEmendament.emendament[0] +
            `<b>  Conseguentemente,<b> ` +
            `<p>${emendamentsToAddText}</p>`,
        },
      })
    );
    // setconseguentemente updatred version of emendaments

    dispatch(
      setConseguentementeActive({
        section: originalEmendament.section,
        emendament: [
          originalEmendament.emendament[0] +
            `<b>  Conseguentemente,<b> ` +
            `<p>${emendamentsToAddText}</p>`,
        ],
        id: originalReduxEmendament.id,
      })
    );
  };

  const handleUndo = (emendament) => {
    dispatch(
      removeRemovedSection({
        section: emendament.section.split("Sopprimi l'").pop(),
        text: emendament.text,
      })
    );
    dispatch(
      removePremiseSection({
        section: emendament.section.split("all' ").pop(),
        text: emendament.text,
      })
    );
    dispatch(
      removeAddedSection({
        section: emendament.section.split("dopo ").pop(),
        text: emendament.text,
      })
    );
    dispatch(
      removeChangedSection({
        section: emendament.section.split("Sostituisci ").pop(),
        text: emendament.text,
      })
    );
    dispatch(
      removeChangedSection({
        section: emendament.section,
        text: emendament.text,
      })
    );
  };

  const handleUndoClick = (emendament) => {
    handleUndo(emendament);
    setIsEditorPopupOpen(false);
    setIsEditing(false);
    setIsEdited(false);
  };

  return (
    <>
      {!!isRedoPopupOpen ? (
        <div className="flex-column justify-center align-center">
          <h2>SICURO DI VOLER ANNULLARE L'OPERAZIONE?</h2>
          <p>Tutte le modifiche andranno perse.</p>
          <div className="flex-row align-center justify-between w-100 mt-2">
            <Button handleclick={() => setIsRedoPopupOpen(false)}>
              Annulla
            </Button>
            <Button
              backgroundColor={"var(--danger)"}
              handleclick={() => {
                handleUndoClick(emendaments[emendaments.length - 1]);
              }}
            >
              Conferma
            </Button>
          </div>
        </div>
      ) : (
        <>
          {!isEdited ? (
            <div className={isEditing ? "editor-enabled" : "editor-disabled"}>
              {!!isEditing && <RecIcon />}
              <h3
                className="mb-1 mt-1"
                style={{
                  textTransform: "uppercase",
                  fontWeight: 600,
                }}
              >
                {conseguentementeActive
                  ? "Conseguentemente: " + paragraphPath
                  : paragraphPath}
              </h3>
              <CKEditor
                disabled={!isEditing}
                editor={ClassicEditor}
                data={editedData || editorData}
                config={{
                  toolbar: [],
                }}
                onReady={(editor) => {
                  handleDeleteRequest(editor);
                }}
                onChange={handleEditorChange}
              />
              {/* TASTO REC PER INIZIARE A SCRIVERE */}
              <div className="flex-row justify-between">
                <Button
                  backgroundColor="var(--danger)"
                  style={{
                    marginTop: "20px",
                  }}
                  handleclick={() => {
                    setIsEditorPopupOpen(false);
                    setIsEditing(false);
                  }}
                >
                  <span>Annulla</span>
                </Button>

                {!!isEditing ? (
                  <Button
                    disabled={
                      editorAction === "edit"
                        ? editedData === originalData
                        : editedData === ""
                    }
                    style={{
                      marginTop: "20px",
                    }}
                    handleclick={() => handleSave(conseguentementeActive)}
                  >
                    <span>Salva</span>
                  </Button>
                ) : (
                  <Button
                    style={{
                      marginTop: "20px",
                    }}
                    handleclick={() => {
                      setIsEditing(true);
                    }}
                  >
                    <span>Modifica</span>
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <>
              <h2 className="mb-1">Anteprima emendamento</h2>
              <p
                className="mb-1"
                style={{
                  overflow: "auto",
                  maxHeight: "65vh",
                }}
              >
                {emendaments[0].emendament?.map((emendament, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        paddingTop: index > 0 && "10px",
                      }}
                    >
                      {index > 0 && (
                        <div
                          style={{
                            paddingTop: "10px",
                            borderTop: "1px solid #ccc",
                          }}
                        />
                      )}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: emendament,
                        }}
                      />
                    </div>
                  );
                })}
              </p>
              <div className="flex-row justify-between full-width mt-1">
                <Button
                  handleclick={() => {
                    setIsRedoPopupOpen(true);
                  }}
                  backgroundColor="var(--danger)"
                >
                  Annulla
                </Button>

                {editorAction !== "delete" && (
                  <Button
                    handleclick={() => {
                      setIsEdited(false);
                    }}
                  >
                    Modifica
                  </Button>
                )}

                {conseguentementeActive ? (
                  <Button
                    width="200px"
                    maxWidth="200px"
                    handleclick={() => {
                      setIsEditorPopupOpen(false);
                      setIsEditing(false);
                      setIsEdited(false);

                      handleConfirmConseguentemente(
                        conseguentementeActive,
                        emendaments
                      );
                    }}
                  >
                    Conseguentemente
                  </Button>
                ) : (
                  <Button
                    width="200px"
                    maxWidth="200px"
                    handleclick={() => {
                      setIsEditorPopupOpen(false);
                      setIsEditing(false);
                      setIsEdited(false);
                      handleConfirmEmendaments(emendaments, "conseguentemente");
                    }}
                  >
                    Conseguentemente
                  </Button>
                )}
                {conseguentementeActive ? (
                  <Button
                    handleclick={() => {
                      setIsEditorPopupOpen(false);
                      setIsEditing(false);
                      setIsEdited(false);
                      handleConfirmConseguentemente(
                        conseguentementeActive,
                        emendaments
                      );
                      dispatch(setConseguentementeActive(false));
                    }}
                  >
                    Conferma
                  </Button>
                ) : (
                  <Button
                    handleclick={() => {
                      setIsEditorPopupOpen(false);
                      setIsEditing(false);
                      setIsEdited(false);
                      handleConfirmEmendaments(emendaments);
                    }}
                  >
                    Conferma
                  </Button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Editor;
