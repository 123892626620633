import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";

export default function DiffViewer({ oldText, newText }) {
  return (
    <div>
      <div className="flex-row mb-05">
        <div
          style={{
            width: "50%",
          }}
        >
          <h4>Originale</h4>
        </div>
        <div
          style={{
            width: "50%",
          }}
        >
          <h4>Modificato</h4>
        </div>
      </div>
      <ReactDiffViewer
        styles={{
          diffContainer: {
            overflowY: "auto",
            width: "100%",
          },
          diffRemoved: {
            background: "#fff",
            color: "#b31d28",
            border: "1px solid #b31d2870",
            padding: " 5px",
          },
          diffAdded: {
            background: "#fff",
            color: "#389e0d",
            border: "1px solid #389e0d70",
            padding: " 5px",
          },
          gutter: {
            display: "none",
          },
          marker: {
            display: "none",
          },
        }}
        oldValue={oldText}
        newValue={newText}
        compareMethod={DiffMethod.WORDS}
      />
    </div>
  );
}
