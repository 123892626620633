import ContextMenuArticle from "../../../components/context-menu/ContextMenuArticle";
import ContextMenuComma from "../../../components/context-menu/ContextMenuComma";
import ContextMenuLettera from "../../../components/context-menu/ContextMenuLetter";
import ContextMenuNumero from "../../../components/context-menu/ContextMenuNumber";
import ContextMenuRubrica from "../../../components/context-menu/ContextMenuRubrica";
import {
  cleanParagraphPath,
  convertForClassNames,
  extractIntroductionValues,
  formatKey,
} from "../../../helpers/formatStringFuncs";

export const renderXmlNode = (
  node,
  depth = 0,
  parentKey = "",
  path = "",
  isNovella,
  isTagsVisibile,
  setParagraphPath,
  setIsEditorPopupOpen,
  setEditorData,
  setIsEditing,
  setSectionType,
  setEditorAction
) => {
  const nodes = Object.entries(node).map(([key, value]) => {
    // livello generale del documento

    let novella = isNovella;

    if (typeof value === "object") {
      /////////////////////////////// LIVELLO 1 ///////////////////////////////////////
      if (parentKey === "dl:Presentatore") {
        return null;
      }
      if (key === "dl:RelPres" || parentKey === "dl:RelPres") {
        return (value = "");
      }
      if (parentKey === "a:Novella") {
        // TODO: MODIFICHE DI ALTRE LEGGI COME GESTIRLE?
        key = "Novella";
        novella = true;

        /*  console.log("novella", [].concat(...Object.values(value)));
          value = [].concat(...Object.values(value)).map((item) => {
            return item["a:Corpo"] ? item["a:Corpo"]["h:p"] : item["h:p"];
          }); */
      }
      // LOGICA IN CASO DI SINGOLO ARTICOLO

      if (!!value["a:Articolo"]) {
        if (!Array.isArray(value["a:Articolo"]) && !!value["a:Articolo"]) {
          // return an array with the Articolo
          const copyValue = { ...value };
          const Articolo = value["a:Articolo"];
          delete copyValue["a:Articolo"];

          value = {
            ...copyValue,
            "a:Articolo": [Articolo],
          };
        }
      }

      if (parentKey === "a:Articolo") {
        // LOGICA IN CASO DI SINGOLO COMMA
        if (!!value["a:Comma"]) {
          // check if comma is an array
          if (!Array.isArray(value["a:Comma"]) && !!value["a:Comma"]) {
            // return an array with the comma
            const copyValue = { ...value };
            const comma = value["a:Comma"];
            delete copyValue["a:Comma"];

            value = {
              ...copyValue,
              "a:Comma": [comma],
            };
          }
        }
        if (!!value["a:Num"]) {
          const copyValue = { ...value };
          delete copyValue["a:Num"];
          const articleKey = "Articolo " + value["a:Num"]["@_numero"];
          key = articleKey;
          // if (key === "Articolo 1" && !value["a:Rubrica"]) {
          //   // handler introduzione
          //   key = "";
          //   value = extractIntroductionValues(copyValue);
          //   /*        value = ""; */
          // } else {
          //   key = articleKey;
          //   value = copyValue;
          // }
        } else if (!!value["@_numero"]) {
          const copyValue = { ...value };
          delete copyValue["@_numero"];
          const articleKey = "Articolo " + value["@_numero"];
          key = articleKey;
          value = copyValue;
        } else {
          const copyValue = value;
          key = null;
          value = copyValue;
        }
      }

      if (parentKey === "a:Comma") {
        if (!!value["a:Num"]) {
          const copyValue = { ...value };
          delete copyValue["a:Num"];
          const commaKey = "Comma " + value["a:Num"]["@_numero"];
          key = commaKey;
          value = copyValue;
        } else if (!!value["@_numero"]) {
          // comma senza numero, sottocommi?? TODO
          const copyValue = { ...value };
          delete copyValue["@_numero"];
          const commaKey = "Comma " + value["@_numero"];
          key = commaKey;
          value = copyValue;
        } else {
          key = "";
        }
      }
      if (parentKey === "a:Lettera") {
        if (!!value["a:Num"]) {
          const copyValue = { ...value };
          delete copyValue["a:Num"];
          const letteraKey = "Lettera " + value["a:Num"]["@_numero"];
          key = letteraKey;
          value = copyValue;
        } else {
          key = "";
        }
      }
      if (parentKey === "a:Numero") {
        if (!!value["a:Num"]) {
          const copyValue = { ...value };
          delete copyValue["a:Num"];
          const numeroKey = "Numero " + value["a:Num"]["@_numero"];
          key = numeroKey;
          value = copyValue;
        } else {
          key = "";
        }
      }

      const newPath = path === "" ? key : `${path} ${key}`;
      // const isSectionChanged = sectionChangedList.find(
      //   (emendament) => emendament.section === cleanParagraphPath(newPath)
      // );
      // const isSectionRemoved = sectionsRemovedList.find(
      //   (section) => section.section === cleanParagraphPath(newPath)
      // );
      // const isSectionPremised = sectionPremisedList.find(
      //   (section) => section.section === cleanParagraphPath(newPath)
      // );
      // const isSectionAdded = sectionAddedList.find(
      //   (section) => section.section === cleanParagraphPath(newPath)
      // );

      return (
        <>
          {/* {!!isSectionPremised && (
              <div className="premise-section">
                <Tag backgroundColor={"#32CD32"}>Premessa</Tag>
                <p>
                  <span
                    className="premise-section-path"
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {getPremisedSectionTitle(isSectionPremised.section)}
                  </span>
                </p>
                <p
                  dangerouslySetInnerHTML={{ __html: isSectionPremised.text }}
                />
              </div>
            )} */}
          <div
            section={cleanParagraphPath(newPath)}
            key={key + depth + Math.random() * 1000000}
            // className={
            //   !!isSectionChanged
            //     ? " changed"
            //     :
            //      !!isSectionRemoved && "removed"
            // }
          >
            {
              // key onlu if not beigin with a: or @_
            }
            {key &&
              !key.startsWith("a:") &&
              !key.startsWith("@_") &&
              !key.startsWith("h:") &&
              !key.startsWith("dl:") &&
              !key.startsWith("Novella") && (
                <div
                  className={key}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {formatKey(key)}{" "}
                </div>
              )}
            <div
              className={`${!!isTagsVisibile && convertForClassNames(key)} `}
            >
              {!!isTagsVisibile && (
                <>
                  {!isNovella && key && key.startsWith("Articolo") && (
                    <div id={cleanParagraphPath(newPath)}>
                      <ContextMenuArticle
                        setParagraphPath={setParagraphPath}
                        setIsEditorPopupOpen={setIsEditorPopupOpen}
                        setEditorData={setEditorData}
                        setIsEditing={setIsEditing}
                        setEditorAction={setEditorAction}
                        setSectionType={setSectionType}
                        newPath={newPath}
                        id={key + depth + Math.random() * 1000000}
                        value={value}
                      />
                    </div>
                  )}
                  {!isNovella && key && key.startsWith("Comma") && (
                    <div id={cleanParagraphPath(newPath)}>
                      <ContextMenuComma
                        setParagraphPath={setParagraphPath}
                        setIsEditorPopupOpen={setIsEditorPopupOpen}
                        setEditorData={setEditorData}
                        setIsEditing={setIsEditing}
                        setEditorAction={setEditorAction}
                        setSectionType={setSectionType}
                        newPath={newPath}
                        id={key + depth + Math.random() * 1000000}
                        value={value}
                      />
                    </div>
                  )}
                  {!isNovella && key && key.startsWith("Lettera") && (
                    <div id={cleanParagraphPath(newPath)}>
                      <ContextMenuLettera
                        setParagraphPath={setParagraphPath}
                        setIsEditorPopupOpen={setIsEditorPopupOpen}
                        setEditorData={setEditorData}
                        setIsEditing={setIsEditing}
                        setEditorAction={setEditorAction}
                        setSectionType={setSectionType}
                        newPath={newPath}
                        id={key + depth + Math.random() * 1000000}
                        value={value}
                      />
                    </div>
                  )}
                  {!isNovella && key && key.startsWith("Numero") && (
                    <div id={cleanParagraphPath(newPath)}>
                      <ContextMenuNumero
                        setParagraphPath={setParagraphPath}
                        setIsEditorPopupOpen={setIsEditorPopupOpen}
                        setEditorData={setEditorData}
                        setIsEditing={setIsEditing}
                        setEditorAction={setEditorAction}
                        setSectionType={setSectionType}
                        newPath={newPath}
                        id={key + depth + Math.random() * 1000000}
                        value={value}
                      />
                    </div>
                  )}
                </>
              )}
              {/* <Tag backgroundColor={"var(--" + key + ")"}>{key}</Tag> */}
              {renderXmlNode(
                value,
                depth + 1,
                key,
                newPath,
                novella,
                isTagsVisibile,
                setParagraphPath,
                setIsEditorPopupOpen,
                setEditorData,
                setIsEditing,
                setSectionType,
                setEditorAction
              )}
            </div>
          </div>
          {/* {!!isSectionAdded && (
              <div className="premise-section">
                <Tag backgroundColor={"#32CD32"}>Aggiunta</Tag>
                <p>
                  <span className="premise-section-path">
                    {getAddedSectionTitle(isSectionAdded.section)}
                  </span>
                </p>
                <p dangerouslySetInnerHTML={{ __html: isSectionAdded.text }} />
              </div>
            )} */}
        </>
      );
    } else {
      /////////////////////////////// ULTIMO LIVELLO ///////////////////////////////////////

      // PULISCI VALORI VUOTI
      if (
        value === "" ||
        value === null ||
        value === undefined ||
        value === " " ||
        value === 0
      ) {
        return null;
      }

      // PULISCI CHIAVI CHE NON SERVONO
      if (key === "dl:TipoAtto") {
        return (
          <div className="flex-row justify-center">
            <h2>{value}</h2>
          </div>
        );
      }
      if (key === "@_ramo" || key === "dl:Titolo") {
        return (
          <div
            className="flex-row justify-center"
            style={{
              borderBottom: "1px solid",
              paddingBottom: "10px",
            }}
          >
            <h3>{value}</h3>
          </div>
        );
      }
      if (key.startsWith("@_") || key.startsWith("#text")) {
        return null;
      }

      if (key === "a:Rubrica") {
        // const isRubricaChanged = sectionChangedList.find(
        //   (emendament) =>
        //     emendament.section === cleanParagraphPath(path) + " Rubrica"
        // );

        return (
          <div
            key={key + depth + Math.random() * 1000000}
            style={{
              cursor: "pointer",
            }}
            className={`${!!isTagsVisibile && convertForClassNames(key)}   `}
            //  ${!!isRubricaChanged && " changed"}

            onClick={() => {
              setIsEditorPopupOpen(true);
              setEditorAction("edit");
              setEditorData(value);
              setParagraphPath(path + " Rubrica");
            }}
          >
            {!isNovella && !!isTagsVisibile && (
              <ContextMenuRubrica
                setParagraphPath={setParagraphPath}
                setIsEditorPopupOpen={setIsEditorPopupOpen}
                setEditorData={setEditorData}
                setIsEditing={setIsEditing}
                setEditorAction={setEditorAction}
                setSectionType={setSectionType}
                newPath={path}
                id={key + depth + Math.random() * 1000000}
                value={value}
              />
            )}
            {/*    <span className="Rubrica">Rubrica</span> */}
            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <i>{value}</i>
              {/* <div
                  className="vedi-modifica-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDiffViewerPopupOpen(true);
                    setDiffViewerOldText(value);
                    setParagraphPath(path);
                    handleSetNewText(path + " Rubrica");
                  }}
                >
                  Vedi modifiche
                </div> */}
            </div>
          </div>
        );
      }

      // check if key is a number
      if (!isNaN(key)) {
        key = null;
      }

      return (
        <>
          <div
            // SERVE???
            /*  section={cleanParagraphPath(path)} */
            key={key + depth + Math.random() * 1000000}
            className="paragrafo"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setIsEditorPopupOpen(true);
              setEditorAction("edit");
              setEditorData(value);
              setParagraphPath(path);
            }}
          >
            {key &&
              !key.startsWith("a:") &&
              !key.startsWith("@_") &&
              !key.startsWith("h:") && <span>{key}</span>}
            <span> </span>
            <span>{value}</span>
          </div>
          {/* <div
              className="vedi-modifica-btn"
              onClick={() => {
                setIsDiffViewerPopupOpen(true);
                setDiffViewerOldText(value);
                setParagraphPath(path);
                handleSetNewText(path);
              }}
            >
              Vedi modifiche
            </div> */}
        </>
      );
    }
  });

  return nodes;
};
