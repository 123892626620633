// import { combineReducers } from "redux";

import { configureStore } from "@reduxjs/toolkit";

import accountReducer from "./features/accountSlice";
import emendamentsReducer from "./features/emendamentsSlice";
import ddlReducer from "./features/ddlSlice";
import layoutReducer from "./features/layoutSlice";
import firmatariReducer from "./features/firmatariSlice";
// export default combineReducers({
//   account,
// });

export const store = configureStore({
  reducer: {
    account: accountReducer,
    emendaments: emendamentsReducer,
    ddl: ddlReducer,
    layout: layoutReducer,
    firmatari: firmatariReducer,
  },
  // devTools: true,
  // devTools: {
  //   name: "Redux DevTools",
  //   realtime: true,
  //   trace: true,
  //   traceLimit: 25,
  //   actionSanitizer: (action) => {
  //     return {
  //       ...action,
  //       payload: action.payload.toString().substring(0, 100),
  //     };
  //   },
  // },
});
