import React from "react";
import styled from "styled-components";

const getPositionStyles = (position) => {
  switch (position) {
    case "left":
      return `
        right: 100%;
        top: 130%;
        transform: translateY(-50%);
      `;
    case "right":
      return `
        left: 100%;
        top: 130%;
        transform: translateY(-50%);
      `;
    // Puoi aggiungere altre posizioni come "top" o "bottom" se necessario
    default:
      return `
        left: 100%;
        top: 130%;
        transform: translateY(-50%);
      `;
  }
};

const CustomTooltip = styled.div`
  display: none;
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  ${({ position }) => getPositionStyles(position)}
`;

const TooltipContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;

  &:hover > ${CustomTooltip} {
    display: block;
    opacity: 1;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: white;
  color: var(--primary);
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 10px;
  margin-right: 10px;
`;

const Avatar = ({ profile, marginLeft, marginRight }) => {
  const tooltipText = `${profile?.family_name?.toUpperCase()} ${profile?.given_name?.toUpperCase()}`;
  return (
    <TooltipContainer>
      <AvatarContainer style={{ marginLeft, marginRight }}>
        {profile?.given_name?.charAt(0).toUpperCase() +
          profile?.family_name?.charAt(0).toUpperCase()}
      </AvatarContainer>
      <CustomTooltip position={"left"}>{tooltipText}</CustomTooltip>
    </TooltipContainer>
  );
};

export default Avatar;
