import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

const initialState = {
  isTagsVisible: true,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleTagsVisibility: (state) => {
      state.isTagsVisible = !state.isTagsVisible;
    },
  },
  // extraReducers(builder) {

  // },
});

export const { toggleTagsVisibility } = layoutSlice.actions;
export const { actions } = layoutSlice;
export default layoutSlice.reducer;
