import ContextMenuArticle from "../../../components/context-menu/ContextMenuArticle";
import ContextMenuComma from "../../../components/context-menu/ContextMenuComma";
import ContextMenuLettera from "../../../components/context-menu/ContextMenuLetter";
import ContextMenuNumero from "../../../components/context-menu/ContextMenuNumber";
import ContextMenuRubrica from "../../../components/context-menu/ContextMenuRubrica";
import parse from "html-react-parser";
import { parseDOM } from "htmlparser2";
import * as domutils from "domutils";
import { convertForClassNames } from "../../../helpers/formatStringFuncs";

const SECTION_COMPONENTS = {
  Article: ContextMenuArticle,
  Comma: ContextMenuComma,
  Lettera: ContextMenuLettera,
  Numero: ContextMenuNumero,
  Rubrica: ContextMenuRubrica,
};

const ContextMenu = ({
  type,
  path,
  setParagraphPath,
  setIsEditorPopupOpen,
  setEditorData,
  setIsEditing,
  setEditorAction,
  setSectionType,
}) => {
  // Ottieni il componente corretto dal mapping
  const SpecificSection = SECTION_COMPONENTS[type];

  // Controlla se il componente esiste
  if (!SpecificSection) {
    console.warn(`No component found for type "${type}"`);
    return null;
  }

  // Renderizza il componente
  return (
    <div id={path}>
      <SpecificSection
        setParagraphPath={setParagraphPath}
        setIsEditorPopupOpen={setIsEditorPopupOpen}
        setEditorData={setEditorData}
        setIsEditing={setIsEditing}
        setEditorAction={setEditorAction}
        setSectionType={setSectionType}
        newPath={path}
        id={path}
        // value={value}
      />
    </div>
  );
};

const handleSaltoPagina = (node) => {
  if (node.name === "span" && node.attribs.class === "saltoPagina") {
    const parent = domutils.getParent(node);
    if (!parent) return;

    const index = parent.children.indexOf(node);

    // Memorizza i figli dello span in una variabile
    const childrenToMove = [...node.children];

    // Rimuovi lo span
    domutils.removeElement(node);

    // Inserisci ogni figlio al posto giusto nel parent
    childrenToMove.forEach((child, idx) => {
      parent.children.splice(index + idx, 0, child);
    });

    // Dopo aver spostato i figli, chiamiamo ricorsivamente handleSaltoPagina su ogni figlio
    childrenToMove.forEach((child) => handleSaltoPagina(child));
  } else {
    // Se non è un nodo "saltoPagina", chiamiamo comunque ricorsivamente su ogni figlio
    node.children && node.children.forEach((child) => handleSaltoPagina(child));
  }
  // Se il nodo è un tag <i>
  if (node.name === "i") {
    const parent = domutils.getParent(node);
    if (!parent) return;

    const index = parent.children.indexOf(node);

    // Memorizza i figli del tag <i> in una variabile
    const childrenToMove = [...node.children];

    // Rimuovi il tag <i>
    domutils.removeElement(node);

    // Inserisci ogni figlio al posto giusto nel parent
    childrenToMove.forEach((child, idx) => {
      parent.children.splice(index + idx, 0, child);
    });

    // Dopo aver spostato i figli, chiamiamo ricorsivamente handleSaltoPagina su ogni figlio
    childrenToMove.forEach((child) => handleSaltoPagina(child));
  } else {
    // Se non è un nodo "saltoPagina" o un nodo <i>,
    // chiamiamo comunque ricorsivamente su ogni figlio
    node.children && node.children.forEach((child) => handleSaltoPagina(child));
  }
  if (
    node.name === "span" &&
    node.attribs.id &&
    node.attribs.id.startsWith("pag.")
  ) {
    /*   console.log("node", node); */
  } else {
    // Se non è un nodo "saltoPagina", un nodo <i>, o un nodo <span> con id "pag.",
    // chiamiamo comunque ricorsivamente su ogni figlio
    node.children && node.children.forEach((child) => handleSaltoPagina(child));
  }
};

const processNode = (node, isTagsVisibile) => {
  if (!node.name || !node.attribs) return;

  handleSaltoPagina(node);

  if (node.name === "p" && /  (\d+)[.]/.test(node.children[0]?.data)) {
    node.children.map((child) => {
      if (child.type === "text" && /  (\d+)[.]/.test(child.data)) {
        child.attribs = child.attribs || {}; // Ensure the child has the 'attribs' object
        child.attribs.class =
          (child.attribs.class ? child.attribs.class + " " : "") +
          "numberPrefix";
      }
    });
  }

  if (node.name === "div" && node.attribs.id === "telecomando") {
    domutils.removeElement(node);
    return;
  }

  if (node.name === "div" && node.attribs.class === "articolo") {
    const placeholderHtml = `<span class="articleComponentPlaceholder" id=${node.attribs.id}></span>`;
    const placeholderNode = parseDOM(placeholderHtml);
    node.attribs.class = !!isTagsVisibile
      ? "articolo html-articolo "
      : "" + convertForClassNames(node.attribs.id);
    domutils.appendChild(node, placeholderNode[0]);
  }

  // Processa ricorsivamente tutti i figli di questo nodo
  node.children &&
    node.children.forEach((child) => processNode(child, isTagsVisibile));
};

const traverseAndProcess = (node, isTagsVisibile) => {
  processNode(node, isTagsVisibile);

  if (node.children) {
    node.children.forEach((child) => traverseAndProcess(child, isTagsVisibile));
  }
};

export const renderXhtmlNode = (
  html,
  setIsEditorPopupOpen,
  setEditorData,
  setEditorAction,
  isTagsVisibile,
  setParagraphPath,
  setIsEditing,
  setSectionType
) => {
  //replace <em> and </em> with <i> and </i>
  html = html.replace(/<em/g, "<i");
  html = html.replace(/«[^»]*»/g, (match) => {
    return match.replace(/<[^>]*>/g, " ");
  });

  html = html.replace(/<\/p>\s*<p>\s{2,}«/g, " «");
  html = html.replace(/<\/em/g, "</i");

  // strip all the tags between « and » but not the letters

  let dom = parseDOM(html);

  // Processa ogni nodo una sola volta
  dom.forEach((domNode) => traverseAndProcess(domNode, isTagsVisibile));

  const updatedHtml = domutils.getOuterHTML(dom);

  /*   console.log("updatedHtml", updatedHtml); */

  // Funzione replace per html-react-parser
  const replaceFunction = (domNode) => {
    if (
      (domNode.attribs && domNode.attribs.id === "RL") ||
      (domNode.attribs && domNode.attribs.id === "DC")
    ) {
      return <div></div>;
    }
    if (
      domNode.prev?.type === "tag" &&
      domNode.parent?.attribs?.align === "center"
    ) {
      // get articolo di riferimento
      const articleNumber = domNode.parent.parent.attribs.id.split(".")[1];

      return (
        <div
          onClick={() => {
            setIsEditorPopupOpen(true);
            setEditorAction("edit");
            setEditorData(domNode.data);
            setParagraphPath("Articolo " + articleNumber + " Rubrica");
          }}
          className={!!isTagsVisibile && "a-Rubrica"}
          style={{
            cursor: "pointer",
          }}
        >
          {!!isTagsVisibile && (
            <ContextMenu
              type="Rubrica"
              path={"Articolo " + articleNumber}
              setParagraphPath={setParagraphPath}
              setIsEditorPopupOpen={setIsEditorPopupOpen}
              setEditorData={setEditorData}
              setIsEditing={setIsEditing}
              setEditorAction={setEditorAction}
              setSectionType={setSectionType}
            />
          )}
          <div
            style={{
              marginTop: "0.8rem",
            }}
          >
            <i>{domNode.data}</i>
          </div>
        </div>
      );
    } else if (
      domNode.name &&
      domNode.attribs &&
      domNode.attribs.class === "articleComponentPlaceholder"
    ) {
      let key = domNode.attribs.id.split(".")[1];
      key = "Articolo " + key;
      return !!isTagsVisibile ? (
        <ContextMenu
          setParagraphPath={setParagraphPath}
          setIsEditorPopupOpen={setIsEditorPopupOpen}
          setEditorData={setEditorData}
          setIsEditing={setIsEditing}
          setEditorAction={setEditorAction}
          setSectionType={setSectionType}
          type="Article"
          path={key}
        />
      ) : (
        <></>
      );
    } else if (domNode.name === "p") {
      const getText = (node) => {
        if (node.type === "text") {
          return node.data;
        }
        if (node.children) {
          return node.children.map(getText).join("");
        }
        return "";
      };
      const text = getText(domNode);
      const matchesComma = [...text.matchAll(/  (\d+\.)\s(?!\d)/g)];

      if (matchesComma.length > 0) {
        const parentPath = domNode.parent.attribs.id.replace(
          "art.",
          "Articolo "
        );
        // Ottieni tutti i segmenti di testo tra i match
        const segments = [];
        let lastMatchIndex = 0;

        matchesComma.forEach((match, index) => {
          const segment = text.substring(lastMatchIndex, match.index);
          segments.push(segment);
          lastMatchIndex = match.index + match[0].length;
        });

        // Aggiungi l'ultimo segmento dal ultimo match alla fine del testo
        segments.push(text.substring(lastMatchIndex));

        return (
          <div className={!!isTagsVisibile && "a-Comma"}>
            {matchesComma.map((match, index) => {
              const editingData = segments[index + 1].replace(/<br>/g, "\n");
              return (
                <div key={index}>
                  {!!isTagsVisibile && (
                    <ContextMenu
                      setParagraphPath={setParagraphPath}
                      setIsEditorPopupOpen={setIsEditorPopupOpen}
                      setEditorData={setEditorData}
                      setIsEditing={setIsEditing}
                      setEditorAction={setEditorAction}
                      setSectionType={setSectionType}
                      type="Comma"
                      path={parentPath + " Comma " + match[1]}
                    />
                  )}
                  <div
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: 600,
                    }}
                  >
                    {match[1]}
                  </div>
                  <div
                    className="paragrafo"
                    onClick={() => {
                      setIsEditorPopupOpen(true);
                      setEditorAction("edit");
                      setEditorData(editingData);
                      setParagraphPath(parentPath + " Comma " + match[1]);
                    }}
                  >
                    {segments[index + 1]}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
      const matchesLettera = [...text.matchAll(/  ([a-z])[)]/g)];
      if (matchesLettera.length > 0) {
        const parentPath = domNode.parent.attribs.id.replace(
          "art.",
          "Articolo "
        );

        /* const commaNumber = domNode.parent.attribs.id.split(".")[2]; */

        // Ottieni tutti i segmenti di testo tra i match
        const segments = [];
        let lastMatchIndex = 0;

        matchesLettera.forEach((match, index) => {
          const segment = text.substring(lastMatchIndex, match.index);
          segments.push(segment);
          lastMatchIndex = match.index + match[0].length;
        });
        // Aggiungi l'ultimo segmento dal ultimo match alla fine del testo
        segments.push(text.substring(lastMatchIndex));
        return (
          <div className={!!isTagsVisibile && "a-Lettera"}>
            {matchesLettera.map((match, index) => {
              //get the domNode who corrispond to the match

              const domNodeWhoCorrispondToTheMatch = domNode.children.find(
                (child) => {
                  if (child.type === "text") {
                    return child.data === match.input;
                  }
                  if (child.children) {
                    return child.children.map(getText).join("") === match[0];
                  }
                  return "";
                }
              );

              // while che aggiunge un .prev al domNode finchè non trova un tag p il cui children inizia per numero seguito da .
              // e poi prendere il numero e aggiungerlo al parentPath

              let currentNode = domNodeWhoCorrispondToTheMatch?.parent;
              let commaNumber = "";

              while (currentNode?.prev) {
                if (currentNode?.prev.type === "tag") {
                  if (
                    currentNode?.prev.name === "p" &&
                    /  (\d+)[.]/.test(currentNode?.prev.children[0]?.data)
                  ) {
                    commaNumber =
                      currentNode?.prev.children[
                        currentNode?.prev.children.length - 1
                      ]?.data.split(".")[0];
                    break;
                  }
                }
                currentNode = currentNode?.prev;
              }
              commaNumber = commaNumber ? commaNumber.trim() : "1";

              const editingData = segments[index + 1].replace(/<br>/g, "\n");
              return (
                <div key={index}>
                  {!!isTagsVisibile && (
                    <ContextMenu
                      setParagraphPath={setParagraphPath}
                      setIsEditorPopupOpen={setIsEditorPopupOpen}
                      setEditorData={setEditorData}
                      setIsEditing={setIsEditing}
                      setEditorAction={setEditorAction}
                      setSectionType={setSectionType}
                      type="Lettera"
                      path={
                        parentPath +
                        " Comma " +
                        commaNumber +
                        " Lettera " +
                        match[1]
                      }
                    />
                  )}
                  <div
                    style={{
                      fontSize: "1rem",
                      fontWeight: 600,
                    }}
                  >
                    {match[1] + ")"}
                  </div>
                  <div
                    className="paragrafo"
                    onClick={() => {
                      setIsEditorPopupOpen(true);
                      setEditorAction("edit");
                      setEditorData(editingData);
                      setParagraphPath(
                        parentPath +
                          " Comma " +
                          commaNumber +
                          " Lettera " +
                          match[1]
                      );
                    }}
                  >
                    {segments[index + 1]}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }

      const matchesNumero = [...text.matchAll(/  (\d+)[)]/g)];
      if (matchesNumero.length > 0) {
        const parentPath = domNode.parent.attribs.id.replace(
          "art.",
          "Articolo "
        );

        // Ottieni tutti i segmenti di testo tra i match
        const segments = [];
        let lastMatchIndex = 0;

        matchesNumero.forEach((match, index) => {
          const segment = text.substring(lastMatchIndex, match.index);
          segments.push(segment);
          lastMatchIndex = match.index + match[0].length;
        });
        // Aggiungi l'ultimo segmento dal ultimo match alla fine del testo
        segments.push(text.substring(lastMatchIndex));
        return (
          <div className={!!isTagsVisibile && "a-Numero"}>
            {matchesNumero.map((match, index) => {
              const domNodeWhoCorrispondToTheMatch = domNode.children.find(
                (child) => {
                  if (child.type === "text") {
                    return child.data === match.input;
                  }
                  if (child.children) {
                    return child.children.map(getText).join("") === match[0];
                  }
                  return "";
                }
              );

              // while che aggiunge un .prev al domNode finchè non trova un tag p il cui children inizia per numero seguito da .
              // e poi prendere il numero e aggiungerlo al parentPath

              let currentNode = domNodeWhoCorrispondToTheMatch?.parent;
              let commaNumber = "";
              let letteraLetter = "";

              while (currentNode?.prev) {
                if (currentNode?.prev.type === "tag") {
                  if (
                    currentNode?.prev.name === "p" &&
                    /  (\d+)[.]/.test(currentNode?.prev.children[0]?.data)
                  ) {
                    commaNumber =
                      currentNode?.prev.children[
                        currentNode?.prev.children.length - 1
                      ]?.data.split(".")[0];
                    break;
                  }
                }
                currentNode = currentNode?.prev;
              }

              currentNode =
                domNodeWhoCorrispondToTheMatch?.parent?.parent?.children;

              currentNode?.forEach((child) => {
                if (child.type === "tag") {
                  if (
                    child.name === "p" &&
                    /  ([a-z])[)]/.test(child.children[0]?.data)
                  ) {
                    letteraLetter =
                      child.children[child.children.length - 1]?.data.split(
                        ")"
                      )[0];
                  }
                }
              });

              commaNumber = commaNumber ? commaNumber.trim() : "1";

              letteraLetter = letteraLetter?.trim();

              const editingData = segments[index + 1].replace(/<br>/g, "\n");
              return (
                <div key={index}>
                  {!!isTagsVisibile && (
                    <ContextMenu
                      setParagraphPath={setParagraphPath}
                      setIsEditorPopupOpen={setIsEditorPopupOpen}
                      setEditorData={setEditorData}
                      setIsEditing={setIsEditing}
                      setEditorAction={setEditorAction}
                      setSectionType={setSectionType}
                      type="Numero"
                      path={
                        parentPath +
                        " Comma " +
                        commaNumber +
                        " Lettera " +
                        letteraLetter +
                        " Numero " +
                        match[1]
                      }
                    />
                  )}
                  <div
                    style={{
                      fontSize: "1rem",
                      fontWeight: 600,
                    }}
                  >
                    {match[1] + ")"}
                  </div>
                  <div
                    className="paragrafo"
                    onClick={() => {
                      setIsEditorPopupOpen(true);
                      setEditorAction("edit");
                      setEditorData(editingData);
                      setParagraphPath(
                        parentPath +
                          " Comma " +
                          commaNumber +
                          " Lettera " +
                          letteraLetter +
                          " Numero " +
                          match[1]
                      );
                    }}
                  >
                    {segments[index + 1]}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    }
    // Aggiungi ulteriori condizioni per i segnaposto del comma, lettera, numero se necessario
  };

  /*     console.log("updatedHtml", updatedHtml); */
  // Parse the updated HTML to React components using html-react-parser
  const finalHtml = parse(updatedHtml, { replace: replaceFunction });
  //   dispatch(setDdlCameraFormattedText(finalHtml));
  return finalHtml;
};
