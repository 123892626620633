import React from "react";
import { cleanParagraphPath } from "../../helpers/formatStringFuncs";
import ContextMenu from "./ContextMenu";
import Tag from "../tag/Tag";
import MoreIcon from "../../shared/MoreIcon";

const ContextMenuComma = ({
  setParagraphPath,
  setIsEditorPopupOpen,
  id,
  newPath,
  setIsEditing,
  setEditorData,
  setEditorAction,
  setSectionType,
}) => {
  return (
    <ContextMenu
      items={[
        {
          label: "Aggiungi comma",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath(
              "Aggiungi comma dopo " + cleanParagraphPath(newPath)
            );
            setIsEditorPopupOpen(true);
            setIsEditing(true);
            setEditorAction("add");
            setSectionType("comma");
          },
        },
        {
          label: "Sopprimi comma",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath("Sopprimi l'" + cleanParagraphPath(newPath));
            setEditorAction("delete");
            setSectionType("comma");
            setIsEditorPopupOpen(true);
          },
          setEditorAction: "delete",
          setSectionType: "comma",
        },
        {
          label: "Premetti comma",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath(
              "Premetti comma all' " + cleanParagraphPath(newPath)
            );
            setIsEditorPopupOpen(true);
            setIsEditing(true);
            setEditorAction("premise");
            setSectionType("comma");
          },
        },
        {
          label: "Sostituisci comma",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath("Sostituisci " + cleanParagraphPath(newPath));
            setIsEditorPopupOpen(true);
            setIsEditing(true);
            setEditorAction("replace");
            setSectionType("comma");
          },
        },
      ]}
      backgroundColor={"var(--comma)"}
      id={`comma_${id}`}
    >
      <Tag backgroundColor={"var(--comma)"}>Comma</Tag>
      <MoreIcon color="var(--comma)" />
    </ContextMenu>
  );
};

export default ContextMenuComma;
