import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../server/api";

const initialState = {
  loading: false,
  error: false,
  data: null,
  profile: null,
  authLink: null,
  authToken: null,
  passwordChanged: false,
  parlamentSection: process.env.REACT_APP_PARLAMENT_SECTION,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setParlamentSection: (state, action) => {
      state.parlamentSection = action.payload;
    },

    logout: (state) => {
      state.profile = null;
      state.data = null;
    },
  },
  extraReducers(builder) {
    builder
      /* .addCase(login.pending, (state, action) => {
         state.loading = true;
       })
       .addCase(login.fulfilled, (state, action) => {
         state.loading = false;
         state.data = action.payload;
       })
       .addCase(login.rejected, (state, action) => {
         console.log("error", action.error.message);
         state.loading = false;
         state.error = action.error.message;
       }) */
      .addCase(getProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(getProfile.rejected, (state, action) => {
        console.log("error", action.error.message);
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAuthLink.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAuthLink.fulfilled, (state, action) => {
        state.loading = false;
        state.authLink = action.payload.url;
      })
      .addCase(getAuthLink.rejected, (state, action) => {
        console.log("error", action.error.message);
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getAuthToken.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAuthToken.fulfilled, (state, action) => {
        state.loading = false;
        state.authToken = action.payload.access_token;
        state.profile = action.payload.user;
      })
      .addCase(getAuthToken.rejected, (state, action) => {
        console.log("error", action.error.message);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// export const login = createAsyncThunk(
//   "accountSlice/login",
//   async (credentials) => {
//     const { url, method, headers, data } = api.login(credentials);
//     const account = await axios({
//       url,
//       method,
//       headers,
//       data,
//     });
//     if (account) {
//       localStorage.setItem("authToken", account.data.access_token);
//     }
//     return account.data;
//   }
// );

export const getProfile = createAsyncThunk(
  "accountSlice/getProfile",
  async () => {
    const { url, method, headers } = api.getProfile();
    const profile = await axios({
      url,
      method,
      headers,
    });

    return profile.data;
  }
);

export const getAuthLink = createAsyncThunk(
  "accountSlice/getAuthLink",
  async () => {
    const { url, method, headers } = api.getAuthLink();
    const authLink = await axios({
      url,
      method,
      headers,
    });

    return authLink.data;
  }
);

export const getAuthToken = createAsyncThunk(
  "accountSlice/getAuthToken",
  async (code) => {
    const { url, method, headers } = api.getAuthToken(code);
    const authToken = await axios({
      url,
      method,
      headers,
    });
    if (authToken) {
      localStorage.setItem("authToken", authToken.data.access_token);
      localStorage.setItem("user", JSON.stringify(authToken.data.user));
    }
    return authToken.data;
  }
);

// export const getProfile = createAsyncThunk(
//   "accountSlice/getProfile",
//   async () => {
//     const { url, method, headers } = api.getProfile();
//     const profile = await axios({
//       url,
//       method,
//       headers,
//     });

//     return profile.data;
//   }
// );

// export const changePassword = createAsyncThunk(
//   "accountSlice/changePassword",
//   async ({ userCode, updateData }) => {
//     const { url, method, headers, data } = api.changePassword(
//       userCode,
//       updateData
//     );
//     const profile = await axios({
//       url,
//       method,
//       headers,
//       data,
//     });

//     return profile.data;
//   }
// );
// export const updateProfile = createAsyncThunk(
//   "accountSlice/updateProfile",
//   async (profileData) => {
//     const { url, method, headers, data } = api.updateUser(
//       profileData.id,
//       profileData
//     );

//     const profile = await axios({
//       url,
//       method,
//       headers,
//       data,
//     });

//     return profile.data;
//   }
// );

export const { logout, setParlamentSection } = accountSlice.actions;
export const { actions } = accountSlice;
export default accountSlice.reducer;
