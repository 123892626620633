//libraries import

//style import
import "./CameraFooter.css";

//resources import
import { ReactComponent as LogoSvg } from "../../assets/logo-camera.svg";
import VersionInfo from "../version-info/VersionInfo";

export default function CameraFooter() {
  return (
    <footer>
      <div className="riga">
        <div className="logo">
          <LogoSvg />
        </div>
        <p variant="body2">
          {" "}
          Camera dei deputati - &copy; {new Date().getFullYear()}
        </p>
      </div>
      <VersionInfo iconColor={"#fff"} />
    </footer>
  );
}
