import { XMLParser } from "fast-xml-parser";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  getCameraDdlList,
  getSenatoDdlList,
  getSingleCameraTextById,
  getSingleDdlById,
  getSingleDdlTextById,
  setDdlID,
} from "../../features/ddlSlice";
import { Spinner } from "../../shared/Spinner";
import { setParlamentSection } from "../../features/accountSlice";
import { getAuthLink } from "../../features/accountSlice";
import SelectHeader from "./components/SelectHeader";
import { useNavigate } from "react-router-dom";

const optionsArraySenatoLocal = [
  {
    value: "/xmlFiles/50863.xml",
    label:
      "50863 - Disposizioni in materia di attribuzione del cognome ai figli",
  },
  {
    value: "/xmlFiles/51037.xml",
    label:
      "51037 - Disposizioni per il riconoscimento degli alunni con alto potenziale cognitivo, l'adozione di piani didattici personalizzati e la formazione del personale scolastico",
  },

  {
    value: "/xmlFiles/51608.xml",
    label:
      "51608 - Istituzione di una Commissione parlamentare d'inchiesta sulla condizione del lavoro in Italia",
  },
  {
    value: "/xmlFiles/51397.xml",
    label:
      "51397 - Modifiche al decreto legislativo 17 agosto 1999, n. 368, e al decreto legislativo 30 dicembre 1992, n. 502, in materia di formazione specialistica dei medici",
  },
];

const optionsArrayCameraLocal = [
  {
    value: "/xhtmlFiles/PDL0006910.html",
    label: "Progetto di legge 6910",
  },
  {
    value: "/xhtmlFiles/PDL0002660.html",
    label: "Progetto di legge 2660",
  },
  {
    value: "/xhtmlFiles/PDL0004660.html",
    label: "Progetto di legge 4660",
  },
  {
    value: "/xhtmlFiles/PDL0005791.html",
    label: "Progetto di legge 5791",
  },
  {
    value: "/xhtmlFiles/PDL0010450.html",
    label: "Progetto di legge 10450",
  },
  {
    value: "/xhtmlFiles/PDL0012790.html",
    label: "Progetto di legge 12790",
  },
  {
    value: "/xhtmlFiles/PDL0013640.html",
    label: "Progetto di legge 13640",
  },
];

const SelectEmedaments = ({ setXmlData, setXhtmlData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isCameraSelected, setIsCameraSelected] = useState(false);
  const [isSenatoSelected, setIsSenatoSelected] = useState(false);
  const [ddlWithNoText, setDdlWithNoText] = useState(false);
  const parlamentSection = useSelector(
    (state) => state.account.parlamentSection
  );
  const [optionsArraySenato, setOptionsArraySenato] = useState([]);
  const [optionsArrayCamera, setOptionsArrayCamera] = useState([]);

  const ddlSenatoList = useSelector((state) => state.ddl.ddlSenatoList);
  const ddlSenatoData = useSelector((state) => state.ddl.ddlSenatoData);
  const ddlSenatoText = useSelector((state) => state.ddl.ddlSenatoText);
  const ddlCameraList = useSelector((state) => state.ddl.ddlCameraList);

  const ddlCameraText = useSelector((state) => state.ddl.ddlCameraText);

  const loading = useSelector((state) => state.ddl.loading);
  const ddlIdFromStorage = localStorage.getItem("ddlID");
  const idTestoSenatoFromStorage = localStorage.getItem("idTestoSenato");
  const navetteFromStorage = localStorage.getItem("navetteCamera");
  const stralcioFromStorage = localStorage.getItem("stralcioCamera");

  useEffect(() => {
    dispatch(getAuthLink());
  }, []);

  useEffect(() => {
    if (!!ddlIdFromStorage) {
      if (parlamentSection === "senato") {
        dispatch(getSingleDdlTextById(idTestoSenatoFromStorage));
      } else {
        dispatch(
          getSingleCameraTextById({
            idLegislatura: 19,
            tipo: "PDL",
            numero: parseInt(ddlIdFromStorage),
            // navette se esiste
            navette: navetteFromStorage || null,
            // stralcio se esiste
            stralcio: stralcioFromStorage || null,
          })
        );
      }
    }
  }, [ddlIdFromStorage]);

  //////// GESTIONE LISTE DDL SENATO E CAMERA

  useEffect(() => {
    if (
      (!!isSenatoSelected || parlamentSection === "senato") &&
      !ddlSenatoList?.length
    ) {
      dispatch(getSenatoDdlList());
    }
  }, [isSenatoSelected, parlamentSection]);

  useEffect(() => {
    if (
      (!!isCameraSelected || parlamentSection === "camera") &&
      !isCameraSelected?.length
    ) {
      dispatch(getCameraDdlList());
    }
  }, [isCameraSelected, parlamentSection]);

  useEffect(() => {
    if (ddlSenatoList?.length > 0) {
      // Crea una copia dell'array e poi esegui il sort sulla copia
      const sortedDdlSenatoList = [...ddlSenatoList].sort(
        (a, b) => a.numFase - b.numFase
      );

      const optionsArraySenato = sortedDdlSenatoList.map((ddl) => {
        return {
          ...ddl,
          value: ddl.idFase,
          label: `${ddl.numFase} - ${ddl.titolo}`,
        };
      });
      setOptionsArraySenato(optionsArraySenato);
    }
  }, [ddlSenatoList]);

  useEffect(() => {
    if (ddlCameraList?.length > 0) {
      const sortedDdlCameraList = [...ddlCameraList].sort(
        (a, b) =>
          a.numeroAtto - b.numeroAtto || a.numeroAttoCamera - b.numeroAttoCamera
      );

      const optionsArrayCamera = sortedDdlCameraList.map((ddl) => {
        return {
          ...ddl,
          value: ddl.idPdl,
          label: `${ddl.numeroAtto || ddl.numeroAttoCamera} - ${ddl.titolo}`,
        };
      });
      setOptionsArrayCamera(
        optionsArrayCamera.sort((a, b) => a.label - b.label)
      );
    }
  }, [ddlCameraList]);

  ////////

  const handleSelectSenatoLaw = async (selectedOption) => {
    try {
      /*      const idFase = selectedOption.value;
     dispatch(getSingleDdlById(idFase)); */

      const response = await fetch(selectedOption.value);
      // //get id from label
      const id = selectedOption.label.split(" ")[0];
      dispatch(setDdlID(id));
      const xmlText = await response.text();
      const options = {
        ignoreAttributes: false,
        parseAttributeValue: true,
      };
      let xmlString = xmlText;
      // // Rimuovi i tag con namespace h, mantenendo il loro contenuto

      // // Rimuovi i tag <h:i> (con o senza attributi) e </h:i>
      xmlString = xmlString.replace(/<h:i[^>]*>|<\/h:i>/g, "");
      // // Rimuovi i tag <h:b> (con o senza attributi) e </h:b>
      xmlString = xmlString.replace(/<h:b[^>]*>|<\/h:b>/g, "");

      // // Rimuovi il tag <h:br> (con o senza attributi)
      xmlString = xmlString.replace(/<h:br[^>]*\/?>/g, "");
      // // Rimuovi il tag <h:span> (con o senza attributi) e </h:span>
      xmlString = xmlString.replace(/<h:span[^>]*>|<\/h:span>/g, "");
      // // rimuovi class="em2" o class="em3" ecc
      xmlString = xmlString.replace(/class="em\d"/g, "");
      const parser = new XMLParser(options);
      let jObj = parser.parse(xmlString);
      dispatch(setParlamentSection("senato"));
      setXmlData(jObj.BGTDOC["dl:DDLPRES"]);
    } catch (error) {
      console.error("Error fetching the XML file:", error);
    }
  };

  useEffect(() => {
    if (!!ddlSenatoData) {
      const idTesto = ddlSenatoData.idTesto;
      const idDdl = ddlSenatoData.numFase;
      if (!idTesto) {
        setDdlWithNoText(true);
      } else {
        setDdlWithNoText(false);
        dispatch(setDdlID(idDdl));
        localStorage.setItem("ddlID", idDdl);
        localStorage.setItem("idTestoSenato", idTesto);
        navigate(`/editor/${idDdl}?textId=${idTesto}`);
        /*         dispatch(getSingleDdlTextById(idTesto)); */
      }
    }
  }, [ddlSenatoData]);

  const handleSelectCameraLaw = async (selectedOption) => {
    dispatch(
      setDdlID(selectedOption.numeroAtto || selectedOption.numeroAttoCamera)
    );
    navigate(
      `/editor/${
        selectedOption.numeroAtto || selectedOption.numeroAttoCamera
      }?navette=${selectedOption.navette || ""}&stralcio=${
        selectedOption.stralcio || ""
      }`
    );
    /*     dispatch(
      getSingleCameraTextById({
        idLegislatura: 19,
        tipo: "PDL",
        numero: selectedOption.numeroAtto
          ? parseInt(selectedOption.numeroAtto)
          : parseInt(selectedOption.numeroAttoCamera),
        // navette se esiste
        navette: selectedOption.navette ? selectedOption.navette : null,
        // stralcio se esistew
        stralcio: selectedOption.stralcio ? selectedOption.stralcio : null,
      })
    ); */
    /*      localStorage.setItem(
        "ddlID",
        selectedOption.numeroAtto || selectedOption.numeroAttoCamera
      );
      localStorage.setItem("navetteCamera", selectedOption.navette);
      localStorage.setItem("stralcioCamera", selectedOption.stralcio); */

    ////////// LEGGE DI DEFAULT FROM STORAGE

    try {
      const response = await fetch(selectedOption.value);

      // get id from label
      const id = selectedOption.label.split(" ")[3];
      dispatch(setDdlID(id));
      localStorage.setItem("ddlID", id);
      const htmlText = await response.text();
      dispatch(setParlamentSection("camera"));
      setXhtmlData(htmlText);
    } catch (error) {
      console.error("Error fetching the XML file:", error);
    }
  };

  /*   useEffect(() => {
    if (!!ddlSenatoText) {
      const options = {
        ignoreAttributes: false,
        parseAttributeValue: true,
      };

      let xmlString = ddlSenatoText;
      // Rimuovi i tag con namespace h, mantenendo il loro contenuto

      // Rimuovi i tag <h:i> (con o senza attributi) e </h:i>
      xmlString = xmlString.replace(/<h:i[^>]*>|<\/h:i>/g, "");
      // Rimuovi i tag <h:b> (con o senza attributi) e </h:b>
      xmlString = xmlString.replace(/<h:b[^>]*>|<\/h:b>/g, "");

      // Rimuovi il tag <h:br> (con o senza attributi)
      xmlString = xmlString.replace(/<h:br[^>]*\/?>/g, "");
      // Rimuovi il tag <h:span> (con o senza attributi) e </h:span>
      xmlString = xmlString.replace(/<h:span[^>]*>|<\/h:span>/g, "");
      // rimuovi class="em2" o class="em3" ecc
      xmlString = xmlString.replace(/class="em\d"/g, "");

      const parser = new XMLParser(options);

      let jObj = parser.parse(xmlString);
      dispatch(setParlamentSection("senato"));
      setXmlData(jObj.BGTDOC["dl:DDLPRES"]);
    }
  }, [ddlSenatoText]);
 */
  /*   useEffect(() => {
    if (!!ddlCameraText) {
      dispatch(setParlamentSection("camera"));
      setXhtmlData(ddlCameraText);
    }
  }, [ddlCameraText]);
 */

  return (
    <div
      style={{
        minHeight:
          parlamentSection === "camera" ? "calc(100vh - 75px)" : "100vh",
        width: "100%",
      }}
    >
      <SelectHeader />

      {!parlamentSection && (
        <div
          className="flex-row align-center justify-around"
          style={{
            height: "30vh",
          }}
        >
          <div
            onClick={() => {
              setIsCameraSelected(true);
              dispatch(setParlamentSection("camera"));
              setIsSenatoSelected(false);
            }}
            className="flex-row align-center justify-center"
            style={{
              width: "30%",
              height: "108px",
              backgroundColor: "var(--primary)",
              border: "2px solid #ccc",
              color: "white",
              cursor: "pointer",
              fontWeight: 600,
              fontSize: "1.4rem",
            }}
          >
            Camera dei deputati
          </div>
          <div
            onClick={() => {
              setIsSenatoSelected(true);
              dispatch(setParlamentSection("senato"));
              setIsCameraSelected(false);
            }}
            className="flex-row align-center justify-center"
            style={{
              width: "30%",
              height: "108px",
              backgroundColor: "var(--primary)",
              border: "2px solid #ccc",
              color: "white",
              cursor: "pointer",
              fontWeight: 600,
              fontSize: "1.4rem",
            }}
          >
            Senato della Repubblica
          </div>
        </div>
      )}
      {(!!isCameraSelected || parlamentSection === "camera") && (
        <div className="flex-column align-center mt-4">
          <h1>Seleziona un provvedimento</h1>
          <Select
            options={optionsArrayCameraLocal}
            onChange={(e) => handleSelectCameraLaw(e)}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "50%",
                margin: "auto",
                marginTop: "20px",
              }),
            }}
            placeholder="Seleziona un provvedimento"
          />
        </div>
      )}
      {!!loading && (
        <div
          className="flex-column align-center"
          style={{
            marginTop: "20px",
          }}
        >
          <Spinner />
        </div>
      )}
      {(!!isSenatoSelected || parlamentSection === "senato") && !loading && (
        <div className="flex-column align-center mt-4">
          <h1>Senato della Repubblica - Seleziona un provvedimento</h1>
          <Select
            options={optionsArraySenatoLocal}
            onChange={(e) => handleSelectSenatoLaw(e)}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "50%",
                margin: "auto",
                marginTop: "20px",
              }),
            }}
            placeholder="Seleziona un progetto di legge"
          />
          {!!ddlWithNoText && (
            <p
              style={{
                marginTop: "12px",
                color: "var(--danger)",
                fontWeight: 600,
              }}
            >
              Testo non ancora disponibile
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectEmedaments;

/*  const handleXMLUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const text = await file.text();

      const options = {
        ignoreAttributes: false,
        parseAttributeValue: true,
      };
      const parser = new XMLParser(options);

      let jObj = parser.parse(text);
      // entriamo un po'
      setXmlData(jObj.BGTDOC["dl:DDLPRES"]);
    }
  };

  const handleXHTMLUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const text = await file.text();
      setXhtmlData(text);
    }
  
  };
 */

{
  /*   <div className="flex-column align-center">
    <>
      <h1>XML Viewer</h1>
      <input type="file" accept=".xml" onChange={handleXMLUpload} />
    </>
    <br />
    <>
      <h1>XHTML Viewer</h1>
      <input
        type="file"
        accept=".xhtml, .html"
        onChange={handleXHTMLUpload}
      />
    </>
  </div> */
}
