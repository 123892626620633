import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu,
} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import { styled } from "styled-components";

const StyledMenuItem = styled(Item)`
  font-weight: 600;
  &:hover > div {
    background-color: ${(props) =>
      `${props.backgroundColor} !important` || "#f1f1f1 !important"};
  }
`;

export default function ContextMenu(props) {
  // 🔥 you can use this hook from everywhere. All you need is the menu id
  const { show } = useContextMenu({
    id: props.id,
  });

  /*   function handleItemClick({ event, props, triggerEvent, data }) {
    console.log(event, props, triggerEvent, data);
  } */

  function displayMenu(e) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      event: e,
    });
  }

  return (
    <div>
      {/* run custom logic then display the menu */}
      <div onContextMenu={displayMenu} onClick={displayMenu}>
        {props.children}
      </div>
      <Menu id={props.id}>
        {props.items?.length > 0 &&
          props.items.map((item, i) => (
            <StyledMenuItem
              key={`${props.id}_item_${i}`}
              backgroundColor={props.backgroundColor}
              onClick={() => item.handleItemClick()}
            >
              {item?.label}
            </StyledMenuItem>
          ))}
      </Menu>
    </div>
  );
}
