import { getPositionReference } from "./formatStringFuncs";

const isSingleWord = (text) => text.split(" ").length === 1;

export const getChangedPhrases = (
  paragraphPath,
  diff,
  parlamentSection,
  conseguentementeActive
) => {
  // teniamo solo le ultime due parole

  const positionReference = getPositionReference(
    paragraphPath,
    null,
    conseguentementeActive
  );

  const changedPhrases = [];

  for (let i = 0; i < diff.length - 1; i++) {
    const currentSection = diff[i];
    const nextSection = diff[i + 1];
    const previousSection = diff[i - 1];
    let [currentOp, currentText] = currentSection;
    let [nextOp, nextText] = nextSection || [];
    let [prevOp, prevText] = previousSection || [];
    const isCurrentSingleWord = isSingleWord(currentText);
    const isNextSingleWord = isSingleWord(nextText);
    const isAFullWordOrPhrase =
      currentText[0] === " " || currentText[currentText.length - 1] === " ";

    /////////////////////////////////// SOSTITUZIONE ///////////////////////////////////

    if (currentOp === -1 && nextOp === 1) {
      //// ci assicuriamo di ritornare le parole intere e non solo le lettere modificate ////
      // get last word of prevTextious phrase if it exists and is not a space
      if (i - 1 >= 0 && prevText !== " ") {
        const lastWord = prevText.split(" ").pop();
        if (!!lastWord) {
          currentText = lastWord + currentText;
          nextText = lastWord + nextText;
        }
      }

      // get first word of nextText phrase if it exists and is not a space
      if (i + 2 < diff.length && diff[i + 2][1] !== " ") {
        const firstWord = diff[i + 2][1].split(" ").shift();
        if (!!firstWord) {
          currentText = currentText + firstWord;
          nextText = nextText + firstWord;
        }
      }
      // Sostituzione di una parola con un'altra
      if (!!isCurrentSingleWord && !!isNextSingleWord) {
        changedPhrases.push(
          // `${positionReference}, sostituire la parola: «${currentText}» con la seguente: «${nextText}»`
          `${positionReference}, <i>sostituire la parola:</i>
          <br />
          «${currentText}»
          <br />
          <i>con la seguente:</i>
          <br />
          «${nextText}»`
        );
        // Sostituzione di una frase con un'altra
      } else if (isCurrentSingleWord === false && isNextSingleWord === false) {
        changedPhrases.push(
          // `${positionReference}, sostituire le parole: «${currentText}» con le seguenti: «${nextText}»`
          `${positionReference}, <i>sostituire le parole:</i>
          <br />
          «${currentText}»
          <br />
         <i>con le seguenti: </i>
          <br /> 
          «${nextText}»`
        );
      } else if (!!isCurrentSingleWord && isNextSingleWord === false) {
        // Sostituzione di una parola con una frase
        changedPhrases.push(
          // `${positionReference}, sostituire la parola: «${currentText}» con le seguenti: «${nextText}»`
          `${positionReference},<i> sostituire la parola:</i>
          <br />
          «${currentText}»
          <br />
         <i>con le seguenti:</i>
          <br />
          «${nextText}»`
        );
      } else if (isCurrentSingleWord === false && !!isNextSingleWord) {
        // Sostituzione di una frase con una parola
        changedPhrases.push(
          // `${positionReference}, sostituire le parole: «${currentText}» con la seguente: «${nextText}»`
          `{positionReference}, <i>sostituire le parole:</i>
          <br />
          «${currentText}»
          <br />
         <i>con la seguente:</i>
          <br />
          «${nextText}»`
        );
      } else {
        console.log("ERRORE");
      }

      i++; // Passa alla prossima operazione di inserimento
    }

    /////////////////////////////////// AGGIUNTA ///////////////////////////////////
    /*     console.log("curentOP", currentOp);
    console.log("nextOp", nextOp);
    console.log("prevOp", prevOp); */

    ////  01. aggiunta di una parola o di una frase alla fine di una frase //
    if (currentOp === 0 && nextOp === 1 && !diff[i + 2]) {
      // Aggiunta di una parola
      if (!!isNextSingleWord) {
        changedPhrases.push(
          // `${positionReference}, aggiungere, in fine, la parola: «${currentText}»`
          `${positionReference}<i>, aggiungere, in fine, la parola:</i> 
            <br />
             «${nextText}»`
        );
        continue;
      }
      // Aggiunta di una frase
      if (isNextSingleWord === false) {
        changedPhrases.push(
          // `${positionReference}, aggiungere, in fine, le parole: «${currentText}»`
          `${positionReference}<i>, aggiungere, in fine, le parole:</i> 
            <br />
             «${nextText}»`
        );
        continue;
      }
    }

    if (currentOp === 1) {
      // assicuriamoci che la parola non sia una modifica ma un'aggiunta vera e propria //
      if (!!previousSection && prevOp === -1) {
        continue;
      }

      ////  2. aggiunta di una parola o di una frase inizi di una frase //
      if (i - 1 < 0) {
        // Aggiunta di una parola
        if (!!isCurrentSingleWord) {
          changedPhrases.push(
            // `${positionReference}, aggiungere la parola: «${currentText}»`
            `${positionReference}<i>, aggiungere la parola:</i> 
            <br />
             «${currentText}»`
          );
          continue;
        }
        // Aggiunta di una frase
        if (isCurrentSingleWord === false) {
          changedPhrases.push(
            // `${positionReference}, aggiungere le parole: «${currentText}»`
            `${positionReference}<i>, aggiungere le parole:</i> 
            <br />
             «${currentText}»`
          );
          continue;
        }
      }

      ////  3. aggiunta di una parola o di una frase alla fine di una frase //
      if (i + 1 >= diff.length) {
        // Aggiunta di una parola
        if (!!isCurrentSingleWord) {
          changedPhrases.push(
            // `${positionReference}, aggiungere, in fine, la parola: «${currentText}»`
            `${positionReference}<i>, aggiungere, in fine, la parola:</i> 
            <br />
             «${currentText}»`
          );
          continue;
        }
        // Aggiunta di una frase
        if (isCurrentSingleWord === false) {
          changedPhrases.push(
            // `${positionReference}, aggiungere, in fine, le parole: «${currentText}»`
            `${positionReference}<i>, aggiungere, in fine, le parole:</i> 
            <br />
             «${currentText}»`
          );
          continue;
        }
      }

      if (
        (prevText[prevText?.length - 1] !== " " || nextText[0] !== " ") &&
        !isAFullWordOrPhrase
      ) {
        let newCurrentText = currentText;
        let newNextText = nextText;

        //// 0a. aggiunta di una parte interna di parola => rientra nel caso di sostituzione //
        if (prevText[prevText?.length - 1] !== " " && nextText[0] !== " ") {
          // preniamo la parola precedente e quella successiva e le uniamo
          const prevWord = prevText.split(" ").pop();
          const nextWord = nextText.split(" ").shift();

          newCurrentText = prevWord + nextWord;
          newNextText = prevWord + currentText + nextWord;
        }
        /// 0b. aggiunta di una parte finale di parola => rientra nel caso di sostituzione //
        else if (prevText[prevText?.length - 1] !== " ") {
          // preniamo la parola precedente e quella successiva e le uniamo
          const prevWord = prevText.split(" ").pop();
          newCurrentText = prevWord + nextText.split(" ").shift();
          newNextText = prevWord + currentText;
        }
        /// 0c. aggiunta di una parte iniziale di parola => rientra nel caso di sostituzione //
        else if (nextText[0] !== " ") {
          // preniamo la parola precedente e quella successiva e le uniamo
          const nextWord = nextText.split(" ").shift();
          newCurrentText = nextWord;
          newNextText = currentText + nextWord;
        } else {
          console.log("ERRORE");
        }

        changedPhrases.push(
          // `${positionReference}, sostituire la parola: «${currentText}» con la seguente: «${nextText}»`
          `${positionReference}<i>, sostituire la parola:</i>
          <br />
          «${newCurrentText}»
          <br />
          <i>con la seguente:</i>
          <br />
          «${newNextText}»`
        );
        continue;
      }

      ////  1. aggiunta di una parola o di una frase in mezzo ad altre parole o frasi //
      if (i - 1 >= 0 && i + 1 < diff.length) {
        if (prevText !== " " && nextText !== " ") {
          const trimmedText = currentText?.trim();

          // TODO: capire quante parole premettere in prevText
          // Aggiunta di una parola
          if (!!isCurrentSingleWord) {
            changedPhrases.push(
              // `${positionReference}, dopo la parole «${prevText}», aggiungere la parola: «${currentText}»`
              `${positionReference}<i>, dopo la parole</i> 
              <br />
               «${prevText}», 
              <br />
               <i>aggiungere la parola:</i>
              <br />
               «${currentText}»`
            );
            continue;
          }

          // Aggiunta di una frase
          if (isCurrentSingleWord === false) {
            changedPhrases.push(
              // `${positionReference}, dopo la parole «${prevText}», aggiungere le parole: «${currentText}»`
              `${positionReference}<i>, dopo la parole</i> 
              <br />
               «${prevText}», 
              <br />
               <i>aggiungere le parole:</i> 
              <br />
               «${currentText}»`
            );
            continue;
          }
        }
      }

      // Aggiunta di una parola
      if (!!isCurrentSingleWord) {
        changedPhrases.push(
          // `${positionReference}, aggiungere la parola: «${currentText}»`
          `${positionReference}<i>, aggiungere la parola:</i> 
          <br />
           «${currentText}»`
        );
        // Aggiunta di una frase
      } else if (isCurrentSingleWord === false) {
        changedPhrases.push(
          // `${positionReference}, aggiungere le parole: «${currentText}»`
          `${positionReference}<i>, aggiungere le parole:</i> 
          <br />
           «${currentText}»`
        );
      } else {
        console.log("ERRORE");
      }
    }

    /////////////////////////////////// RIMOZIONE ///////////////////////////////////

    if (currentOp === -1) {
      // assicuriamoci che la parola non sia una modifica ma una rimozione vera e propria di una parola intera e non di una parte di essa //
      if (!!nextSection?.length && nextOp === 1) {
        continue;
      }

      if (
        !!prevText &&
        (prevText[prevText?.length - 1] !== " " || nextText[0] !== " ") &&
        !isAFullWordOrPhrase
      ) {
        let newCurrentText = currentText;
        let newNextText = nextText;

        //// 0a. rimozione di una parte interna di parola => rientra nel caso di sostituzione //
        if (prevText[prevText?.length - 1] !== " " && nextText[0] !== " ") {
          // preniamo la parola precedente e quella successiva e le uniamo
          const prevWord = prevText.split(" ").pop();
          const nextWord = nextText.split(" ").shift();

          newCurrentText = prevWord + currentText + nextWord;
          newNextText = prevWord + nextWord;
        }
        /// 0b. rimozione di una parte finale di parola => rientra nel caso di sostituzione //
        else if (prevText[prevText?.length - 1] !== " ") {
          // preniamo la parola precedente e quella successiva e le uniamo
          const prevWord = prevText.split(" ").pop();
          newCurrentText = prevWord + currentText;
          newNextText = prevWord + nextText.split(" ").shift();
        }
        /// 0c. rimozione di una parte iniziale di parola => rientra nel caso di sostituzione //
        else if (nextText[0] !== " ") {
          // preniamo la parola precedente e quella successiva e le uniamo
          const nextWord = nextText.split(" ").shift();
          newCurrentText = currentText + nextWord;
          newNextText = nextWord;
        } else {
          console.log("ERRORE");
        }

        changedPhrases.push(
          // `${positionReference}, sostituire la parola: «${currentText}» con la seguente: «${nextText}»`
          `${positionReference}<i>, sostituire la parola:</i>
          <br />
          «${newCurrentText}» 
          <br />
          <i>con la seguente:</i>
          <br />
          «${newNextText}»`
        );

        continue;
      }

      ////  1. rimozione di una parola o di una frase in mezzo ad altre parole o frasi //
      if (i - 1 >= 0 && i + 1 < diff.length) {
        if (prevText !== " " && nextText !== " ") {
          const trimmedText = currentText?.trim();

          // Rimozione di una parola
          if (!!isCurrentSingleWord) {
            changedPhrases.push(
              // `${positionReference}, dopo la parola «${prevText}», sopprimere la parola: «${currentText}»`
              `${positionReference}<i>, dopo la parola</i> 
              <br />
               «${prevText}», 
              <br />
              <i>sopprimere la parola:</i> 
              <br />
               «${currentText}»`
            );
            continue;
          }

          // Rimozione di una frase
          if (isCurrentSingleWord === false) {
            changedPhrases.push(
              // `${positionReference}, dopo le parole «${prevText}», sopprimere le parole: «${currentText}»`
              `${positionReference}<i>, dopo le parole</i> 
              <br />
               «${prevText}», 
              <br />
              <i>sopprimere le parole:</i> 
              <br />
               «${currentText}»`
            );
            continue;
          }
        }
      }
      ///   2. rimozione di una parola o di una frase alla fine di una frase //
      if (i + 1 >= diff.length) {
        // Rimozione di una parola
        if (!!isCurrentSingleWord) {
          changedPhrases.push(
            // `${positionReference}, sopprimere la parola: «${currentText}»`
            `${positionReference}<i>, sopprimere la parola:</i> 
            <br />
            «${currentText}»`
          );
          continue;
        }
        // Rimozione di una frase
        if (isCurrentSingleWord === false) {
          changedPhrases.push(
            // `${positionReference}, sopprimere le parole: «${currentText}»`
            `${positionReference}<i>, sopprimere le parole:</i> 
            <br />
            «${currentText}» 
            <br />
           <i>alla conclusione del periodo</i>`
          );
          continue;
        }
      }
      /// 3. rimozione di una parola o di una frase all'inizio di una frase //
      if (i - 1 < 0) {
        // Rimozione di una parola
        if (!!isCurrentSingleWord) {
          changedPhrases.push(
            // `${positionReference}, sopprimere la parola: «${currentText}»`
            `${positionReference}<i>, sopprimere la parola:</i>
            <br />
            «${currentText}»`
          );
          continue;
        }
        // Rimozione di una frase
        if (isCurrentSingleWord === false) {
          changedPhrases.push(
            // `${positionReference}, sopprimere le parole: «${currentText}»`
            `${positionReference}<i>, sopprimere le parole:</i>
            <br />
            «${currentText}»
            <br />
           <i>all'inizio del periodo</i>`
          );
          continue;
        }
      } else {
        console.log("ERRORE");
      }
    }
  }
  return [{ emendament: changedPhrases, section: paragraphPath }];
};

// export const detectReplacedWords = (paragraphPath, diff) => {
//   const replacedPhrases = [];

//   for (let i = 0; i < diff.length - 1; i++) {
//     const currentSection = diff[i];
//     const nextSection = diff[i + 1];
//     const previousSection = diff[i - 1];
//     let [currentOp, currentText] = currentSection;
//     let [nextOp, nextText] = nextSection || [];
//     let [prevOp, prevText] = previousSection || [];
//     const isCurrentSingleWord = isSingleWord(currentText);
//     const isNextSingleWord = isSingleWord(nextText);

//     //// ci assicuriamo di ritornare le parole intere e non solo le lettere modificate ////

//     // get last word of prevTextious phrase if it exists and is not a space
//     if (i - 1 >= 0 && prevText !== " ") {
//       const lastWord = prevText.split(" ").pop();
//       if (!!lastWord) {
//         currentText = lastWord + currentText;
//         nextText = lastWord + nextText;
//       }
//     }

//     // get first word of nextText phrase if it exists and is not a space
//     if (i + 2 < diff.length && diff[i + 2][1] !== " ") {
//       const firstWord = diff[i + 2][1].split(" ").shift();
//       if (!!firstWord) {
//         currentText = currentText + firstWord;
//         nextText = nextText + firstWord;
//       }
//     }

//     //////

//     if (currentOp === -1 && nextOp === 1) {
//       // console.log("currentText", currentText);
//       // console.log("nextText", nextText);
//       // Sostituzione di una parola con un'altra
//       if (!!isCurrentSingleWord && !!isNextSingleWord) {
//         replacedPhrases.push(
//           // `${paragraphPath}, sostituire la parola: «${currentText}» con la seguente: «${nextText}»`
//           `${paragraphPath}, sostituire la parola:
//           <br />
//           «${currentText}»
//           <br />
//           con la seguente:
//           <br />
//           «${nextText}»`
//         );
//         // Sostituzione di una frase con un'altra
//       } else if (isCurrentSingleWord === false && isNextSingleWord === false) {
//         replacedPhrases.push(
//           // `${paragraphPath}, sostituire le parole: «${currentText}» con le seguenti: «${nextText}»`
//           `${paragraphPath}, sostituire le parole:
//           <br />
//           «${currentText}»
//           <br />
//           con le seguenti:
//           <br />
//           «${nextText}»`
//         );
//       } else if (!!isCurrentSingleWord && isNextSingleWord === false) {
//         // Sostituzione di una parola con una frase
//         replacedPhrases.push(
//           // `${paragraphPath}, sostituire la parola: «${currentText}» con le seguenti: «${nextText}»`
//           `${paragraphPath}, sostituire la parola:
//           <br />
//           «${currentText}»
//           <br />
//           con le seguenti:
//           <br />
//           «${nextText}»`
//         );
//       } else if (isCurrentSingleWord === false && !!isNextSingleWord) {
//         // Sostituzione di una frase con una parola
//         replacedPhrases.push(
//           // `${paragraphPath}, sostituire le parole: «${currentText}» con la seguente: «${nextText}»`
//           `{paragraphPath}, sostituire le parole:
//           <br />
//           «${currentText}»
//           <br />
//           con la seguente:
//           <br />
//           «${nextText}»`
//         );
//       } else {
//         console.log("ERRORE");
//       }

//       i++; // Passa alla prossima operazione di inserimento
//     }
//   }

//   return replacedPhrases;
// };

// export const detectAddedWords = (paragraphPath, diff) => {
//   const addedPhrases = [];

//   for (let i = 0; i < diff.length; i++) {
//     const currentSection = diff[i];
//     const nextSection = diff[i + 1];
//     const previousSection = diff[i - 1];
//     let [currentOp, currentText] = currentSection;
//     let [nextOp, nextText] = nextSection || [];
//     let [prevOp, prevText] = previousSection || [];
//     const isCurrentSingleWord = isSingleWord(currentText);
//     const isNextSingleWord = isSingleWord(nextText);

//     if (currentOp === 1) {
//       // assicuriamoci che la parola non sia una modifica ma un'aggiunta vera e propria //
//       if (!!previousSection && prevOp === -1) {
//         continue;
//       }
//       ////  1. aggiunta di una parola o di una frase in mezzo ad altre parole o frasi //
//       if (i - 1 >= 0 && i + 1 < diff.length) {
//         if (prevText !== " " && nextText !== " ") {
//           const trimmedText = currentText?.trim();

//           // TODO: capire quante parole premettere in prevText
//           // Aggiunta di una parola
//           if (!!isCurrentSingleWord) {
//             addedPhrases.push(
//               // `${paragraphPath}, dopo la parole «${prevText}», aggiungere la parola: «${currentText}»`
//               `${paragraphPath}, dopo la parole
//               <br />
//                «${prevText}»,
//               <br />
//                aggiungere la parola:
//               <br />
//                «${currentText}»`
//             );
//             continue;
//           }

//           // Aggiunta di una frase
//           if (isCurrentSingleWord === false) {
//             addedPhrases.push(
//               // `${paragraphPath}, dopo la parole «${prevText}», aggiungere le parole: «${currentText}»`
//               `${paragraphPath}, dopo la parole
//               <br />
//                «${prevText}»,
//               <br />
//                aggiungere le parole:
//               <br />
//                «${currentText}»`
//             );
//             continue;
//           }
//         }
//       }

//       ////  2. aggiunta di una parola o di una frase inizi di una frase //
//       if (i - 1 < 0) {
//         // Aggiunta di una parola
//         if (!!isCurrentSingleWord) {
//           addedPhrases.push(
//             // `${paragraphPath}, aggiungere la parola: «${currentText}»`
//             `${paragraphPath}, aggiungere la parola:
//             <br />
//              «${currentText}»`
//           );
//           continue;
//         }
//         // Aggiunta di una frase
//         if (isCurrentSingleWord === false) {
//           addedPhrases.push(
//             // `${paragraphPath}, aggiungere le parole: «${currentText}»`
//             `${paragraphPath}, aggiungere le parole:
//             <br />
//              «${currentText}»`
//           );
//           continue;
//         }
//       }
//       ////  3. aggiunta di una parola o di una frase alla fine di una frase //
//       if (i + 1 >= diff.length) {
//         // Aggiunta di una parola
//         if (!!isCurrentSingleWord) {
//           addedPhrases.push(
//             // `${paragraphPath}, aggiungere, in fine, la parola: «${currentText}»`
//             `${paragraphPath}, aggiungere, in fine, la parola:
//             <br />
//              «${currentText}»`
//           );
//           continue;
//         }
//         // Aggiunta di una frase
//         if (isCurrentSingleWord === false) {
//           addedPhrases.push(
//             // `${paragraphPath}, aggiungere, in fine, le parole: «${currentText}»`
//             `${paragraphPath}, aggiungere, in fine, le parole:
//             <br />
//              «${currentText}»`
//           );
//           continue;
//         }
//       }

//       // Aggiunta di una parola
//       if (!!isCurrentSingleWord) {
//         addedPhrases.push(
//           // `${paragraphPath}, aggiungere la parola: «${currentText}»`
//           `${paragraphPath}, aggiungere la parola:
//           <br />
//            «${currentText}»`
//         );
//         // Aggiunta di una frase
//       } else if (isCurrentSingleWord === false) {
//         addedPhrases.push(
//           // `${paragraphPath}, aggiungere le parole: «${currentText}»`
//           `${paragraphPath}, aggiungere le parole:
//           <br />
//            «${currentText}»`
//         );
//       } else {
//         console.log("ERRORE");
//       }
//     }
//   }

//   return addedPhrases;
// };

// export const detectRemovedWords = (paragraphPath, diff) => {
//   const removedPhrases = [];
//   for (let i = 0; i < diff.length; i++) {
//     const currentSection = diff[i];
//     const nextSection = diff[i + 1];
//     const previousSection = diff[i - 1];
//     let [currentOp, currentText] = currentSection;
//     let [nextOp, nextText] = nextSection || [];
//     let [prevOp, prevText] = previousSection || [];
//     const isCurrentSingleWord = isSingleWord(currentText);
//     const isNextSingleWord = isSingleWord(nextText);

//     if (currentOp === -1) {
//       // assicuriamoci che la parola non sia una modifica ma una rimozione vera e propria di una parola intera e non di una parte di essa //
//       if (!!nextSection.length && nextOp === 1) {
//         continue;
//       }
//       ////  1. rimozione di una parola o di una frase in mezzo ad altre parole o frasi //
//       if (i - 1 >= 0 && i + 1 < diff.length) {
//         if (prevText !== " " && nextText !== " ") {
//           const trimmedText = currentText?.trim();

//           // Rimozione di una parola
//           if (!!isCurrentSingleWord) {
//             removedPhrases.push(
//               // `${paragraphPath}, dopo la parola «${prevText}», rimuovere la parola: «${currentText}»`
//               `${paragraphPath}, dopo la parola
//               <br />
//                «${prevText}»,
//               <br />
//                rimuovere la parola:
//               <br />
//                «${currentText}»`
//             );
//             continue;
//           }

//           // Rimozione di una frase
//           if (isCurrentSingleWord === false) {
//             removedPhrases.push(
//               // `${paragraphPath}, dopo le parole «${prevText}», rimuovere le parole: «${currentText}»`
//               `${paragraphPath}, dopo le parole
//               <br />
//                «${prevText}»,
//               <br />
//                rimuovere le parole:
//               <br />
//                «${currentText}»`
//             );
//             continue;
//           }
//         }
//       }
//       ///   2. rimozione di una parola o di una frase alla fine di una frase //
//       if (i + 1 >= diff.length) {
//         // Rimozione di una parola
//         if (!!isCurrentSingleWord) {
//           removedPhrases.push(
//             // `${paragraphPath}, rimuovere la parola: «${currentText}»`
//             `${paragraphPath}, rimuovere la parola:
//             <br />
//             «${currentText}»`
//           );
//           continue;
//         }
//         // Rimozione di una frase
//         if (isCurrentSingleWord === false) {
//           removedPhrases.push(
//             // `${paragraphPath}, rimuovere le parole: «${currentText}»`
//             `${paragraphPath}, rimuovere le parole:
//             <br />
//             «${currentText}»`
//           );
//           continue;
//         }
//       } else {
//         console.log("ERRORE");
//       }
//     }
//   }

//   return removedPhrases;
// };
