import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  PageBreak,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun,
  ExternalHyperlink,
} from "docx";

class DocumentCreator {
  create([emendaments]) {
    const sections = emendaments.map((emendament) => ({
      properties: {},
      children: [
        this.createHeading("Emendamento"),
        this.createSubHeading(emendament.section),
        this.createFirmatari(emendament.firmatari),
        this.createEmendamentText(emendament.emendament),
        this.createRelationText(emendament.relationText),
      ],
    }));

    const document = new Document({
      sections: sections,
    });

    return document;
  }

  createFirmatari(firmatari) {
    // map firmatari which is an array of strings in a unique paragraph with , and a space between each firmatario
    const firmatariParagraphs = new Paragraph({
      children: [
        new TextRun({
          text: firmatari.map((firmatario) => firmatario).join(", "),
        }),
      ],
    });

    return firmatariParagraphs;
  }

  createHeading(text) {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
    });
  }

  createSubHeading(text) {
    return new Paragraph({
      text: text,
      spacing: {
        before: 500,
        after: 500,
      },
      heading: HeadingLevel.HEADING_2,
    });
  }

  createRelationText(relationText) {
    if (!!relationText) {
      return new Paragraph({
        children: [
          new TextRun({
            text: "***RELAZIONE   ",
          }),
          new TextRun({
            text: relationText,
          }),
        ],
      });
    } else {
      return new Paragraph({
        children: [
          new TextRun({
            text: " ",
          }),
        ],
      });
    }
  }
  createEmendamentText(htmlText) {
    // Converte l'HTML in elementi DOCX usando la funzione htmlToDocx
    const docxElements = htmlToDocx(htmlText);

    // Restituisce un nuovo paragrafo che include tutti gli elementi DOCX generati
    return new Paragraph({
      children: docxElements,
    });
  }
  createSpacedParagraph() {
    return new Paragraph({
      text: "",
      spacing: {
        before: 500,
        after: 500,
      },
    });
  }
}

function trimMultipleSpaces(str) {
  return str.replace(/\s+/g, " ");
}

function htmlToDocx(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const elements = [];

  doc.body.childNodes.forEach((node) => {
    if (node.nodeType === 3) {
      // Text Node
      elements.push(new TextRun(trimMultipleSpaces(node.textContent)));
    } else if (node.nodeType === 1) {
      // Element Node

      // // If the node is an anchor <a>, wrap the TextRun in a Hyperlink
      // if (node.tagName.toLowerCase() === "a" && node.href) {
      //   textRun = new ExternalHyperlink({
      //     children: [textRun],
      //     link: node.href,
      //   });
      // }

      elements.push(
        new TextRun({
          text: trimMultipleSpaces(node.textContent),
          bold: node.tagName.toLowerCase() === "strong",
          italics: node.tagName.toLowerCase() === "i",
        })
      );
    }
  });

  return elements;
}

export default DocumentCreator;
