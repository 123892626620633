import {
  createAsyncThunk,
  createSlice,
  //  createAsyncThunk
} from "@reduxjs/toolkit";
import axios from "axios";
import api from "../server/api";
import { act } from "@testing-library/react";
import { cleanParagraphPath } from "../helpers/formatStringFuncs";
// import axios from "axios";

const initialState = {
  loading: false,
  error: false,
  emendaments: [],
  formattedEmendaments: [],
  sectionsChanged: [],
  sectionsPremised: [],
  sectionsAdded: [],
  sectionsRemoved: [],
  emendamentSaved: false,
  emendamentDeleted: false,
  savedEmendaments: null,
  conseguentementeActive: false,
};

const emendamentsSlice = createSlice({
  name: "emendaments",
  initialState,
  reducers: {
    addEmendaments: (state, action) => {
      state.emendaments = [...state.emendaments, ...action.payload];
    },

    setFormattedEmendaments: (state, action) => {
      state.formattedEmendaments = action.payload;
    },

    resetEmendaments: (state) => {
      state.emendaments = [];
      state.formattedEmendaments = [];
      state.sectionsChanged = [];
      state.sectionsPremised = [];
      state.sectionsAdded = [];
      state.sectionsRemoved = [];
      state.emendamentSaved = false;
      state.emendamentDeleted = false;
      state.savedEmendaments = null;
      state.conseguentementeActive = false;
    },
    removeEmendament: (state, action) => {
      state.emendaments = state.emendaments.filter((emendament) => {
        let formattedSection = emendament.section.split("Articolo");
        if (formattedSection.length > 1) {
          formattedSection = "Articolo" + formattedSection[1];
        } else {
          // Gestire il caso in cui la sezione non inizia con "Articolo"
          formattedSection = emendament.section;
        }

        let formattedActionSection = action.payload.section.split("Articolo");
        if (formattedActionSection.length > 1) {
          formattedActionSection = "Articolo" + formattedActionSection[1];
        } else {
          // Gestire il caso in cui la sezione non inizia con "Articolo"
          formattedActionSection = action.payload.section;
        }

        const isTextDifferent =
          emendament.emendament[0].replace(
            /<(?!\/?i(?:\s+[^>]*)?>)[^>]*>/gm,
            ""
          ) !== action.payload.text;
        const isSectionDifferent = formattedSection !== formattedActionSection;

        // Mantenere l'emendamento solo se sia il testo che la sezione sono diversi
        return isTextDifferent || isSectionDifferent;
      });
    },
    addChangedSection: (state, action) => {
      state.sectionsChanged = [
        ...state.sectionsChanged,
        {
          section: action.payload.section,
          text: action.payload.text,
        },
      ];
    },
    removeChangedSection: (state, action) => {
      state.sectionsChanged = state.sectionsChanged.filter(
        (section) => section.section !== action.payload.section
      );
    },

    addPremiseSection: (state, action) => {
      state.sectionsPremised = [
        ...state.sectionsPremised,
        {
          section: action.payload.section,
          text: action.payload.text,
        },
      ];
    },
    removePremiseSection: (state, action) => {
      state.sectionsPremised = state.sectionsPremised.filter(
        (section) => section.section !== action.payload.section
      );
    },

    addAddedSection: (state, action) => {
      state.sectionsAdded = [
        ...state.sectionsAdded,
        {
          section: action.payload.section,
          text: action.payload.text,
        },
      ];
    },

    removeAddedSection: (state, action) => {
      state.sectionsAdded = state.sectionsAdded.filter(
        (section) => section.section !== action.payload.section
      );
    },

    addRemovedSection: (state, action) => {
      state.sectionsRemoved = [
        ...state.sectionsRemoved,
        {
          section: action.payload.section,
          text: action.payload.text,
        },
      ];
    },

    removeRemovedSection: (state, action) => {
      state.sectionsRemoved = state.sectionsRemoved.filter(
        (section) => section.section !== action.payload.section
      );
    },
    setConseguentementeActive: (state, action) => {
      state.conseguentementeActive = action.payload;
    },
    addConseguentementeText: (state, action) => {
      const emendamentsToAddText = action.payload.emendamentsToAddText;
      const originalEmendament = action.payload.originalEmendament;
      const originalEmendamentText = originalEmendament.emendament[0].replace(
        /<(?!\/?i(?:\s+[^>]*)?>)[^>]*>/gm,
        ""
      );
      // trovare l'emendamento originale
      const foundEmendament = state.emendaments.find((emendament) => {
        const emendamentText = emendament.emendament[0].replace(
          /<[^>]*>?/gm,
          ""
        );

        return emendamentText.includes(originalEmendamentText);
      });

      // trovare l'indice dell'emendamento originale
      const originalEmendamentIndex = state.emendaments.findIndex(
        (emendament) => {
          const emendamentText = emendament.emendament[0].replace(
            /<(?!\/?i(?:\s+[^>]*)?>)[^>]*>/gm,
            ""
          );
          return emendamentText.includes(originalEmendamentText);
        }
      );

      // creare nuovo emendamente con conseguentemente aggiunto

      const newEmendament = {
        ...foundEmendament,
        emendament: [
          foundEmendament.emendament[0] +
            `<b>  Conseguentemente,<b> ` +
            `<p>${emendamentsToAddText}</p>`,
        ],
      };

      // sostituire l'emendamento originale con l'emendamento modificato
      state.emendaments[originalEmendamentIndex] = newEmendament;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(saveEmendament.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(saveEmendament.fulfilled, (state, action) => {
        state.loading = false;
        state.emendamentSaved = true;

        if (!state.savedEmendaments) {
          state.savedEmendaments = {};
        }

        // Check if the pdlId exists in savedEmendaments
        if (!state.savedEmendaments[action.payload.pdlId]) {
          // If pdlId is new, initialize it as an empty array
          state.savedEmendaments[action.payload.pdlId] = [];
        }

        // Now add the new emendament to the array for the pdlId
        state.savedEmendaments = {
          ...state.savedEmendaments,
          [action.payload.pdlId]: [
            ...state.savedEmendaments[action.payload.pdlId],
            action.payload,
          ],
        };

        state.emendaments = [...state.emendaments, action.payload];
      })
      .addCase(saveEmendament.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSavedEmendamentsByUserId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSavedEmendamentsByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.savedEmendaments = action.payload;
      })
      .addCase(getSavedEmendamentsByUserId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSinglePdlEmendamentsByUserId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSinglePdlEmendamentsByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.emendaments = action.payload;
      })
      .addCase(getSinglePdlEmendamentsByUserId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateEmendament.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateEmendament.fulfilled, (state, action) => {
        state.loading = false;
        state.emendamentSaved = true;

        const pdlId = action.payload.pdlId;
        if (!state.savedEmendaments) {
          state.savedEmendaments = {};
        }

        const emendamentsForPdl = state.savedEmendaments[pdlId];

        // Check if the pdlId exists in savedEmendaments
        if (emendamentsForPdl) {
          const updatedEmendaments = {
            ...state.savedEmendaments,
            [pdlId]: emendamentsForPdl.map((emendament) => {
              if (emendament.id === action.payload.id) {
                return action.payload;
              }
              return emendament;
            }),
          };
          state.savedEmendaments = updatedEmendaments;
        } else {
          // If pdlId is new, initialize it with the new emendament
          state.savedEmendaments[pdlId] = [action.payload];
        }

        // Update the emendament in the global list
        const updatedEmendaments = state.emendaments.map((emendament) => {
          if (emendament.id === action.payload.id) {
            return action.payload;
          }
          return emendament;
        });
        state.emendaments = updatedEmendaments;
      })
      .addCase(updateEmendament.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteEmendament.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteEmendament.fulfilled, (state, action) => {
        state.loading = false;
        state.emendamentDeleted = true;

        const pdlId = action.payload.pdlId;

        if (!state.savedEmendaments) {
          state.savedEmendaments = {};
        }
        // rimozione dell'emendamento da lista globale
        if (Array.isArray(state.savedEmendaments[pdlId])) {
          const updatedEmendaments = {
            ...state.savedEmendaments,
            [pdlId]: state.savedEmendaments[pdlId].filter(
              (emendament) => emendament.id !== action.payload.id
            ),
          };
          state.savedEmendaments = updatedEmendaments;
        }

        // rimozione dal recap
        state.emendaments = state.emendaments.filter(
          (emendament) => emendament.id !== action.payload.id
        );
      })

      .addCase(deleteEmendament.rejected, (state, action) => {
        console.log("error", action.error.message);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const saveEmendament = createAsyncThunk(
  "emendamentsSlice/saveEmendament",
  async (emendamentRequest) => {
    const { url, method, headers, data } =
      api.saveEmendament(emendamentRequest);
    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    return response.data;
  }
);

export const getSavedEmendamentsByUserId = createAsyncThunk(
  "emendamentsSlice/getSavedEmendamentsByUserId",
  async (userId) => {
    const { url, method, headers } = api.getSavedEmendamentsByUserId(userId);
    const response = await axios({
      url,
      method,
      headers,
    });

    return response.data;
  }
);

export const getSinglePdlEmendamentsByUserId = createAsyncThunk(
  "emendamentsSlice/getSinglePdlEmendamentsByUserId",
  async ({ userId, pdlId }) => {
    const { url, method, headers } = api.getSinglePdlEmendamentsByUserId(
      userId,
      pdlId
    );
    const response = await axios({
      url,
      method,
      headers,
    });

    return response.data;
  }
);

export const updateEmendament = createAsyncThunk(
  "emendamentsSlice/updateEmendament",
  async ({ editingEmendamentId, emendamentData }) => {
    console.log("editingEmendamentId", editingEmendamentId);
    const { url, method, headers, data } = api.updateEmendament(
      editingEmendamentId,
      emendamentData
    );

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    return response.data;
  }
);

export const deleteEmendament = createAsyncThunk(
  "emendamentsSlice/deleteEmendament",
  async (emendamentId) => {
    const { url, method, headers } = api.deleteEmendament(emendamentId);
    const response = await axios({
      url,
      method,
      headers,
    });
    return response.data;
  }
);

export const {
  addEmendaments,
  setFormattedEmendaments,
  removeEmendament,
  addChangedSection,
  removeChangedSection,
  addPremiseSection,
  removePremiseSection,
  addAddedSection,
  removeAddedSection,
  addRemovedSection,
  removeRemovedSection,
  resetEmendaments,
  setConseguentementeActive,
  addConseguentementeText,
} = emendamentsSlice.actions;

export const { actions } = emendamentsSlice;
export default emendamentsSlice.reducer;
