import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../shared/Buttons";
import {
  FaCaretDown,
  FaCaretUp,
  FaDownload,
  FaFileSignature,
  FaTrashCan,
} from "react-icons/fa6";
import { styled } from "styled-components";
import DocumentCreator from "../../../helpers/DocGenerator";
import { saveAs } from "file-saver";
import { Packer } from "docx";
import moment from "moment";
import {
  deleteEmendament,
  updateEmendament,
} from "../../../features/emendamentsSlice";
import DeleteConfirmPopup from "./DeleteConfirmPopup";
import RelazionePopup from "./RelazionePopup";
import FirmatariPopup from "./FirmatariPopup";
import { Popup } from "../../../components/popup/Popup";

const StyledRequestCard = styled.div`
  position: relative;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 16px;
  margin-bottom: 10px;
`;

const EmendamentCard = ({ emendaments, pdlId }) => {
  const dispatch = useDispatch();
  const [isRequestCardOpen, setIsRequestCardOpen] = useState(true);
  const [formattedEmendaments, setFormattedEmendaments] = useState([]);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const [selectedEmendaments, setSelectedEmendaments] = useState([]);
  const [relazioneData, setRelazioneData] = useState("");
  const [isRelazionePopupOpen, setIsRelazionePopupOpen] = useState(false);
  const [
    isDeletedRelazioneConfirmPopupOpen,
    setIsDeletedRelazioneConfirmPopupOpen,
  ] = useState(false);
  const [
    isDeletedFirmatariConfirmPopupOpen,
    setIsDeletedFirmatariConfirmPopupOpen,
  ] = useState(false);
  const [isFirmatariPopupOpen, setIsFirmatariPopupOpen] = useState(false);
  const [editingEmendamentId, setEditingEmendamentId] = useState(null);
  const [deletingEmendamentIds, setDeletingEmendamentIds] = useState([]);
  const parlamentSection = useSelector(
    (state) => state.account.parlamentSection
  );

  const handleCheckboxChange = (emendamentId, isChecked) => {
    if (isChecked) {
      setSelectedEmendaments((prevSelected) => [...prevSelected, emendamentId]);
    } else {
      setSelectedEmendaments((prevSelected) =>
        prevSelected.filter((id) => id !== emendamentId)
      );
    }
  };

  const handleSelectAllEmendamentsChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const allEmendamentIds = formattedEmendaments.flatMap((section) =>
        section.emendaments.map((emendament) => emendament.id)
      );
      setSelectedEmendaments(allEmendamentIds);
    } else {
      setSelectedEmendaments([]);
    }
  };

  useEffect(() => {
    const formatSection = (section) => {
      let articleNumber = section.split("Articolo");
      articleNumber = articleNumber[1].split(" ")[1];
      return `Articolo ${articleNumber}`;
    };

    const stripHtml = (html) =>
      html.replace(/<(?!\/?i(?:\s+[^>]*)?>)[^>]*>/gm, "");

    const formattedEmendaments = emendaments.map((emendament) => ({
      section: formatSection(emendament.section),
      emendamentText: stripHtml(emendament.emendament),
      id: emendament.id,
      relationText: emendament.relationText,
      firmatari: emendament.firmatari,
    }));

    const uniqueEmendaments = formattedEmendaments.reduce((acc, current) => {
      const { section, emendamentText, id, relationText, firmatari } = current;
      if (!acc[section]) {
        acc[section] = {
          section,
          emendaments: [{ emendamentText, id, relationText, firmatari }],
        };
      } else {
        acc[section].emendaments.push({
          emendamentText,
          id,
          relationText,
          firmatari,
        });
      }
      return acc;
    }, {});

    const mergedEmendaments = Object.values(uniqueEmendaments);

    mergedEmendaments.sort((a, b) => {
      const aSectionNumber = a.section.match(/\d+/)[0];
      const bSectionNumber = b.section.match(/\d+/)[0];
      return aSectionNumber - bSectionNumber;
    });

    setFormattedEmendaments(mergedEmendaments);
  }, [emendaments]);

  const generate = () => {
    // get full emendament from emendaments array
    const selectedEmendamentsData = emendaments.filter((emendament) =>
      selectedEmendaments.includes(emendament.id)
    );

    // check if all emendaments have firmatari
    const allEmendamentsHaveFirmatari = selectedEmendamentsData.every(
      (emendament) => emendament.firmatari?.length > 0
    );

    if (!allEmendamentsHaveFirmatari) {
      alert("Tutti gli emendamenti devono avere almeno un firmatario");
      return;
    }

    const timestamp = moment().format("DD-MM-YYYY-HH-mm-ss");

    const documentCreator = new DocumentCreator();
    const doc = documentCreator.create([selectedEmendamentsData]);

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${pdlId}_gruppo_${timestamp}.docx`);
    });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setRelazioneData(data);
  };

  const handleUpdateEmendament = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(relazioneData, "text/html");
    // Estrai solo il testo
    const text = doc.body.textContent || "";
    dispatch(
      updateEmendament({
        editingEmendamentId,
        emendamentData: { relationText: text },
      })
    );
    setIsRelazionePopupOpen(false);
    setEditingEmendamentId(null);
  };

  const handleUpdateFirmatari = (newFirmatari) => {
    selectedEmendaments.forEach((emendamentId) => {
      // find emendament
      const emendamentToUpdate = emendaments.find(
        (emendament) => emendament.id === emendamentId
      );

      if (emendamentToUpdate) {
        // new set firmatari
        const currentFirmatari = emendamentToUpdate.firmatari || [];
        const updatedFirmatari = Array.from(
          new Set([...currentFirmatari, ...newFirmatari])
        );
        dispatch(
          updateEmendament({
            editingEmendamentId: emendamentId,
            emendamentData: { firmatari: updatedFirmatari },
          })
        );
      }
    });
    setIsFirmatariPopupOpen(false);
  };

  const handleDeleteRelation = (editor) => {
    dispatch(
      updateEmendament({
        editingEmendamentId,
        emendamentData: { relationText: "" },
      })
    );
    setIsRelazionePopupOpen(false);
    setEditingEmendamentId(null);
    setIsDeletedRelazioneConfirmPopupOpen(false);
  };

  const handleDeleteFirmatari = () => {
    selectedEmendaments.forEach((emendamentId) => {
      dispatch(
        updateEmendament({
          editingEmendamentId: emendamentId,
          emendamentData: { firmatari: [] },
        })
      );
    });
    setIsDeletedFirmatariConfirmPopupOpen(false);
  };

  const hasEmendamentsFirmatari = () => {
    // get corrisponding emendaments from ID in selectedEmendaments
    const selectedFullEmendaments = emendaments.filter((emendament) =>
      selectedEmendaments.includes(emendament.id)
    );

    // check if al least one of this corr. has some firmatari
    return selectedFullEmendaments.some(
      (emendament) => emendament.firmatari && emendament.firmatari.length > 0
    );
  };

  const handleCancelEmendaments = (idsArray) => {
    idsArray.forEach((id) => {
      dispatch(deleteEmendament(id));
    });
    setIsCancelPopupOpen(false);
    setSelectedEmendaments([]);
  };

  return (
    <StyledRequestCard>
      {!!isCancelPopupOpen && (
        <Popup cardWidth="35%">
          <div className="flex-column justify-center align-center">
            <h3
              style={{
                fontWeight: 600,
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              {deletingEmendamentIds.length > 1
                ? " SICURO DI VOLER ELIMINARE GLI EMENDAMENTI SELEZIONATI?"
                : "SICURO DI VOLER ELIMINARE L'EMENDAMENTO?"}
            </h3>
            <p>
              {deletingEmendamentIds.length > 1
                ? "Tutti gli emendamenti selezionati verranno eliminati. Non sarà possibile recuperarli."
                : "Non sarà possibile recuperarlo."}
            </p>
            <div className="flex-row align-center justify-between w-100 mt-2">
              <Button handleclick={() => setIsCancelPopupOpen(false)}>
                Annulla
              </Button>
              <Button
                backgroundColor={"var(--danger)"}
                handleclick={() => {
                  handleCancelEmendaments(deletingEmendamentIds);
                }}
              >
                Elimina
              </Button>
            </div>
          </div>
        </Popup>
      )}
      {!!isFirmatariPopupOpen && (
        <FirmatariPopup
          setIsFirmatariPopupOpen={setIsFirmatariPopupOpen}
          handleUpdateFirmatari={handleUpdateFirmatari}
          hasEmendamentsFirmatari={hasEmendamentsFirmatari()}
        />
      )}
      {!!isDeletedFirmatariConfirmPopupOpen && (
        <DeleteConfirmPopup
          handleDelete={handleDeleteFirmatari}
          setIsDeletedConfirmPopupOpen={setIsDeletedFirmatariConfirmPopupOpen}
          setEditingEmendamentId={setEditingEmendamentId}
          text="SICURO DI VOLER ELIMINARE I FIRMATARI?"
          subText={`I firmatari verranno eliminati. Non sarà possibile recuperarli.`}
        />
      )}
      {!!isRelazionePopupOpen && (
        <>
          {!!isDeletedRelazioneConfirmPopupOpen ? (
            <DeleteConfirmPopup
              handleDelete={handleDeleteRelation}
              setIsDeletedConfirmPopupOpen={
                setIsDeletedRelazioneConfirmPopupOpen
              }
              setIsRelazionePopupOpen={setIsRelazionePopupOpen}
              setEditingEmendamentId={setEditingEmendamentId}
              text="SICURO DI VOLER ELIMINARE LA RELAZIONE?"
              subText="La relazione verrà eliminata. Non sarà possibile recuperarla."
            />
          ) : (
            <RelazionePopup
              handleUpdateEmendament={handleUpdateEmendament}
              setIsRelazionePopupOpen={setIsRelazionePopupOpen}
              relazioneData={relazioneData}
              setRelazioneData={setRelazioneData}
              handleEditorChange={handleEditorChange}
              editingEmendamentId={editingEmendamentId}
              setIsDeletedConfirmPopupOpen={
                setIsDeletedRelazioneConfirmPopupOpen
              }
              setEditingEmendamentId={setEditingEmendamentId}
            />
          )}
        </>
      )}
      <div className="flex-row justify-between align-center">
        <div>
          <p
            style={{
              fontWeight: 600,
            }}
          >
            {parlamentSection === "camera"
              ? `Emendamenti all'Atto Camera${pdlId}`
              : `Emendamenti all'Atto ${pdlId}`}
          </p>
          {!!isRequestCardOpen ? (
            <div
              className="flex-row justify-between align-center"
              style={{
                marginTop: "10px",
                width: 80,
              }}
              onClick={() => {
                setIsRequestCardOpen(!isRequestCardOpen);
                setSelectedEmendaments([]);
              }}
            >
              <p
                style={{
                  color: "var(--primary)",
                }}
              >
                Nascondi
              </p>
              <FaCaretUp
                style={{
                  marginLeft: "10px",
                  color: "var(--primary)",
                }}
              />
            </div>
          ) : (
            <div
              className="flex-row justify-between align-center"
              style={{
                marginTop: "10px",
                width: 80,
              }}
              onClick={() => setIsRequestCardOpen(!isRequestCardOpen)}
            >
              <p
                style={{
                  color: "var(--primary)",
                }}
              >
                Espandi
              </p>
              <FaCaretDown
                style={{
                  marginLeft: "10px",
                  color: "var(--primary)",
                }}
              />
            </div>
          )}
        </div>
        <div className="flex-row justify-between align-center">
          {
            // check if al least one of selected Emendament has a firmatario
            hasEmendamentsFirmatari() ? (
              <div className="flex-row justify-between align-center">
                <Button
                  disabled={selectedEmendaments.length === 0}
                  width={"230px"}
                  maxWidth={"230px"}
                  handleclick={() => {
                    setIsFirmatariPopupOpen(true);
                  }}
                  backgroundColor="var(--button)"
                >
                  Aggiungi firmatari
                  <FaFileSignature
                    color="#fff"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </Button>
                <Button
                  disabled={selectedEmendaments.length === 0}
                  width={"230px"}
                  maxWidth={"230px"}
                  handleclick={() => {
                    setIsDeletedFirmatariConfirmPopupOpen(true);
                  }}
                  style={{
                    marginLeft: "10px",
                  }}
                  backgroundColor="var(--danger)"
                >
                  Elimina firmatari
                  <FaFileSignature
                    color="#fff"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </Button>
              </div>
            ) : (
              <Button
                disabled={selectedEmendaments.length === 0}
                width={"230px"}
                maxWidth={"230px"}
                handleclick={() => {
                  setIsFirmatariPopupOpen(true);
                }}
                backgroundColor="var(--button)"
              >
                Inserisci firmatari
                <FaFileSignature
                  color="#fff"
                  style={{
                    marginLeft: "10px",
                  }}
                />
              </Button>
            )
          }
          <Button
            backgroundColor="var(--button)"
            width={"230px"}
            maxWidth={"230px"}
            style={{
              marginLeft: "10px",
            }}
            disabled={selectedEmendaments.length === 0}
            handleclick={() => {
              generate();
            }}
            /*    backgroundColor="var(--danger)" */
          >
            Scarica selezionati
            <FaDownload
              color="#fff"
              style={{
                marginLeft: "10px",
              }}
            />
          </Button>
          <Button
            width={"230px"}
            maxWidth={"230px"}
            style={{
              marginLeft: "10px",
            }}
            disabled={selectedEmendaments.length === 0}
            handleclick={() => {
              setIsCancelPopupOpen(true);
              setDeletingEmendamentIds(selectedEmendaments);
            }}
            backgroundColor="var(--danger)"
          >
            Elimina selezionati
            <FaTrashCan
              style={{
                marginLeft: "10px",
              }}
              color="#fff"
            />
          </Button>
        </div>
      </div>
      {isRequestCardOpen && (
        <>
          <div className="select-all-checkbox">
            <input
              style={{
                marginRight: "12px",
                marginTop: "16px",
              }}
              type="checkbox"
              checked={formattedEmendaments.every((section) =>
                section.emendaments.every((emendament) =>
                  selectedEmendaments.includes(emendament.id)
                )
              )}
              onChange={handleSelectAllEmendamentsChange}
            />
            Seleziona tutti gli emendamenti
          </div>
          {formattedEmendaments.map((emendament, i) => {
            return (
              <div
                key={i}
                style={{
                  position: "relative",
                  borderBottom: "6px double #bbb",
                  padding: "10px 0px",
                  marginBottom: "4px",
                }}
              >
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: "1.1rem",
                    marginBottom: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  {emendament.section}
                </h3>

                {emendament.emendaments.length > 0 &&
                  emendament.emendaments.map((singleEmendament, i) => {
                    // TEMPORANEO
                    // se c'è conseguentemente nel testo, butto a capo sia prima che dopo

                    const formattedText =
                      singleEmendament.emendamentText.replace(
                        /conseguentemente,/gi,
                        "<br/>Conseguentemente,<br/>"
                      );
                    return (
                      <div
                        key={`${i}-${singleEmendament.id}`}
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "8px 0px",
                          width: "100%",
                          borderBottom:
                            i === emendament.emendaments.length - 1
                              ? "none"
                              : "1px solid #ccc",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedEmendaments.includes(
                            singleEmendament.id
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(
                              singleEmendament.id,
                              e.target.checked
                            )
                          }
                          style={{ marginRight: "12px" }}
                        />
                        <div
                          style={{
                            flex: 1,
                            wordBreak: "break-all",
                            marginRight: "6px",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: formattedText,
                            }}
                          />
                          {singleEmendament.firmatari?.length > 0 && (
                            <div>
                              <b>Firmatari:</b>
                              <span>
                                {singleEmendament.firmatari.map(
                                  (firmatario, index) => (
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {firmatario}
                                      {index <
                                        singleEmendament.firmatari.length - 1 &&
                                        ","}
                                    </span>
                                  )
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="flex-row">
                          <Button
                            width={"150px"}
                            handleclick={() => {
                              setIsRelazionePopupOpen(true);
                              setRelazioneData(singleEmendament.relationText);
                              setEditingEmendamentId(singleEmendament.id);
                            }}
                            backgroundColor="var(--button)"
                          >
                            {!!singleEmendament.relationText
                              ? "Modifica relazione"
                              : "Aggiungi relazione"}
                          </Button>
                          <Button
                            style={{
                              width: "32px",
                              marginLeft: "10px",
                            }}
                            handleclick={() => {
                              setIsCancelPopupOpen(true);
                              setDeletingEmendamentIds([singleEmendament.id]);
                            }}
                            backgroundColor="var(--danger)"
                          >
                            <FaTrashCan style={{}} color="#fff" />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </>
      )}
    </StyledRequestCard>
  );
};

export default EmendamentCard;
