const api = process.env.REACT_APP_API_URL;

export const API_ROOT_URL = api;

const getHeaders = () => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  return headers;
};

const getAuthHeaders = () => {
  const userAS = localStorage.getItem("user");
  let token = "";

  if (userAS) {
    const user = JSON.parse(userAS);
    token = user.authToken;
  }

  return {
    ...getHeaders(),
    Authorization: token,
  };
};

const requests = {
  // SESSIONS

  login: (credentials) => {
    return {
      url: `${API_ROOT_URL}/account`,
      method: "POST",
      headers: getHeaders(),
      data: credentials,
    };
  },

  getProfile: () => {
    return {
      url: `${API_ROOT_URL}/profile`,
      method: "GET",
      headers: getHeaders(),
    };
  },

  getAuthLink: () => {
    return {
      url: `${API_ROOT_URL}/auth/link-auth`,
      method: "GET",
      headers: getHeaders(),
    };
  },

  getAuthToken: (code) => {
    return {
      url: `${API_ROOT_URL}/auth/callback?code=${code}`,
      method: "GET",
      headers: getHeaders(),
    };
  },
  // EMENDAMENTS
  getSavedEmendamentsByUserId: (userId) => {
    return {
      url: `${API_ROOT_URL}/emendaments/user/${userId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  saveEmendament: (emendament) => {
    return {
      url: `${API_ROOT_URL}/emendaments/emendamento`,
      method: "POST",
      headers: getAuthHeaders(),
      data: emendament,
    };
  },

  getSinglePdlEmendamentsByUserId: (userId, pdlId) => {
    return {
      url: `${API_ROOT_URL}/emendaments/user/${userId}/pdl/${pdlId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  updateEmendament: (editingEmendamentId, emendamentData) => {
    return {
      url: `${API_ROOT_URL}/emendaments/emendamento/${editingEmendamentId}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: emendamentData,
    };
  },

  deleteEmendament: (emendamentId) => {
    return {
      url: `${API_ROOT_URL}/emendaments/emendamento/${emendamentId}`,
      method: "DELETE",
      headers: getAuthHeaders(),
    };
  },
};

export default requests;
