import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api = process.env.REACT_APP_API_URL;
export const API_ROOT_URL = api;

const initialState = {
  loading: false,
  error: false,
  gruppiCamera: null,
  firmatariCamera: null,
  gruppiSenato: null,
  firmatariSenato: null,
};

const firmatariSlice = createSlice({
  name: "firmatari",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGruppiCamera.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getGruppiCamera.fulfilled, (state, action) => {
        state.loading = false;
        state.gruppiCamera = action.payload;
      })
      .addCase(getGruppiCamera.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFirmatariCamera.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFirmatariCamera.fulfilled, (state, action) => {
        state.loading = false;
        state.firmatariCamera = action.payload;
      })
      .addCase(getFirmatariCamera.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getGruppiSenato.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getGruppiSenato.fulfilled, (state, action) => {
        state.loading = false;
        state.gruppiSenato = action.payload;
      })
      .addCase(getGruppiSenato.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFirmatariSenato.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFirmatariSenato.fulfilled, (state, action) => {
        state.loading = false;
        state.firmatariSenato = action.payload;
      })
      .addCase(getFirmatariSenato.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getGruppiCamera = createAsyncThunk(
  "firmatariSlice/getGruppiCamera",
  async () => {
    const gruppiCamera = await axios({
      url: `${API_ROOT_URL}/firmatari/camera/gruppi`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    return gruppiCamera.data;
  }
);

export const getFirmatariCamera = createAsyncThunk(
  "firmatariSlice/getFirmatariCamera",
  async () => {
    const firmatariCamera = await axios({
      url: `${API_ROOT_URL}/firmatari/camera`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    return firmatariCamera.data;
  }
);

export const getGruppiSenato = createAsyncThunk(
  "firmatariSlice/getGruppiSenato",
  async () => {
    const gruppiSenato = await axios({
      url: `${API_ROOT_URL}/firmatari/senato`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    return gruppiSenato.data;
  }
);

export const getFirmatariSenato = createAsyncThunk(
  "firmatariSlice/getFirmatariSenato",
  async () => {
    const firmatariSenato = await axios({
      url: `${API_ROOT_URL}/firmatari/senato`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    return firmatariSenato.data;
  }
);

export const { actions } = firmatariSlice;
export default firmatariSlice.reducer;
