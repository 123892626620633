import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React from "react";
import { Popup } from "../../../components/popup/Popup";
import { Button } from "../../../shared/Buttons";

const RelazionePopup = ({
  relazioneData,
  setIsRelazionePopupOpen,
  setEditingEmendamentId,
  handleEditorChange,
  handleUpdateEmendament,
  setIsDeletedConfirmPopupOpen,
}) => {
  return (
    <Popup cardWidth="75%" height="80%">
      <div className="no-drag">
        <div className="flex-column justify-center align-center editor-enabled">
          <h3
            style={{
              fontWeight: 600,
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            RELAZIONE
          </h3>
          <div
            style={{
              overflow: "auto",
              maxHeight: "60vh",
              width: "100%",
            }}
          >
            <CKEditor
              config={{
                toolbar: [],
              }}
              editor={ClassicEditor}
              data={relazioneData}
              onChange={handleEditorChange}
            />
          </div>
          <div
            className="flex-row justify-between"
            style={{
              width: "100%",
            }}
          >
            <Button
              backgroundColor="var(--danger)"
              style={{
                marginTop: "20px",
              }}
              handleclick={() => {
                setIsRelazionePopupOpen(false);
                setEditingEmendamentId(null);
              }}
            >
              <span>Annulla</span>
            </Button>

            {!!relazioneData && (
              <Button
                width={"230px"}
                maxWidth={"230px"}
                backgroundColor="var(--danger)"
                style={{
                  marginTop: "20px",
                }}
                handleclick={() => {
                  setIsDeletedConfirmPopupOpen(true);
                }}
              >
                <span>Elimina relazione</span>
              </Button>
            )}
            {!!true ? (
              <Button
                disabled={!relazioneData}
                style={{
                  marginTop: "20px",
                }}
                handleclick={handleUpdateEmendament}
              >
                <span>Salva</span>
              </Button>
            ) : (
              <Button
                style={{
                  marginTop: "20px",
                }}
                handleclick={() => {
                  // setIsEditing(true);
                }}
              >
                <span>Modifica</span>
              </Button>
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default RelazionePopup;
