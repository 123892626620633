import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Avatar from "../../../components/avatar/Avatar";
import { IconWithTooltip } from "../../../shared/IconWithTooltip";
import { Button, ButtonExternalLink } from "../../../shared/Buttons";
import LogoSenato from "../../../assets/logo_senato.svg";
import { Popup } from "../../../components/popup/Popup";

const SelectHeader = () => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.account.profile);
  const authLink = useSelector((state) => state.account.authLink);
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const parlamentSection = useSelector(
    (state) => state.account.parlamentSection
  );
  return (
    <div
      className="flex-row justify-between align-center"
      style={{
        backgroundColor: "var(--primary)",
        width: "100%",
        boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
      }}
    >
      {parlamentSection === "camera" ? (
        <div style={{ flex: 1 }}></div>
      ) : (
        <div style={{ flex: 1 }}>
          <img
            src={LogoSenato}
            alt="logo senato"
            style={{
              width: "150px",
              height: "auto",
              paddingTop: "4px",
              marginLeft: "20px",
            }}
          />
        </div>
      )}
      <NavLink
        style={{
          flex: 1,
          textAlign: "center",
        }}
        to="/"
      >
        <h1
          style={{
            color: "white",
            fontWeight: 600,
            letterSpacing: "2px",
          }}
        >
          Editor Emendamenti
        </h1>
      </NavLink>
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        {!!profile ? (
          <div className="flex-row justify-between align-center">
            {!!parlamentSection && (
              <NavLink
                to="/emendaments-list"
                style={{
                  color: "white",
                  fontWeight: 600,
                  padding: "6px 8px",
                  letterSpacing: "2px",
                  cursor: "pointer",
                  border: "2px solid #fff",
                  borderRadius: "4px",
                }}
              >
                I miei emendamenti
              </NavLink>
            )}
            {!!profile && <Avatar profile={profile} />}
          </div>
        ) : (
          <>
            {parlamentSection === "senato" ? (
              <div className="flex-row justify-between align-center">
                <ButtonExternalLink
                  style={{
                    color: "white",
                    fontWeight: 600,
                    padding: "6px 8px",
                    letterSpacing: "2px",
                    cursor: "pointer",
                    border: "2px solid #fff",
                    marginRight: "12px",
                    backgroundColor: "var(--primary)",
                  }}
                  to={authLink}
                >
                  Login
                </ButtonExternalLink>
              </div>
            ) : (
              <div></div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SelectHeader;
