import React, { useState, useEffect, useRef } from "react";
import { Popup } from "../../components/popup/Popup";
import "../../styles/general.scss";
import Editor from "../../components/editor/Editor";
import { Button } from "../../shared/Buttons";
/* import Tag from "./components/tag/Tag"; */
import { cleanParagraphPath } from "../../helpers/formatStringFuncs";

import { useDispatch, useSelector } from "react-redux";
import {
  addConseguentementeText,
  addEmendaments,
  getSinglePdlEmendamentsByUserId,
  resetEmendaments,
  setConseguentementeActive,
  setFormattedEmendaments,
} from "../../features/emendamentsSlice";

import { IconWithTooltip } from "../../shared/IconWithTooltip";
import { renderXhtmlNode } from "./components/RenderXhtmlNode";
import { toggleTagsVisibility } from "../../features/layoutSlice";
import { resetDdl } from "../../features/ddlSlice";
import { getRelativeOffsetTop } from "../../helpers/LayoutFuncs";
import { useNavigate } from "react-router-dom";
import Avatar from "../../components/avatar/Avatar";

const XhtmlViewer = ({
  xhtmlData,
  setXhtmlData,
  selectedSection,
  setSelectedSection,
  viewerWidth,
  clickedSection,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTagsVisibile = useSelector((state) => state.layout.isTagsVisible);
  /*   const conseguentementeActive = useSelector(
    (state) => state.emendaments.conseguentementeActive
  ); */
  const ddlID = useSelector((state) => state.ddl.ddlID);
  const profile = useSelector((state) => state.account.profile);
  const reduxEmendaments = useSelector(
    (state) => state.emendaments.emendaments
  );
  const viewer = useRef(null);

  const [isEditorPopupOpen, setIsEditorPopupOpen] = useState(false);
  const [isExitPopupOpen, setIsExitPopupOpen] = useState(false);
  const [editorData, setEditorData] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [emendaments, setEmendaments] = useState([]);
  const [sectionChanged, setSectionChanged] = useState([]);
  const [paragraphPath, setParagraphPath] = useState("");
  const [editorAction, setEditorAction] = useState("");
  const [sectionType, setSectionType] = useState("");

  /*   const listedEmendaments = useSelector(
    (state) => state.emendaments.emendaments
  ); */

  // Funzione per determinare la sezione visibile
  const handleScroll = () => {
    const viewerElement = viewer.current;
    const bottomReached =
      viewerElement.scrollHeight - viewerElement.scrollTop ===
      viewerElement.clientHeight;

    // Ottieni tutte le sezioni
    const sections = viewer.current.querySelectorAll("[id]");
    let nearestSection = null;
    let minDistance = Infinity;

    // filter sections that contains art.
    const filteredSections = [...sections].filter((section) => {
      return section.id.includes("Articolo");
    });

    filteredSections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      const distance = Math.abs(rect.top - 120);

      if (distance < minDistance) {
        minDistance = distance;
        nearestSection = section.id;
      }
    });

    if (nearestSection !== null && nearestSection !== selectedSection) {
      setSelectedSection(nearestSection); // Aggiorna la sezione selezionata
    }

    // Se si è raggiunto il fondo della pagina, setta ultima sezione
    if (bottomReached) {
      // get last section
      const lastSection = filteredSections[filteredSections.length - 1];
      setSelectedSection(lastSection.id);
    }
  };

  useEffect(() => {
    if (reduxEmendaments && reduxEmendaments.length > 0) {
      const formattedEmendaments = reduxEmendaments.map((emendament) => {
        let formattedSection = emendament.section.split("Articolo");
        formattedSection = formattedSection[1].split(" ");

        // recap completo
        /*  formattedSection = "Articolo " + formattedSection; */

        // recap solo articolo

        formattedSection = "Articolo " + formattedSection[1];

        // // tolgo ultime due parole
        // formattedSection = formattedSection
        //   .split(" ")
        //   .slice(0, -2)
        //   .join(" ")
        //   .trim();

        let trimmedEmendaments = null;
        if (Array.isArray(emendament.emendament)) {
          trimmedEmendaments = emendament.emendament.map((text) =>
            text.replace(/<(?!\/?i(?:\s+[^>]*)?>)[^>]*>/gm, "")
          );
        } else if (typeof emendament.emendament === "string") {
          trimmedEmendaments = emendament.emendament.replace(
            /<(?!\/?i(?:\s+[^>]*)?>)[^>]*>/gm,
            ""
          );
        } else {
          console.log("emendament.emendament non è né un array né una stringa");
        }

        return {
          /*         title: formattedSection,
           */
          ...emendament,
          textSection: emendament.section,
          section: formattedSection,
          emendament: Array.isArray(emendament.emendament)
            ? trimmedEmendaments
            : [trimmedEmendaments],
        };
      });

      // eliminare copie di emendamenti
      const uniqueEmendaments = [];
      formattedEmendaments.forEach((emendament) => {
        const index = uniqueEmendaments.findIndex(
          (uniqueEmendament) =>
            uniqueEmendament.emendament[0] === emendament.emendament[0] &&
            uniqueEmendament.section === emendament.section
        );
        if (index === -1) {
          uniqueEmendaments.push(emendament);
        }
      });

      // se ci sono emendamenti con la sezione uguale, unirli
      const mergedEmendaments = [];
      uniqueEmendaments.forEach((emendament) => {
        const index = mergedEmendaments.findIndex(
          (mergedEmendament) => mergedEmendament.section === emendament.section
        );

        if (index === -1) {
          // Se non esiste ancora un emendamento con questa sezione, aggiungi un nuovo oggetto
          mergedEmendaments.push({
            section: emendament.section,
            emendaments: [
              {
                id: emendament.id,
                textSection: emendament.textSection,
                emendament: emendament.emendament,
              },
            ],
          });
        } else {
          // Altrimenti, aggiungi l'emendamento corrente all'array esistente
          mergedEmendaments[index].emendaments.push({
            id: emendament.id,
            textSection: emendament.textSection,
            emendament: emendament.emendament,
          });
        }
      });
      // sort by section
      mergedEmendaments.sort((a, b) => {
        const aSection = a.section.split(" ").pop();
        const bSection = b.section.split(" ").pop();
        return aSection - bSection;
      });

      dispatch(setFormattedEmendaments(mergedEmendaments));
      console.log("mergedEmendaments", mergedEmendaments);
      return () => {
        dispatch(setFormattedEmendaments([]));
      };
    }
  }, [reduxEmendaments]);

  // Aggiungi l'event listener allo scroll
  useEffect(() => {
    const viewerElement = viewer.current;
    viewerElement.addEventListener("scroll", handleScroll);

    // Pulizia dell'event listener
    return () => {
      viewerElement.removeEventListener("scroll", handleScroll);
    };
  }, [selectedSection]);

  useEffect(() => {
    if (!!clickedSection && viewer.current) {
      // get element with id = selectedSection
      const sectionDiv = viewer.current.querySelector(
        `[id='${clickedSection.replace(/\./g, "\\.")}']`
      );
      if (!!sectionDiv) {
        const topOffset = getRelativeOffsetTop(sectionDiv, viewer.current);
        viewer.current.scrollTop = topOffset - 120;
      }
    }
  }, [clickedSection]);

  useEffect(() => {
    dispatch(
      getSinglePdlEmendamentsByUserId({
        pdlId: ddlID,
        userId: profile?.sub || 99,
      })
    );
  }, [profile, ddlID]);

  /*   const handleConfirmEmendaments = (emendaments) => {
    dispatch(addEmendaments([...emendaments]));
  };

  const handleConfirmConseguentemente = (
    originalEmendament,
    emendamentsToAdd
  ) => {
    const emendamentsToAddText = emendamentsToAdd[0].emendament[0].replace(
      /<[^>]*>?/gm,
      ""
    );
    dispatch(
      addConseguentementeText({
        originalEmendament,
        emendamentsToAddText,
      })
    );
  };
 */
  return (
    <div
      style={{
        /*    border: "1px solid", */
        backgroundColor: "var(--backgroundColor)",
        height: "calc(100vh - 75px)",
        width: viewerWidth,
      }}
    >
      <div
        style={{
          backgroundColor: "var(--primary)",
          width: "100%",
          boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "20px",
          minHeight: "48px",
        }}
      >
        <h2
          style={{
            color: "white",
            fontWeight: 600,
            padding: "0px 20px",
            fontSize: "1.5rem",
            letterSpacing: "2px",
          }}
        >
          {"Atto Camera " + ddlID}
        </h2>
        <div className="flex-row align-center">
          {!isTagsVisibile ? (
            <IconWithTooltip
              iconName="TbSchema"
              tooltipText="Mostra tag"
              position="left"
              handleclick={() => {
                dispatch(toggleTagsVisibility());
              }}
            />
          ) : (
            <IconWithTooltip
              iconName="TbSchemaOff"
              tooltipText="Nascondi tag"
              position="left"
              handleclick={() => {
                dispatch(toggleTagsVisibility());
              }}
            />
          )}
          <Button
            style={{
              width: "200px",
              maxWidth: "200px",
              color: "white",
              fontWeight: 600,
              padding: "6px 8px",
              letterSpacing: "2px",
              cursor: "pointer",
              border: "2px solid #fff",
              marginLeft: "12px",
              backgroundColor: "var(--primary)",
            }}
            handleclick={() => {
              setIsEditorPopupOpen(false);
              setIsEditing(false);
              setIsEdited(false);
              setParagraphPath("");
              setEmendaments([]);
              setSectionChanged([]);
              setXhtmlData(null);
              dispatch(resetEmendaments());
              dispatch(resetDdl());
              dispatch(setConseguentementeActive(false));
              setIsExitPopupOpen(false);
              navigate("/");
            }}
          >
            Elenco Provvedimenti
          </Button>
          {!profile && <Avatar profile={profile} marginLeft={24} />}
          {/*   <IconWithTooltip
            iconName="TbDoorExit"
            tooltipText="Esci dall'editor"
            position="left"
            marginLeft="12px"
            handleclick={() => setIsExitPopupOpen(true)}
          /> */}
        </div>
      </div>
      <div
        ref={viewer}
        style={{
          overflowY: "auto",
          padding: "20px",
          height: "calc(100vh - 75px - 48px)",
          width: viewerWidth,
        }}
      >
        {!!isExitPopupOpen && (
          <Popup cardWidth="35%">
            <div className="flex-column justify-center align-center">
              <h2>SICURO DI VOLER USCIRE?</h2>
              <p>Tutte le modifiche andranno perse.</p>
              <div className="flex-row align-center justify-between w-100 mt-2">
                <Button handleclick={() => setIsExitPopupOpen(false)}>
                  Annulla
                </Button>
                <Button
                  backgroundColor={"var(--danger)"}
                  handleclick={() => {
                    setIsEditorPopupOpen(false);
                    setIsEditing(false);
                    setIsEdited(false);
                    setParagraphPath("");
                    setEmendaments([]);
                    setSectionChanged([]);
                    setXhtmlData(null);
                    dispatch(resetEmendaments());
                    dispatch(resetDdl());
                    dispatch(setConseguentementeActive(false));
                    setIsExitPopupOpen(false);
                  }}
                >
                  Esci
                </Button>
              </div>
            </div>
          </Popup>
        )}
        {!!isEditorPopupOpen && (
          <Popup
            handleClose={() => {
              setIsEditorPopupOpen(false);
              setIsEditing(false);
              setIsEdited(false);
              setParagraphPath("");
            }}
            paddingCard={!!isEdited && "24px 32px 24px"}
          >
            <div className="no-drag">
              <Editor
                paragraphPath={cleanParagraphPath(paragraphPath)}
                editorData={editorData}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                setIsEdited={setIsEdited}
                setEmendaments={setEmendaments}
                setSectionChanged={setSectionChanged}
                setIsEditorPopupOpen={setIsEditorPopupOpen}
                editorAction={editorAction}
                sectionType={sectionType}
                isEdited={isEdited}
                emendaments={emendaments}
              />
            </div>
          </Popup>
        )}

        <div>
          {renderXhtmlNode(
            xhtmlData,
            setIsEditorPopupOpen,
            setEditorData,
            setEditorAction,
            isTagsVisibile,
            setParagraphPath,
            setIsEditing,
            setSectionType
          )}
        </div>
      </div>
    </div>
  );
};

export default XhtmlViewer;
