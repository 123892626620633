import * as TbIcons from "react-icons/tb";
import * as FiIcons from "react-icons/fi";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import styled from "styled-components";

const allIcons = { ...TbIcons, ...FiIcons, ...FaIcons, ...MdIcons, ...RiIcons };

const getPositionStyles = (position) => {
  switch (position) {
    case "left":
      return `
        right: 100%;
        top: 130%;
        transform: translateY(-50%);
      `;
    case "right":
      return `
        left: 100%;
        top: 130%;
        transform: translateY(-50%);
      `;
    case "fixed":
      return `
        left: 40px;
        top: 50px;
        transform: translateY(-50%);
      `;

    default:
      return `
        left: 100%;
        top: 130%;
        transform: translateY(-50%);
      `;
  }
};

const CustomTooltip = styled.div`
  display: none;
  position: ${({ position }) => (position === "fixed" ? "fixed" : "absolute")};
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  ${({ position }) => getPositionStyles(position)}
`;

const TooltipContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;

  &:hover > ${CustomTooltip} {
    display: block;
    opacity: 1;
  }
`;

export const IconWithTooltip = ({
  iconName,
  tooltipText,
  handleclick,
  marginLeft,
  marginRight,
  position = "left",
}) => {
  const IconComponent = allIcons[iconName];
  if (!IconComponent) return null;

  return (
    <TooltipContainer
      onClick={handleclick}
      style={{ marginLeft: marginLeft, marginRight: marginRight }}
    >
      <IconComponent color="#fff" size={24} />
      <CustomTooltip position={position}>{tooltipText}</CustomTooltip>
    </TooltipContainer>
  );
};
