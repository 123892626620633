import React from "react";
import { cleanParagraphPath } from "../../helpers/formatStringFuncs";
import ContextMenu from "./ContextMenu";
import Tag from "../tag/Tag";
import MoreIcon from "../../shared/MoreIcon";

const ContextMenuRubrica = ({
  setParagraphPath,
  setIsEditorPopupOpen,
  id,
  newPath,
  setEditorData,
  setIsEditing,
  setEditorAction,
  setSectionType,
}) => {
  return (
    <ContextMenu
      items={[
        {
          label: "Sostituisci rubrica",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath(
              "Sostituisci Rubrica dell'" + cleanParagraphPath(newPath)
            );
            setIsEditorPopupOpen(true);
            setIsEditing(true);
            setEditorAction("replace");
            setSectionType("rubrica");
          },
        },
      ]}
      backgroundColor={"var(--rubrica)"}
      id={`rubrica_${id}`}
    >
      <Tag backgroundColor={"var(--rubrica)"}>Rubrica</Tag>
      <MoreIcon color="var(--rubrica)" />
    </ContextMenu>
  );
};

export default ContextMenuRubrica;
