import {
  formatCommaLabel,
  formatLetterLabel,
  formatNumberLabel,
} from "./BulletTreeFunc";

export const renderXHTMLTree = (
  node,
  depth = 0,
  parentKey = "",
  path = "",
  setSelectedSection,
  expandedSections,
  toggleSection,
  selectedSection,
  setClickedSection
) => {
  // get all the ids from html node and add them to the path
  const ids = node.match(/id="art.([^"]*)"/g);

  const formattedIds = ids?.map((id) => {
    // Estrai il numero da id
    const number = id.match(/id="art.([^"]*)"/)[1];

    let currentSection = null;

    if (!!number) {
      currentSection = node
        ?.split(`id="art.${number}"`)[1]
        ?.split(`id="art.`)[0];
    } else {
      currentSection = node
        ?.split(`id="articolo.${number}"`)[1]
        ?.split(`id="articolo.`)[0];
    }

    // Estrai le etichette "comma"
    const commaLabels =
      currentSection?.match(
        /  (\d+\.)\s(?!\d)|&#8195;&#8194;(\d+\.)\s(?!\d)/g
      ) || [];

    const structuredLabels = commaLabels.map((commaLabel) => {
      // Estrai la sottosezione corrispondente a questa etichetta "comma"
      const commaSection = currentSection
        ?.split(commaLabel)[1]
        ?.split(/  (\d+\.)\s(?!\d)|&#8195;&#8194;(\d+\.)\s(?!\d)/)[0];

      // Estrai e organizza le etichette "lettera" e "numero"
      const letterLabels =
        commaSection.match(
          /  <i>([a-z])[)]|&#8195;&#8195;&#8194;<i>([a-z])[)]/g
        ) || [];
      const structuredLetterLabels = letterLabels.map((letterLabel) => {
        // Estrai la sottosezione corrispondente a questa etichetta "lettera"
        const letterSection = commaSection
          .split(letterLabel)[1]
          .split(/  <i>[a-z][)]|&#8195;&#8195;&#8194;<i>([a-z])[)]/)[0];

        // Estrai le etichette "numero" all'interno di questa sottosezione

        const numeroLabels =
          letterSection.match(
            /  (\d+)[)]|&#8195;&#8195;&#8195;&#8194;(\d+)[)]/g
          ) || [];

        return {
          letterLabel: letterLabel,
          numeroLabels: numeroLabels,
        };
      });

      return {
        commaLabel: commaLabel,
        letterLabels: structuredLetterLabels,
      };
    });

    return {
      id: `Articolo ${number}`,
      labels: structuredLabels,
    };
  });

  const handleCommaLabelClick = (e, formattedId, commaLabel) => {
    const formattedComma = formatCommaLabel(commaLabel);
    e.stopPropagation();
    /*  toggleSection(formattedId.id); */
    setSelectedSection(formattedId.id + " " + formattedComma);
    setClickedSection(formattedId.id + " " + formattedComma);
  };

  const handleLetterLabelClick = (e, formattedId, commaLabel, letterLabel) => {
    const formattedComma = formatCommaLabel(commaLabel);
    const formattedLetter = formatLetterLabel(letterLabel.letterLabel);
    e.stopPropagation();
    /*   toggleSection(formattedId.id); */
    setSelectedSection(
      formattedId.id + " " + formattedComma + " " + formattedLetter
    );
    setClickedSection(
      formattedId.id + " " + formattedComma + " " + formattedLetter
    );
  };

  const handleNumberLabelClick = (
    e,
    formattedId,
    commaLabel,
    letterLabel,
    numeroLabel
  ) => {
    const formattedComma = formatCommaLabel(commaLabel);
    const formattedLetter = formatLetterLabel(letterLabel.letterLabel);
    const formattedNumber = formatNumberLabel(numeroLabel);
    e.stopPropagation();
    /* toggleSection(formattedId.id); */
    setSelectedSection(
      formattedId.id +
        " " +
        formattedComma +
        " " +
        formattedLetter +
        " " +
        formattedNumber
    );
    console.log(
      "clicked",
      formattedId.id +
        " " +
        formattedComma +
        " " +
        formattedLetter +
        " " +
        formattedNumber
    );
    setClickedSection(
      formattedId.id +
        " " +
        formattedComma +
        " " +
        formattedLetter +
        " " +
        formattedNumber
    );
  };

  return (
    <ul>
      {formattedIds?.map((formattedId) => (
        <li key={formattedId.id}>
          <span
            onClick={() => {
              toggleSection(formattedId.id);
              setSelectedSection(formattedId.id);
              setClickedSection(formattedId.id);
            }}
            style={{
              cursor: "pointer",
              paddingLeft: `${6}px`,
              fontWeight: selectedSection?.startsWith(formattedId.id)
                ? "bold"
                : "500",
              color: selectedSection?.startsWith(formattedId.id)
                ? "var(--articolo)"
                : "var(--textColor)",
            }}
          >
            {formattedId.id}
          </span>
          {/*     {selectedSection?.startsWith(formattedId.id) && ( */}
          <ul
            style={{
              maxHeight: selectedSection?.startsWith(formattedId.id)
                ? "1000px"
                : "0",
              overflow: "hidden",
              transition: "max-height 1s ease-out",
            }}
          >
            {formattedId.labels?.map((label) => (
              <li key={label.commaLabel}>
                <span
                  onClick={(e) =>
                    handleCommaLabelClick(e, formattedId, label.commaLabel)
                  }
                  style={{
                    cursor: "pointer",
                    paddingLeft: `${12}px`,
                    fontWeight: selectedSection?.startsWith(
                      formattedId.id + " " + formatCommaLabel(label.commaLabel)
                    )
                      ? "bold"
                      : "500",
                    color: selectedSection?.startsWith(
                      formattedId.id + " " + formatCommaLabel(label.commaLabel)
                    )
                      ? "var(--comma)"
                      : "var(--textColor)",
                  }}
                >
                  {formatCommaLabel(label.commaLabel)}
                </span>
                {selectedSection?.startsWith(
                  formattedId.id + " " + formatCommaLabel(label.commaLabel)
                ) && (
                  <ul>
                    {label.letterLabels?.length > 0 &&
                      label?.letterLabels.map((letterLabel) => (
                        <li key={letterLabel}>
                          <span
                            onClick={(e) =>
                              handleLetterLabelClick(
                                e,
                                formattedId,
                                label.commaLabel,
                                letterLabel
                              )
                            }
                            style={{
                              cursor: "pointer",
                              paddingLeft: `${18}px`,
                              fontWeight: selectedSection?.startsWith(
                                formattedId.id +
                                  " " +
                                  formatCommaLabel(label.commaLabel) +
                                  " " +
                                  formatLetterLabel(letterLabel.letterLabel)
                              )
                                ? "bold"
                                : "500",
                              color: selectedSection?.startsWith(
                                formattedId.id +
                                  " " +
                                  formatCommaLabel(label.commaLabel) +
                                  " " +
                                  formatLetterLabel(letterLabel.letterLabel)
                              )
                                ? "var(--lettera)"
                                : "var(--textColor)",
                            }}
                          >
                            {formatLetterLabel(letterLabel.letterLabel)}
                          </span>
                          {selectedSection.startsWith(
                            formattedId.id +
                              " " +
                              formatCommaLabel(label.commaLabel) +
                              " " +
                              formatLetterLabel(letterLabel.letterLabel)
                          ) && (
                            <ul>
                              {letterLabel.numeroLabels?.length > 0 &&
                                letterLabel.numeroLabels?.map((numeroLabel) => (
                                  <li key={numeroLabel}>
                                    <span
                                      onClick={(e) =>
                                        handleNumberLabelClick(
                                          e,
                                          formattedId,
                                          label.commaLabel,
                                          letterLabel,
                                          numeroLabel
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        paddingLeft: `${24}px`,
                                        fontWeight: selectedSection?.startsWith(
                                          formattedId.id +
                                            " " +
                                            formatCommaLabel(label.commaLabel) +
                                            " " +
                                            formatLetterLabel(
                                              letterLabel.letterLabel
                                            ) +
                                            " " +
                                            formatNumberLabel(numeroLabel)
                                        )
                                          ? "bold"
                                          : "500",
                                        color: selectedSection?.startsWith(
                                          formattedId.id +
                                            " " +
                                            formatCommaLabel(label.commaLabel) +
                                            " " +
                                            formatLetterLabel(
                                              letterLabel.letterLabel
                                            ) +
                                            " " +
                                            formatNumberLabel(numeroLabel)
                                        )
                                          ? "var(--numero)"
                                          : "var(--textColor)",
                                      }}
                                    >
                                      {formatNumberLabel(numeroLabel)}
                                    </span>
                                  </li>
                                ))}
                            </ul>
                          )}
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          {/*    )} */}
        </li>
      ))}
    </ul>
  );
};
