import React, { useState, useEffect } from "react";
import { Popup } from "../../../components/popup/Popup";
import { Button } from "../../../shared/Buttons";
import Select from "react-select";
import { useSelector } from "react-redux";

const firmatari = [
  {
    id: "1",
    gruppo: "Gruppo Misto",
    nome: "Ferrari",
  },
  {
    id: "2",
    gruppo: "Gruppo Misto",
    nome: "Resca",
  },
  {
    id: "3",
    gruppo: "Gruppo A",
    nome: "Rossi",
  },
  {
    id: "4",
    gruppo: "Gruppo A",
    nome: "Marini",
  },
  {
    id: "5",
    gruppo: "Gruppo A",
    nome: "Polzelli",
  },
  {
    id: "6",
    gruppo: "Gruppo B",
    nome: "Ascani",
  },
  {
    id: "7",
    gruppo: "Gruppo B",
    nome: "Violati",
  },
  {
    id: "8",
    gruppo: "Gruppo B",
    nome: "Galli",
  },
  {
    id: "9",
    gruppo: "Gruppo B",
    nome: "De Rossi",
  },
];

const FirmatariPopup = ({
  setIsFirmatariPopupOpen,
  handleUpdateFirmatari,
  hasEmendamentsFirmatari,
}) => {
  const gruppiCamera = useSelector((state) => state.firmatari.gruppiCamera);
  const firmatariCamera = useSelector(
    (state) => state.firmatari.firmatariCamera
  );
  const gruppiSenato = useSelector((state) => state.firmatari.gruppiSenato);
  const firmatariSenato = useSelector(
    (state) => state.firmatari.firmatariSenato
  );
  const [singleFirmatarioOptions, setSingleFirmatarioOptions] = useState([]);
  const [groupsOptions, setGroupsOptions] = useState([]);
  const [selectedSingleFirmatari, setSelectedSingleFirmatari] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [totalFirmatari, setTotalFirmatari] = useState([]);
  const [selectKey, setSelectKey] = useState("");

  const lastSingleFirmatari = JSON.parse(
    localStorage.getItem("lastSingleFirmatari")
  );
  const lastGroups = JSON.parse(localStorage.getItem("lastGroups"));

  useEffect(() => {
    if (gruppiCamera?.length > 0) {
      const groups = gruppiCamera.map((gruppo) => {
        return {
          value: gruppo.idGruppo,
          label: gruppo.denominazioneUfficiale,
        };
      });
      setGroupsOptions(groups);
    } else {
      const groups = [];
      firmatari.forEach((firmatario) => {
        if (!groups.some((group) => group.value === firmatario.gruppo)) {
          groups.push({
            value: firmatario.gruppo,
            label: firmatario.gruppo,
            firmatari: [firmatario.nome],
          });
        } else {
          groups.forEach((group) => {
            if (group.value === firmatario.gruppo) {
              group.firmatari.push(firmatario.nome);
            }
          });
        }
      });

      setGroupsOptions(groups);
    }
  }, [gruppiCamera]);

  useEffect(() => {
    // Create options for single firmatari

    if (firmatariCamera?.membro?.length > 0) {
      const singleFirmatari = firmatariCamera.membro.map((firmatario) => {
        return {
          value: firmatario.cognomeNotorieta,
          label: firmatario.cognomeNotorieta,
          firmatari: [firmatario.cognomeNotorieta],
        };
      });
      setSingleFirmatarioOptions(singleFirmatari);
    }
  }, [firmatariCamera]);

  useEffect(() => {
    // Create options for single firmatari

    if (firmatariSenato?.length > 0) {
      const singleFirmatari = firmatariSenato.map((firmatario) => {
        return {
          value: firmatario.cognome,
          label: firmatario.cognome,
          firmatari: [firmatario.cognome],
        };
      });
      setSingleFirmatarioOptions(singleFirmatari);

      const groups = [];
      firmatariSenato.forEach((firmatario) => {
        if (!groups.some((group) => group.value === firmatario.dizGruppo)) {
          groups.push({
            value: firmatario.dizGruppo,
            label: firmatario.dizGruppo,
            firmatari: [firmatario.cognome],
          });
        } else {
          groups.forEach((group) => {
            if (group.value === firmatario.dizGruppo) {
              group.firmatari.push(firmatario.cognome);
            }
          });
        }
      });

      setGroupsOptions(groups);
    }
  }, [firmatariSenato]);

  const importLastSelection = (type) => {
    if (type === "singleFirmatari") {
      setSelectedSingleFirmatari(lastSingleFirmatari);
    } else if (type === "groups") {
      setSelectedGroups(lastGroups);
    }

    // Genera una nuova chiave dinamica per il componente Select
    const newKey = Math.random().toString();
    setSelectKey(newKey);
  };

  const handleSaveFirmatari = () => {
    //get all firmatari of the groups

    if (firmatariCamera?.membro?.length > 0) {
      const firmatariFromGroup = [];

      selectedGroups.map((gruppo) => {
        const firmatariGruppo = firmatariCamera.membro.filter(
          (firmatario) => firmatario.idGruppo === gruppo.value
        );
        const formattedFirmatariGruppo = firmatariGruppo.map((firmatario) => {
          return {
            value: firmatario.cognomeNotorieta,
            label: firmatario.cognomeNotorieta,
            firmatari: [firmatario.cognomeNotorieta],
          };
        });

        firmatariFromGroup.push(...formattedFirmatariGruppo);
      });

      const totalFirmatari = [
        ...selectedSingleFirmatari,
        ...firmatariFromGroup,
      ];

      // Create array of firmatari names
      const firmatari = [];
      totalFirmatari.forEach((firmatario) => {
        firmatari.push(...firmatario.firmatari);
      });

      const uniqueFirmatari = [...new Set(firmatari)];

      setTotalFirmatari(uniqueFirmatari);
    } else if (firmatariSenato?.length > 0) {
      const totalFirmatari = [...selectedSingleFirmatari, ...selectedGroups];

      // Create array of firmatari names
      const firmatari = [];
      totalFirmatari.forEach((firmatario) => {
        firmatari.push(...firmatario.firmatari);
      });

      const uniqueFirmatari = [...new Set(firmatari)];

      setTotalFirmatari(uniqueFirmatari);
    }
  };

  const handleConfirmFirmatari = () => {
    // local storage firmatari selected
    localStorage.setItem(
      "lastSingleFirmatari",
      JSON.stringify(selectedSingleFirmatari)
    );
    localStorage.setItem("lastGroups", JSON.stringify(selectedGroups));
    setIsFirmatariPopupOpen(false);
    handleUpdateFirmatari(totalFirmatari);
  };

  return (
    <Popup cardWidth="35%">
      {totalFirmatari?.length > 0 ? (
        <>
          {<h3>Firmatari selezionati:</h3>}
          <div
            style={{
              maxHeight: 400,
              overflowY: "auto",
            }}
          >
            {totalFirmatari.map((firmatario) => (
              <div>
                {"- "}
                {firmatario}
                {";"}
              </div>
            ))}
          </div>
          <div className="flex-row align-center justify-between w-100 mt-2">
            <Button handleclick={() => setTotalFirmatari([])}>Annulla</Button>
            <Button
              backgroundColor={"var(--danger)"}
              handleclick={() => {
                handleConfirmFirmatari();
              }}
            >
              Conferma
            </Button>
          </div>
        </>
      ) : (
        <div className="flex-column justify-center align-center">
          <h3
            style={{
              fontWeight: 600,
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            {!!hasEmendamentsFirmatari
              ? "AGGIUNGI FIRMATARI"
              : "SELEZIONA I FIRMATARI"}
          </h3>
          {!!hasEmendamentsFirmatari ? (
            <p>Aggiungi i singoli firmatari o il gruppo.</p>
          ) : (
            <p>Seleziona i singoli firmatari o il gruppo.</p>
          )}
          <div className="flex-row align-center justify-between w-100 mt-2">
            <div className="w-100">
              <div className="flex-row align-center justify-between w-100 mt-2">
                <label htmlFor="firmatari">Singoli firmatari</label>
                {!!lastSingleFirmatari && (
                  <div
                    onClick={() => {
                      importLastSelection("singleFirmatari");
                    }}
                    style={{
                      color: "var(--primary)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Importa ultima selezione
                  </div>
                )}
              </div>
              <Select
                key={selectKey}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    marginBottom: "24px",
                    marginTop: "8px",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "240px",
                    overflowY: "auto",
                  }),
                }}
                options={singleFirmatarioOptions}
                defaultValue={selectedSingleFirmatari}
                isMulti
                placeholder="Seleziona i singoli firmatari"
                onChange={(selected) => {
                  setSelectedSingleFirmatari(selected);
                }}
              />
              <div className="flex-row align-center justify-between w-100 mt-2">
                <label htmlFor="firmatari">Gruppo</label>
                {!!lastGroups && (
                  <div
                    onClick={() => {
                      importLastSelection("groups");
                    }}
                    style={{
                      color: "var(--primary)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Importa ultima selezione
                  </div>
                )}
              </div>
              <Select
                key={selectKey + "2"}
                styles={{
                  control: (provided) => ({
                    ...provided,

                    marginTop: "8px",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "240px",
                    overflowY: "auto",
                  }),
                }}
                isMulti
                defaultValue={selectedGroups}
                options={groupsOptions}
                placeholder="Seleziona il gruppo"
                onChange={(selected) => {
                  setSelectedGroups(selected);
                }}
              />
            </div>
          </div>
          <div className="flex-row align-center justify-between w-100 mt-2">
            <Button
              backgroundColor={"var(--danger)"}
              handleclick={() => setIsFirmatariPopupOpen(false)}
            >
              Annulla
            </Button>
            <Button
              disabled={
                selectedSingleFirmatari.length === 0 &&
                selectedGroups.length === 0
              }
              handleclick={() => {
                handleSaveFirmatari();
              }}
            >
              Salva
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default FirmatariPopup;
