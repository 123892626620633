import React, { useState, useEffect } from "react";
import { FiUser } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "../../shared/Spinner";
import { NavLink, useNavigate, useNavigation } from "react-router-dom";
import {
  deleteEmendament,
  getSavedEmendamentsByUserId,
  resetEmendaments,
} from "../../features/emendamentsSlice";
import { Button } from "../../shared/Buttons";
import { Popup } from "../../components/popup/Popup";
import EmendamentCard from "./components/EmendamentCard";
import {
  getFirmatariCamera,
  getFirmatariSenato,
  getGruppiCamera,
  getGruppiSenato,
} from "../../features/firmatariSlice";
import { getProfile } from "../../features/accountSlice";
import useDynamicCSSVariables from "../../hooks/useDynamicCSSVariables";
import CameraFooter from "../../components/footer/CameraFooter";
import Avatar from "../../components/avatar/Avatar";
import { resetDdl } from "../../features/ddlSlice";

const EmendamentList = () => {
  useDynamicCSSVariables();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const savedEmendaments = useSelector(
    (state) => state.emendaments.savedEmendaments
  );
  const parlamentSection = useSelector(
    (state) => state.account.parlamentSection
  );
  const profile = useSelector((state) => state.account.profile);

  useEffect(() => {
    if (profile) {
      dispatch(getSavedEmendamentsByUserId(profile.sub));
    } else {
      dispatch(getProfile());
      dispatch(getSavedEmendamentsByUserId(99));
    }
  }, [profile]);

  useEffect(() => {
    if (parlamentSection === "camera") {
      dispatch(getGruppiCamera());
      dispatch(getFirmatariCamera());
    } else if (parlamentSection === "senato") {
      //  dispatch(getGruppiSenato());
      dispatch(getFirmatariSenato());
    }
  }, [parlamentSection]);

  const handleGoHome = () => {
    dispatch(resetEmendaments());
    dispatch(resetDdl());
    navigate("/");
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        overflow: "hidden",
        height: parlamentSection === "camera" ? "calc(100vh - 75px)" : "100vh",
        width: "100%",
        backgroundColor: "var(--backgroundCard)",
      }}
    >
      <div
        className="flex-row justify-between align-center"
        style={{
          backgroundColor: "var(--primary)",
          width: "100%",
          boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
        }}
      >
        <div
          className="flex-row justify-center align-center"
          style={{
            flex: 1,
            textAlign: "center",
          }}
        ></div>
        <h1
          style={{
            flex: 1,
            color: "white",
            fontWeight: 600,
            textAlign: "center",
            letterSpacing: "2px",
          }}
        >
          Lista Richieste Emendamenti
        </h1>
        <div
          className="flex-row justify-end align-center"
          style={{
            flex: 1,
          }}
        >
          <NavLink
            to="/"
            style={{
              color: "white",
              fontWeight: 600,
              padding: "6px 8px",
              letterSpacing: "2px",
              cursor: "pointer",
              border: "2px solid #fff",
              marginRight: "12px",
              borderRadius: "4px",
            }}
          >
            Torna all'editor
          </NavLink>
          {/*     //link to go back  */}
          <div
            onClick={() => handleGoHome()}
            style={{
              color: "white",
              fontWeight: 600,
              padding: "6px 8px",
              letterSpacing: "2px",
              cursor: "pointer",
              border: "2px solid #fff",
              marginLeft: "20px",
              borderRadius: "4px",
              marginRight: "12px",
            }}
          >
            Elenco provvedimenti
          </div>
          {profile && <Avatar profile={profile} />}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          padding: "24px 3% ",
          height:
            parlamentSection === "camera"
              ? "calc(100vh - 75px - 48px )"
              : "calc(100vh - 48px )",
          minHeight:
            parlamentSection === "camera"
              ? "calc(100vh - 75px - 48px )"
              : "calc(100vh - 48px )",
          overflowY: "auto",
        }}
      >
        {!!savedEmendaments ? (
          <>
            {Object.entries(savedEmendaments).map(([key, value], i) => {
              return (
                <EmendamentCard
                  key={`${key}_${i}`}
                  emendaments={value}
                  pdlId={key}
                />
              );
            })}
          </>
        ) : (
          <div
            style={{
              width: "100%",
              backgroundColor: "#fff",
              padding: "16px",
              borderRadius: "4px",
              marginTop: "20px",
            }}
          >
            <p
              style={{
                fontWeight: 600,
              }}
            >
              Non hai ancora creato nessuna richiesta di emendamento.
            </p>
            <p>
              Crea una nuova richiesta di emendamento cliccando sul bottone{" "}
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                Crea nuovi emendamenti
              </span>{" "}
              in alto a destra.
            </p>
          </div>
        )}
      </div>
      {parlamentSection === "camera" && <CameraFooter />}
    </div>
  );
};

export default EmendamentList;
