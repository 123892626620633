import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api = process.env.REACT_APP_API_URL;
export const API_ROOT_URL = api;

const initialState = {
  loading: false,
  error: false,
  ddlID: localStorage.getItem("ddlID") || null,
  ddlSenatoList: [],
  ddlSenatoData: null,
  ddlSenatoText: null,
  ddlCameraList: [],
  ddlCameraData: null,
  ddlCameraText: null,
  ddlCameraFormattedText: null,
};

const ddlSlice = createSlice({
  name: "ddl",
  initialState,
  reducers: {
    resetDdl: (state) => {
      state.loading = false;
      state.ddlID = null;
      state.error = false;
      state.ddlSenatoData = null;
      state.ddlSenatoText = null;
      state.ddlCameraData = null;
      state.ddlCameraText = null;
      state.ddlCameraFormattedText = null;
      localStorage.removeItem("ddlID");
      localStorage.removeItem("idTestoSenato");
      localStorage.removeItem("navetteCamera");
      localStorage.removeItem("stralcioCamera");
    },
    setDdlCameraFormattedText: (state, action) => {
      state.ddlCameraFormattedText = action.payload;
    },
    setDdlID: (state, action) => {
      state.ddlID = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSenatoDdlList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSenatoDdlList.fulfilled, (state, action) => {
        state.loading = false;
        state.ddlSenatoList = action.payload.content;
      })
      .addCase(getSenatoDdlList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCameraDdlList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCameraDdlList.fulfilled, (state, action) => {
        state.loading = false;
        state.ddlCameraList = action.payload;
      })
      .addCase(getCameraDdlList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSingleDdlById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSingleDdlById.fulfilled, (state, action) => {
        state.loading = false;
        state.ddlSenatoData = action.payload;
      })
      .addCase(getSingleDdlById.rejected, (state, action) => {
        console.log("error", action.error.message);
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSingleDdlTextById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSingleDdlTextById.fulfilled, (state, action) => {
        state.loading = false;
        state.ddlSenatoText = action.payload;
      })
      .addCase(getSingleDdlTextById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSingleCameraTextById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSingleCameraTextById.fulfilled, (state, action) => {
        state.loading = false;
        state.ddlCameraText = action.payload.data;
      })
      .addCase(getSingleCameraTextById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getSenatoDdlList = createAsyncThunk(
  "ddlSlice/getSenatoDdlList",
  async () => {
    /*  const { url, method, headers } = api.getSenatoDdlList(); */
    const ddlList = await axios({
      url: `${API_ROOT_URL}/pdl/senato`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return ddlList.data;
  }
);

export const getCameraDdlList = createAsyncThunk(
  "ddlSlice/getCameraDdlList",
  async () => {
    /*  const { url, method, headers } = api.getCameraDdlList(); */
    const ddlList = await axios({
      url: `${API_ROOT_URL}/pdl/camera`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return ddlList.data;
  }
);

export const getSingleDdlById = createAsyncThunk(
  "ddlSlice/getSingleDdlById",
  async (id) => {
    const ddl = await axios({
      url: `${API_ROOT_URL}/pdl/senato/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return ddl.data;
  }
);

export const getSingleDdlTextById = createAsyncThunk(
  "ddlSlice/getSingleDdlTextById",
  async (id) => {
    const ddlText = await axios({
      url: `${API_ROOT_URL}/pdl/senato/text/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return ddlText.data;
  }
);

export const getSingleCameraTextById = createAsyncThunk(
  "ddlSlice/getSingleCameraTextById",
  async (data) => {
    const ddlText = await axios({
      url: `https://microws-test.intra.camera.it/wsgetarticles/api/pdl/articles`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });

    return ddlText.data;
  }
);

// export const changePassword = createAsyncThunk(
//   "ddlSlice/changePassword",
//   async ({ userCode, updateData }) => {
//     const { url, method, headers, data } = api.changePassword(
//       userCode,
//       updateData
//     );
//     const profile = await axios({
//       url,
//       method,
//       headers,
//       data,
//     });

//     return profile.data;
//   }
// );
// export const updateProfile = createAsyncThunk(
//   "ddlSlice/updateProfile",
//   async (profileData) => {
//     const { url, method, headers, data } = api.updateUser(
//       profileData.id,
//       profileData
//     );

//     const profile = await axios({
//       url,
//       method,
//       headers,
//       data,
//     });

//     return profile.data;
//   }
// );

export const { resetDdl, setDdlCameraFormattedText, setDdlID } =
  ddlSlice.actions;
export const { actions } = ddlSlice;
export default ddlSlice.reducer;
