import { cleanParagraphPath } from "../../../helpers/formatStringFuncs";
import { handleBulletKeyString } from "./BulletTreeFunc";

// XML TREE
export const renderXMLTree = (
  node,
  depth = 0,
  parentKey = "",
  path = "",
  setSelectedSection,
  expandedSections,
  toggleSection,
  setClickedSection,
  selectedSection
) => {
  return (
    <ul>
      {Object.entries(node).map(([key, value]) => {
        if (typeof value === "object") {
          if (parentKey === "a:Articolo") {
            if (!!value["a:Num"]) {
              const copyValue = { ...value };
              delete copyValue["a:Num"];
              const articleKey = "Articolo " + value["a:Num"]["@_numero"];
              key = articleKey;
              // if (key === "Articolo 1" && !value["a:Rubrica"]) {
              //   // handler introduzione
              //   key = "";
              //   /*          value = extractIntroductionValues(copyValue); */
              //   value = "";
              // } else {
              key = articleKey;
              value = copyValue;
              // }
            } else if (!!value["@_numero"]) {
              const copyValue = { ...value };
              delete copyValue["@_numero"];
              const articleKey = "Articolo " + value["@_numero"];
              key = articleKey;
              value = copyValue;
            } else {
              const copyValue = { ...value };
              key = null;
              value = copyValue;
            }
          }
          if (parentKey === "a:Comma") {
            /*   console.log("value Comma", value);
              console.log("key Comma", key); */
            if (!!value["a:Num"]) {
              const copyValue = { ...value };
              delete copyValue["a:Num"];
              const commaKey = "Comma " + value["a:Num"]["@_numero"];
              key = commaKey;
              value = copyValue;
            } else if (!!value["@_numero"]) {
              // TODO comma senza numero, sottocommi??
              const copyValue = { ...value };
              delete copyValue["@_numero"];
              const commaKey = "Comma " + value["@_numero"];
              key = commaKey;
              value = copyValue;
            } else {
            }
          }
          if (parentKey === "a:Lettera") {
            if (!!value["a:Num"]) {
              const copyValue = { ...value };
              delete copyValue["a:Num"];
              const letteraKey = "Lettera " + value["a:Num"]["@_numero"];
              key = letteraKey;
              value = copyValue;
            } else {
              key = "Lettera";
            }
          }
          if (parentKey === "a:Numero") {
            if (!!value["a:Num"]) {
              const copyValue = { ...value };
              delete copyValue["a:Num"];
              const numeroKey = "Numero " + value["a:Num"]["@_numero"];
              key = numeroKey;
              value = copyValue;
            } else {
              key = "Numero";
            }
          }

          const newPath = path === "" ? key : `${path} ${key}`;

          if (
            (!!key &&
              (key?.startsWith("@_") ||
                key?.startsWith("h:") ||
                key?.startsWith("dl:"))) ||
            key?.startsWith("a:Corpo") ||
            key === "h:p" ||
            key === "a:Alinea"
          ) {
            return null;
          }
          if (
            key === "a:Articolo" ||
            key === "a:Comma" ||
            key === "a:Lettera" ||
            key === "a:Numero"
          ) {
            return renderXMLTree(
              value,
              depth + 1,
              key,
              newPath,
              setSelectedSection,
              expandedSections,
              toggleSection,
              setClickedSection,
              selectedSection
            );
          }
          if (!key) {
            return null;
          }
          return (
            <li key={`${key}_${path}`}>
              <span
                style={{
                  cursor: "pointer",
                  paddingLeft: `${depth * 6}px`,
                  fontWeight:
                    // expandedSections[path + key] || selectedSection === key
                    selectedSection?.startsWith(cleanParagraphPath(newPath))
                      ? "bold"
                      : "500",
                  color:
                    // expandedSections[path + key] && key?.startsWith("Articolo")
                    selectedSection?.startsWith(cleanParagraphPath(newPath)) &&
                    key?.startsWith("Articolo")
                      ? "var(--articolo)"
                      : selectedSection?.startsWith(
                          cleanParagraphPath(newPath)
                        ) && key?.startsWith("Comma")
                      ? "var(--comma)"
                      : selectedSection?.startsWith(
                          cleanParagraphPath(newPath)
                        ) && key?.startsWith("Lettera")
                      ? "var(--lettera)"
                      : selectedSection?.startsWith(
                          cleanParagraphPath(newPath)
                        ) && key?.startsWith("Numero")
                      ? "var(--numero)"
                      : "var(--textColor)",
                }}
                onClick={() => {
                  if (!expandedSections[path + key]) {
                    setSelectedSection(cleanParagraphPath(newPath));
                    setClickedSection(cleanParagraphPath(newPath));
                  }
                  toggleSection(path + key);
                }}
              >
                {handleBulletKeyString(key)}
              </span>

              {selectedSection?.startsWith(cleanParagraphPath(newPath)) &&
                typeof value === "object" &&
                renderXMLTree(
                  value,
                  depth + 1,
                  key,
                  newPath,
                  setSelectedSection,
                  expandedSections,
                  toggleSection,
                  setClickedSection,
                  selectedSection
                )}
            </li>
          );
        } else {
          if (
            value === "" ||
            value === null ||
            value === undefined ||
            value === " " ||
            value === 0 ||
            // TODO NO PROCESSO ESCLUSIONE DELLE CHIAVI DA MOSTRARE NELLA BULLET TREE, MEGLIO INSERIE KEY PERMESSE
            key === "@_inIndice" ||
            key === "@_idPart" ||
            key === "@_tipo" ||
            key === "a:TitoloAtto" ||
            key === "h:p"
          ) {
            return null;
          }

          /*             console.log("key", key); */
          return (
            <li
              key={`${key}_${path}_${
                typeof value === "object"
                  ? JSON.stringify(value)
                  : typeof value === "string"
                  ? value
                  : typeof value === "number"
                  ? value
                  : "error"
              }>

            }`}
            >
              <span
                style={{
                  cursor: "pointer",
                  paddingLeft: `${depth * 6}px`,
                  fontWeight: expandedSections[path + key] ? "bold" : "normal",
                }}
                onClick={() => {
                  toggleSection(path + key);
                  setSelectedSection(path);
                  setClickedSection(path);
                }}
              >
                {handleBulletKeyString(key)}
              </span>
            </li>
          );
        }
      })}
    </ul>
  );
};
