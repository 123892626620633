import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import EmendamentsList from "./sections/EmendamentList/EmendamentsList";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CallbackPage from "./CallbackPage";
import EmendamentEditor from "./sections/EmendamentEditor/EmendamentEditor";

ReactDOM.render(
  <Provider store={store}>
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="" element={<App />} />
        <Route path="emendaments-list" element={<EmendamentsList />} />
        <Route path="editor/:id" element={<EmendamentEditor />} />

        <Route path="callback" element={<CallbackPage />} />
      </Routes>
    </Router>
  </Provider>,
  document.getElementById("root")
);
