import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import XmlViewer from "./sections/XML/XmlViewer";
import BulletTree from "./components/bullet-tree/BulletTree";
import EmendamentsRecap from "./sections/EmendamentsRecap";
import XhtmlViewer from "./sections/XHTML/XhtmlViewer";
import SelectEmedaments from "./sections/SelectEmendaments/SelectEmendaments";
import { getProfile } from "./features/accountSlice";
import { useLocation } from "react-router-dom";
import useDynamicCSSVariables from "./hooks/useDynamicCSSVariables";
import CameraFooter from "./components/footer/CameraFooter";
import VersionInfo from "./components/version-info/VersionInfo";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [xmlData, setXmlData] = useState(null);
  const [xhtmlData, setXhtmlData] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [clickedSection, setClickedSection] = useState(null);
  const [isBulletTreeOpen, setIsBulletTreeOpen] = useState(true);
  const emendaments = useSelector((state) => state.emendaments.emendaments);
  const parlamentSection = useSelector(
    (state) => state.account.parlamentSection
  );

  useEffect(() => {
    dispatch(getProfile());
  }, [location]);

  useDynamicCSSVariables();

  return (
    <div
      className="flex-column align-center"
      style={{
        backgroundColor: "var(--backgroundCard)",
        overflow: "hidden",

        /*      maxHeight: "100vh", */
      }}
    >
      {!!xhtmlData || !!xmlData ? (
        <></>
      ) : (
        <SelectEmedaments setXmlData={setXmlData} setXhtmlData={setXhtmlData} />
      )}

      <div
        className="flex-row justify-around "
        style={{
          width: emendaments?.length > 0 && "100%",
          margin: "auto",
        }}
      >
        {(!!xmlData || !!xhtmlData) && (
          <BulletTree
            data={
              xmlData ||
              xhtmlData.replace(/<em/g, "<i").replace(/<\/em/g, "</i")
            }
            fileType={xmlData ? "xml" : "xhtml"}
            setSelectedSection={setSelectedSection}
            sectionWidth={isBulletTreeOpen ? "15vw" : "2vw"}
            setIsBulletTreeOpen={setIsBulletTreeOpen}
            isBulletTreeOpen={isBulletTreeOpen}
            selectedSection={selectedSection}
            setClickedSection={setClickedSection}
          />
        )}
        {!!xhtmlData && (
          <XhtmlViewer
            xhtmlData={xhtmlData}
            setXhtmlData={setXhtmlData}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            clickedSection={clickedSection}
            setClickedSection={setClickedSection}
            viewerWidth={
              emendaments?.length > 0 && isBulletTreeOpen
                ? "55vw"
                : emendaments?.length > 0 && !isBulletTreeOpen
                ? "68vw"
                : isBulletTreeOpen
                ? "85vw"
                : "98vw"
            }
          />
        )}
        {!!xmlData && (
          <XmlViewer
            xmlData={xmlData}
            setXmlData={setXmlData}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            viewerWidth={
              emendaments?.length > 0 && isBulletTreeOpen
                ? "55vw"
                : emendaments?.length > 0 && !isBulletTreeOpen
                ? "68vw"
                : isBulletTreeOpen
                ? "85vw"
                : "98vw"
            }
            clickedSection={clickedSection}
          />
        )}
        <EmendamentsRecap setClickedSection={setClickedSection} />
      </div>
      {parlamentSection === "camera" ? (
        <CameraFooter />
      ) : (
        <VersionInfo iconColor="var(--primary)" />
      )}
    </div>
  );
}

export default App;
