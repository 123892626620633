import React from "react";
import { Popup } from "../../../components/popup/Popup";
import { Button } from "../../../shared/Buttons";

const DeleteConfirmPopup = ({
  setIsDeletedConfirmPopupOpen,
  setIsRelazionePopupOpen,
  setEditingEmendamentId,
  handleDelete,
  text,
  subText,
}) => {
  return (
    <Popup cardWidth="35%">
      <div className="flex-column justify-center align-center">
        <h3
          style={{
            fontWeight: 600,
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          {text}
        </h3>
        <p>{subText}</p>
        <div className="flex-row align-center justify-between w-100 mt-2">
          <Button
            handleclick={() => (
              setIsDeletedConfirmPopupOpen(false),
              setIsRelazionePopupOpen(false),
              setEditingEmendamentId(null)
            )}
          >
            Annulla
          </Button>
          <Button
            backgroundColor={"var(--danger)"}
            handleclick={() => {
              handleDelete();
            }}
          >
            Elimina
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default DeleteConfirmPopup;
