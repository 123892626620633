import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import XmlViewer from "../../sections/XML/XmlViewer";
import BulletTree from "../../components/bullet-tree/BulletTree";
import EmendamentsRecap from "../../sections/EmendamentsRecap";
import XhtmlViewer from "../../sections/XHTML/XhtmlViewer";
import SelectEmedaments from "../../sections/SelectEmendaments/SelectEmendaments";
import { getProfile } from "../../features/accountSlice";
import useDynamicCSSVariables from "../../hooks/useDynamicCSSVariables";
import CameraFooter from "../../components/footer/CameraFooter";
import VersionInfo from "../../components/version-info/VersionInfo";
import SelectHeader from "../SelectEmendaments/components/SelectHeader";
import {
  getSingleCameraTextById,
  getSingleDdlTextById,
} from "../../features/ddlSlice";
import { XMLParser } from "fast-xml-parser";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EmendamentEditor = () => {
  useDynamicCSSVariables();
  const query = useQuery();
  // get params from url
  const params = useParams();

  const ddlID = params.id;
  const textId = query.get("textId");
  const navette = query.get("navette");
  const stralcio = query.get("stralcio");

  const dispatch = useDispatch();
  const location = useLocation();
  const [xmlData, setXmlData] = useState(null);
  const [xhtmlData, setXhtmlData] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [clickedSection, setClickedSection] = useState(null);
  const [isBulletTreeOpen, setIsBulletTreeOpen] = useState(true);
  const ddlCameraText = useSelector((state) => state.ddl.ddlCameraText);
  const ddlSenatoText = useSelector((state) => state.ddl.ddlSenatoText);
  const emendaments = useSelector((state) => state.emendaments.emendaments);
  const parlamentSection = useSelector(
    (state) => state.account.parlamentSection
  );

  useEffect(() => {
    if (!!ddlID) {
      if (parlamentSection === "senato") {
        dispatch(getSingleDdlTextById(textId));
      } else {
        dispatch(
          getSingleCameraTextById({
            idLegislatura: 19,
            tipo: "PDL",
            numero: parseInt(ddlID),
            // navette se esiste
            navette: navette || null,
            // stralcio se esiste
            stralcio: stralcio || null,
          })
        );
      }
    }
  }, [ddlID]);

  useEffect(() => {
    if (!!ddlCameraText) {
      setXhtmlData(ddlCameraText);
    }
  }, [ddlCameraText]);

  useEffect(() => {
    if (!!ddlSenatoText) {
      const options = {
        ignoreAttributes: false,
        parseAttributeValue: true,
      };

      let xmlString = ddlSenatoText;
      // Rimuovi i tag con namespace h, mantenendo il loro contenuto

      // Rimuovi i tag <h:i> (con o senza attributi) e </h:i>
      xmlString = xmlString.replace(/<h:i[^>]*>|<\/h:i>/g, "");
      // Rimuovi i tag <h:b> (con o senza attributi) e </h:b>
      xmlString = xmlString.replace(/<h:b[^>]*>|<\/h:b>/g, "");

      // Rimuovi il tag <h:br> (con o senza attributi)
      xmlString = xmlString.replace(/<h:br[^>]*\/?>/g, "");
      // Rimuovi il tag <h:span> (con o senza attributi) e </h:span>
      xmlString = xmlString.replace(/<h:span[^>]*>|<\/h:span>/g, "");
      // rimuovi class="em2" o class="em3" ecc
      xmlString = xmlString.replace(/class="em\d"/g, "");

      const parser = new XMLParser(options);

      let jObj = parser.parse(xmlString);
      setXmlData(jObj.BGTDOC["dl:DDLPRES"]);
    }
  }, [ddlSenatoText]);

  useEffect(() => {
    dispatch(getProfile());
  }, [location]);

  return (
    <div
      className="flex-column align-center"
      style={{
        backgroundColor: "var(--backgroundCard)",
        overflow: "hidden",

        /*      maxHeight: "100vh", */
      }}
    >
      {/*       <SelectHeader /> */}
      <div
        className="flex-row justify-around "
        style={{
          width: emendaments?.length > 0 && "100%",
          margin: "auto",
        }}
      >
        {(!!xmlData || !!xhtmlData) && (
          <BulletTree
            data={
              xmlData ||
              xhtmlData.replace(/<em/g, "<i").replace(/<\/em/g, "</i")
            }
            fileType={xmlData ? "xml" : "xhtml"}
            setSelectedSection={setSelectedSection}
            sectionWidth={isBulletTreeOpen ? "15vw" : "2vw"}
            setIsBulletTreeOpen={setIsBulletTreeOpen}
            isBulletTreeOpen={isBulletTreeOpen}
            selectedSection={selectedSection}
            setClickedSection={setClickedSection}
          />
        )}
        {!!xhtmlData && (
          <XhtmlViewer
            xhtmlData={xhtmlData}
            setXhtmlData={setXhtmlData}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            clickedSection={clickedSection}
            setClickedSection={setClickedSection}
            viewerWidth={
              emendaments?.length > 0 && isBulletTreeOpen
                ? "55vw"
                : emendaments?.length > 0 && !isBulletTreeOpen
                ? "68vw"
                : isBulletTreeOpen
                ? "85vw"
                : "98vw"
            }
          />
        )}
        {!!xmlData && (
          <XmlViewer
            xmlData={xmlData}
            setXmlData={setXmlData}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            viewerWidth={
              emendaments?.length > 0 && isBulletTreeOpen
                ? "55vw"
                : emendaments?.length > 0 && !isBulletTreeOpen
                ? "68vw"
                : isBulletTreeOpen
                ? "85vw"
                : "98vw"
            }
            clickedSection={clickedSection}
          />
        )}
        <EmendamentsRecap setClickedSection={setClickedSection} />
      </div>
      {parlamentSection === "camera" ? (
        <CameraFooter />
      ) : (
        <VersionInfo iconColor="var(--primary)" />
      )}
    </div>
  );
};

export default EmendamentEditor;
