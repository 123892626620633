import React from "react";
import { cleanParagraphPath } from "../../helpers/formatStringFuncs";
import ContextMenu from "./ContextMenu";
import Tag from "../tag/Tag";
import MoreIcon from "../../shared/MoreIcon";

const ContextMenuLettera = ({
  setParagraphPath,
  setIsEditorPopupOpen,
  id,
  newPath,
  setEditorData,
  setEditorAction,
  setSectionType,
  setIsEditing,
}) => {
  return (
    <ContextMenu
      items={[
        {
          label: "Aggiungi lettera",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath(
              "Aggiungi lettera dopo " + cleanParagraphPath(newPath)
            );
            setIsEditorPopupOpen(true);
            setIsEditing(true);
            setEditorAction("add");
            setSectionType("lettera");
          },
        },
        {
          label: "Sopprimi lettera",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath("Sopprimi l'" + cleanParagraphPath(newPath));
            setEditorAction("delete");
            setSectionType("lettera");
            setIsEditorPopupOpen(true);
          },
          setEditorAction: "delete",
          setSectionType: "lettera",
        },
        {
          label: "Premetti lettera",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath(
              "Premetti lettera all' " + cleanParagraphPath(newPath)
            );
            setIsEditorPopupOpen(true);
            setIsEditing(true);
            setEditorAction("premise");
            setSectionType("lettera");
          },
        },
        {
          label: "Sostituisci lettera",
          handleItemClick: () => {
            setEditorData("");
            setParagraphPath("Sostituisci " + cleanParagraphPath(newPath));
            setIsEditorPopupOpen(true);
            setIsEditing(true);
            setEditorAction("replace");
            setSectionType("lettera");
          },
        },
      ]}
      backgroundColor={"var(--lettera)"}
      id={`lettera_${id}`}
    >
      <Tag backgroundColor={"var(--lettera)"}>Lettera</Tag>
      <MoreIcon color="var(--lettera)" />
    </ContextMenu>
  );
};

export default ContextMenuLettera;
