import React from "react";
import styled from "styled-components";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const StyledButton = styled.div`
  padding-top: ${(props) => props.paddingVertical || "8px"};
  padding-bottom: ${(props) => props.paddingVertical || "8px"};
  width: ${(props) => props.width || "100px"};
  max-width: ${(props) => props.maxWidth || "160px"};
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  align-self: center;
  color: ${(props) => props.color || "#fff"};
  text-transform: ${(props) => props.textTransform};
  background-color: ${(props) => props.backgroundColor || "var(--button)"};
  border: ${(props) =>
    props.border || `1px solid ${props.backgroundColor || "var(--button)"}`};
  margin-top: ${(props) => props.marginTop || "0px"};
  margin-left: ${(props) => props.marginLeft || "0px"};
  margin-right: ${(props) => props.marginRight || "0px"};
  cursor: pointer;
  border-radius: ${(props) => props.borderRadius || "4px"};
  font-size: ${(props) => props.fontSize || "0.9rem"};
  text-transform: ${(props) => props.textTransform || "none"};
  height: ${(props) => props.height};
  font-weight: ${(props) => props.fontWeight || 600};
  letter-spacing: ${(props) => props.letterSpacing || "1px"};

  &:hover {
    filter: opacity(65%);
  }

  &.disabled {
    filter: opacity(25%);
    cursor: initial;
    pointer-events: none;
  }
`;

export const Button = (props) => {
  return (
    <StyledButton
      className={props.disabled ? "disabled" : ""}
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.handleclick();
      }}
    >
      {props.children}
    </StyledButton>
  );
};

export const ButtonLink = (props) => {
  return (
    <StyledButton className={props.disabled ? "disabled" : ""} {...props}>
      {!!props.download ? (
        <a style={{ color: "white" }} href={props.to} download {...props}>
          {props.children}
        </a>
      ) : (
        <NavLink to={props.to} style={{ color: "white" }}>
          {props.children}
        </NavLink>
      )}
    </StyledButton>
  );
};
export const ButtonExternalLink = (props) => {
  return (
    <StyledButton className={props.disabled ? "disabled" : ""} {...props}>
      <a style={{ color: "white" }} href={props.to}>
        {props.children}
      </a>
    </StyledButton>
  );
};

export const ArrowBtn = (props) => {
  return (
    <StyledButton
      className={props.disabled ? "disabled" : ""}
      style={{
        height: props.height || 32,
        width: 96,
        padding: 0,
      }}
      onClick={() => props.handleclick()}
      {...props}
    >
      {props.arrowLeft && (
        <RiArrowLeftSLine
          width={8}
          style={{ marginRight: "4px", opacity: 0.8 }}
        />
      )}
      <div
        style={{
          height: props.height || 32,
          display: "flex",
          alignItems: "center",
        }}
      >
        {props.children}
      </div>
      {props.arrowRight && (
        <RiArrowRightSLine
          width={8}
          style={{ marginLeft: "4px", opacity: 0.8 }}
        />
      )}
    </StyledButton>
  );
};
