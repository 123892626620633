import React from "react";
import { styled } from "styled-components";

const StyledTag = styled.div`
  padding: 4px 16px 6px 16px;
  cursor: pointer;
  border-bottom-right-radius: 6px;
  background-color: ${(props) => props.backgroundColor || "#f1f1f1"};
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1px;

  text-align: center;
  color: ${(props) => props.color || "#fff"};
  &:hover {
    filter: brightness(90%);
  }
`;

const Tag = ({ children, backgroundColor }) => {
  return (
    <StyledTag backgroundColor={backgroundColor} className="tag">
      {children}
    </StyledTag>
  );
};

export default Tag;
