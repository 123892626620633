import React from "react";
import { FiMoreVertical } from "react-icons/fi";
import { styled } from "styled-components";

const StyledMoreIcon = styled(FiMoreVertical)`
  font-size: 1.5rem;
  color: ${(props) => props.color || "blue"};
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin: 0.5rem;
`;

const MoreIcon = (props) => {
  return <StyledMoreIcon {...props} />;
};

export default MoreIcon;
