import React, { useState } from "react";
import styled from "styled-components";

import { FaInfoCircle } from "react-icons/fa";
import { Popup } from "../popup/Popup";

const InfoContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 8px;
  font-size: 12px;
  z-index: 1000;
  color: #000000;
`;

const VersionInfo = (props) => {
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  return (
    <InfoContainer>
      {!!isInfoPopupOpen && (
        <Popup handleClose={() => setIsInfoPopupOpen(false)} cardWidth="25%">
          <p
            style={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Versione Frontend: <strong>0.1.0</strong>
          </p>
          <p
            style={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Versione Backend: <strong>0.1.0</strong>
          </p>
        </Popup>
      )}
      <FaInfoCircle
        onClick={() => setIsInfoPopupOpen(!isInfoPopupOpen)}
        size={36}
        color={props.iconColor || "#000000"}
      />
    </InfoContainer>
  );
};

export default VersionInfo;
